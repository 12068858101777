import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { NotificacionesService } from './../notificaciones.service';

@Component({
    selector: 'krb-det-noti',
    templateUrl: './det-noti.component.html'
})
export class NotificacionDetalleComponent implements OnInit {

    notiSelected: any;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<NotificacionDetalleComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _notiSrv: NotificacionesService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.notiSelected = null;
    }

    private InitComponente(): void {
        this.notiSelected = <any> this.params.notiSelected;
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }
}
