import { Idle } from '@ng-idle/core';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MensajesService, CargandoService, HttpErrorsService } from 'app/shared';
import { UtilsApps } from './../../shared/utils/utils.app';
import { DesbloqueoService } from './desbloqueo.service';
import { ConfirmacionService } from './../../shared/components/dialogo-confirmacion/confirmacion.service';

@Component({
  selector: 'krb-desbloqueo',
  templateUrl: './desbloqueo.component.html',
  styles: []
})
export class DesbloqueoComponent {

  desbloqueoForm: FormGroup;
  cambioForm: FormGroup;
  oculta = true;
  muestraPass = true;
  
  constructor(private _router: Router,
              private _msjSrv: MensajesService,
              private _loadSrv: CargandoService,
              private _builder: FormBuilder, 
              private _title: Title,
              private _httpErroSrv: HttpErrorsService,
              @Inject('IDLE_TIMEOUT') private timeout: number,
              private _idle: Idle,
              private _dlgSrv: ConfirmacionService,
              private _desSrv: DesbloqueoService
              ) {
    this._title.setTitle('Desbloqueo de Acceso | Karabe');

    this.inicializaForm();
    this.oculta = true;
  }

  private inicializaForm(): void {
    this.desbloqueoForm = this._builder.group({
      usuario: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    this.cambioForm = this._builder.group({
      usuario: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      claveRecuperacion: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  solicitaClaveRecovery(): void {
    const mensaje = 'Se enviará un correo con la clave de recuperación para cambiar su contraseña, ¿Está de acuerdo?';
    this._dlgSrv.confirmarSimple(mensaje).subscribe(
      res=>{
        if(res) {
          this._loadSrv.toggle(true);
          this._desSrv.SolicitarClaveRecuperacion(this.desbloqueoForm.value.usuario, this.desbloqueoForm.value.email).toPromise().then(
            resService => {
              if(resService.code === 0) {
                this.cambiaVista();
                this._msjSrv.agregaInfo(resService.message);
                this._loadSrv.toggle(false);
              } else {
                this._loadSrv.toggle(false);
                this._msjSrv.agregaError(resService.message);
              }
            }).catch(error => {
              this._loadSrv.toggle(false);
              console.log('Error ==> ', error);
              this._msjSrv.agregaError('Error al solicitar la clave de recuperación, intente más tarde');
            });
        }
      });
  }
  
  cambiarPassword(): void {
    const mensaje = 'Su contraseña se cambiará por la ingresada, ¿Desea continuar?';
    this._dlgSrv.confirmarSimple(mensaje).subscribe(
      res => {
        if (res) {
          this._loadSrv.toggle(true);
          this._desSrv.CambioPassword(this.desbloqueoForm.value.usuario,
                                      this.desbloqueoForm.value.email,
                                      this.cambioForm.value.claveRecuperacion,
                                      UtilsApps.sha1(this.cambioForm.value.password)).toPromise().then(
            resService => {
              if(resService.code === 0) {
                this._msjSrv.agregaInfo(resService.message);
                this._loadSrv.toggle(false);
                this.navegar('/login');
              } else {
                this._loadSrv.toggle(false);
                this._msjSrv.agregaError(resService.message);
              }
            }).catch(error => {
              this._loadSrv.toggle(false);
              console.log('Error ==> ', error);
              this._msjSrv.agregaError('Error al cambiar la contraseña, intente más tarde.');
            });
        }
      });
  }

  cambiaVista(): void {
    this.cambioForm.controls.usuario.patchValue(this.desbloqueoForm.value.usuario);
    this.cambioForm.controls.email.patchValue(this.desbloqueoForm.value.email);
    this.cambioForm.controls.usuario.disable();
    this.cambioForm.controls.email.disable();
    this.cambioForm.controls.claveRecuperacion.patchValue(null);
    this.cambioForm.controls.password.patchValue(null);
    this.oculta = false;
  }

  cambiaVistaSolicitar(): void {
    this.cambioForm.controls.usuario.enable();
    this.cambioForm.controls.email.enable();
    this.cambioForm.reset();
    this.oculta = true;
  }

  volverEnviar(): void {
    this._loadSrv.toggle(true);
    this._desSrv.ReenvioClaveRecuperacion(this.desbloqueoForm.value.usuario, this.desbloqueoForm.value.email).toPromise().then(
      resService => {
        if(resService.code === 0) {
          this._msjSrv.agregaInfo(resService.message);
          this._loadSrv.toggle(false);
        } else if(resService.code === 2) {
          this._msjSrv.agregaWarn(resService.message);
          this.cambiaVistaSolicitar();
          this._loadSrv.toggle(false);
        } else {
          this._loadSrv.toggle(false);
          this._msjSrv.agregaError(resService.message);
        }
      }).catch(error => {
        this._loadSrv.toggle(false);
        console.log('Error ==> ', error);
        this._msjSrv.agregaError('Error al solicitar la clave de recuperación.');
      });
  }

  cancelar(): void {
    this.navegar('/login');
  }

  navegar(ruta: string) {
    this._router.navigate([ruta]);
  }

  irRegistrar(): void {
    this.navegar('/registro');
  }

}
