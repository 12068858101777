import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialKrbModule } from './material-krb.module';
import { PrimeNgModule } from './primeng.module';
import { StoreManagement, SubstringPipe } from './utils';

import { MensajesComponent, DialogoCargaComponent, DialogoConfirmacionComponent, LogoutButtonComponent } from './components';
import { CargandoService, ConfirmacionService, MensajesService, FadeInOutComponent } from './components';
import { HttpErrorsService, LoginService, KrbAppsService, DataService } from './services';
import { SecurityModule, SessionModule } from './submodules';
import { NullPipe, TruncatePipe, SafeHtmlPipe, BsizePipe, FormErrorsPipe } from './pipes';

@NgModule({
  declarations: [
    MensajesComponent, DialogoCargaComponent, DialogoConfirmacionComponent, NullPipe, TruncatePipe, SafeHtmlPipe, LogoutButtonComponent,
    BsizePipe, FadeInOutComponent, FormErrorsPipe, SubstringPipe
  ],
  imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, SecurityModule, SessionModule.forRoot(),
    MaterialKrbModule, PrimeNgModule],
  providers: [],
  exports: [MensajesComponent, DialogoCargaComponent, DialogoConfirmacionComponent, NullPipe, TruncatePipe,
    SafeHtmlPipe, LogoutButtonComponent, BsizePipe, FadeInOutComponent, FormErrorsPipe, SubstringPipe],
  entryComponents: [DialogoCargaComponent, DialogoConfirmacionComponent, MensajesComponent]
})
export class KrbSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: KrbSharedModule,
      providers: [StoreManagement, HttpErrorsService, CargandoService, ConfirmacionService,
        MensajesService, LoginService, KrbAppsService, DataService]
    };
  }
}
@NgModule({
  exports: [KrbSharedModule],
  providers: [StoreManagement, HttpErrorsService, CargandoService, ConfirmacionService, MensajesService, LoginService, KrbAppsService, DataService],
  declarations: []
})
export class KrbSharedRootModule { }
