import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { ArticulosService } from './articulos.service';
import { ArticuloModel } from './ArticuloModel';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'krb-new-articulo',
    templateUrl: './new-articulo.component.html',
    styleUrls: ['./new-articulo.component.css']
})
export class NewArticuloComponent implements OnInit {

    accion: string;
    articuloSelected: any;
    imgPrevia: string;

    generalesForm: FormGroup;
    adicionalesForm: FormGroup;
    opcionesForm: FormGroup;
    tipos: any[];
    aplica_opciones: any[];
    upcs: any[];
    grupos: any[];
    categorias: any[];
    familias: any[];
    lineas: any[];
    unidades: any[];
    centroCostos: any[];
    marcas: any[];
    agrupadores: any[];
    imagenArt: any = null;
    imageName: any = null;

    asistentes: any[];
    // requerido: any[];
    opciones_disponibles: any[];

    opcionesArt: any[] = [];

    expandirOpciones: boolean = true;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<NewArticuloComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private _dlgSrv: ConfirmacionService,
                private _builder: FormBuilder,
                private ssnSrv: SessionService,
                private _artSrv: ArticulosService) {
    }

    ngOnInit(): void {
        this.validaTipoAccion();
    }

    private limpiaVariables(): void {
        this.agrupadores = [];
    }

    private validaTipoAccion(): void {
        // console.log(JSON.stringify(this.params));
        this.accion = this.params.action;
        this.articuloSelected = this.params.articuloSelected;

        this.limpiaVariables();
        this.creaForm();

        if (this.accion === 'E') {
            this.imgPrevia = this.articuloSelected.Imagen.base64File;
        }

        this.cargaCombos().subscribe(() => {
            if (this.accion === 'N') {
                this.InitComponenteNuevo();
            } else {
                this.InitComponenteEditar();
            }
        });

    }

    private InitComponenteNuevo(): void {
        this.opcionesArt.push({'Opcion':{'value':'D','viewValue':'Talla'},'Asistente':'Columna'});
        this.generalesForm.controls.TipoOpcion.patchValue('Si');
        this.generalesForm.controls.TipoOpcion.disable();

        this.generalesForm.controls.Tipo.patchValue('Normal');
        this.generalesForm.controls.Tipo.disable();

        this.imgPrevia = null;
    }

    private InitComponenteEditar(): void {

        this.generalesForm.controls.Articulo.patchValue(this.articuloSelected.Art.Articulo);
        this.generalesForm.controls.Descripcion1.patchValue(this.articuloSelected.Art.Descripcion1);
        this.generalesForm.controls.Descripcion2.patchValue(this.articuloSelected.Art.Descripcion2);
        this.generalesForm.controls.Tipo.patchValue(this.articuloSelected.Art.Tipo);
        this.generalesForm.controls.TipoOpcion.patchValue(this.articuloSelected.Art.TipoOpcion);
        this.generalesForm.controls.PrecioLista.patchValue(this.articuloSelected.Art.PrecioLista);
        this.generalesForm.controls.Precio10.patchValue(this.articuloSelected.Art.Precio10);
        this.generalesForm.controls.ModeloCliente.patchValue(this.articuloSelected.Art.ModeloCliente);
        this.generalesForm.controls.FolioDiseno.patchValue(this.articuloSelected.Art.FolioDiseno);

        this.generalesForm.controls.Articulo.disable();
        this.generalesForm.controls.TipoOpcion.disable();
        this.generalesForm.controls.Tipo.disable();

        this.adicionalesForm.controls.Grupo.patchValue(this.articuloSelected.Art.Grupo);
        this.adicionalesForm.controls.Categoria.patchValue(this.articuloSelected.Art.Categoria);
        this.adicionalesForm.controls.Familia.patchValue(this.articuloSelected.Art.Familia);
        this.adicionalesForm.controls.Linea.patchValue(this.articuloSelected.Art.Linea);
        this.adicionalesForm.controls.Unidad.patchValue(this.articuloSelected.Art.Unidad);
        this.adicionalesForm.controls.UPC.patchValue(this.articuloSelected.Art.TipoUPC);
        this.adicionalesForm.controls.InfoEtiquetas.patchValue(this.articuloSelected.Art.info_etiquetas);

        this.centroCostos.forEach(cc => {
            if (cc.value === this.articuloSelected.Art.ContUso) {
                this.generalesForm.controls.CentroCosto.patchValue(cc);
            }
        });

        this.marcas.forEach(m => {
            if (m.viewValue === this.articuloSelected.Art.Marca) {
                this.generalesForm.controls.Marca.patchValue(m);
            }
        });

        this.agrupadores.forEach(des => {
            const valorFin = (<Array<any>>this.articuloSelected.Agrupadores).find(a => a.Agrupador === des.Agrupador.NombreEnc);
            if (valorFin) {
                des.Valor = {NombreDet:valorFin.Valor, NombreEnc: des.Agrupador.NombreEnc, Situacion: "1"};
            }
        });

       this.articuloSelected.Opciones.forEach(opt => {
            this.opcionesArt.push({ Opcion: {value: opt.Opcion, viewValue: opt.Descripcion}, Asistente: opt.Asistente });
        });

        this._loadSrv.toggle(false);
    }

    private creaForm(): void {
        this.generalesForm = this._builder.group({
            Articulo: [null, Validators.required],
            Descripcion1: [null, Validators.required],
            Descripcion2: [null, null],
            Tipo: [null, Validators.required],
            TipoOpcion: [null, Validators.required],
            PrecioLista: [null, Validators.required],
            Precio10: [null, null],
            CentroCosto: [null, Validators.required],
            ModeloCliente: [null, null],
            Marca: [null, null],
            FolioDiseno: [null, null]
          });

        this.adicionalesForm = this._builder.group({
            Grupo: [null, Validators.required],
            Categoria: [null, Validators.required],
            Familia: [null, Validators.required],
            Linea: [null, Validators.required],
            Unidad: [null, Validators.required],
            /*
            UnidadCompra: [null, Validators.required],
            UnidadTraspaso: [null, Validators.required],
            */
            UPC: [null, Validators.required],
            InfoEtiquetas: [null, null]
          });

        this.opcionesForm = this._builder.group({
            Opcion: [null, Validators.required],
            // Requerido: [null, Validators.required],
            Asistente: [null, Validators.required]
        });

    }

    private cargaCombos(): Observable<void> {

        this.tipos = [
            {value: 'Normal', viewValue: 'Normal'},
            {value: 'Servicio', viewValue: 'Servicio'},
            {value: 'Serie', viewValue: 'Serie'},
            {value: 'Lote', viewValue: 'Lote'},
            {value: 'Juego', viewValue: 'Juego'},
            {value: 'Estructura', viewValue: 'Estructura'}
        ];
        this.aplica_opciones = [
            {value: 'Si', viewValue: 'Sí'},
            {value: 'No', viewValue: 'No'},
        ];
        this.upcs = [
            {value: 'NA', viewValue: 'No Aplica'},
            {value: 'A', viewValue: 'Articulo'},
            {value: 'T', viewValue: 'Talla'},
            {value: 'C', viewValue: 'Color'},
            {value: 'D', viewValue: 'Dibujo'},
            {value: 'CT', viewValue: 'Color/Talla'},
            {value: 'DT', viewValue: 'Dibujo/Talla'},
        ];

        this.asistentes = [
            {value: 'Renglon', viewValue: 'Renglon'},
            {value: 'Columna', viewValue: 'Columna'},
            {value: 'Lista', viewValue: 'Lista'}
        ];
        /*
        this.requerido = [
            {value: '1', viewValue: 'Sí'},
            {value: '0', viewValue: 'No'}
        ];
        */
        this.grupos = [];
        this.categorias = [];
        this.familias = [];
        this.lineas = [];
        this.unidades = [];
        this.opciones_disponibles = [];
        this.centroCostos = [];
        this.marcas = [];
        this.agrupadores = [];

        return this._artSrv.getCombosArt().pipe(
            tap(res => {
                res.ArtCat.forEach(cat => {
                    this.categorias.push({value: cat.Categoria, viewValue: cat.Categoria});
                });
                res.ArtFam.forEach(cat => {
                    this.familias.push({value: cat.Familia, viewValue: cat.Familia});
                });
                res.ArtGrupo.forEach(cat => {
                    this.grupos.push({value: cat.Grupo, viewValue: cat.Grupo});
                });
                res.ArtLinea.forEach(cat => {
                    this.lineas.push({value: cat.Linea, viewValue: cat.Linea});
                });
                res.Unidad.forEach(cat => {
                    this.unidades.push({value: cat.Unidad, viewValue: cat.Unidad});
                });
                res.Opcion.forEach(cat => {
                    this.opciones_disponibles.push({value: cat.Opcion, viewValue: cat.Descripcion});
                });
                res.CentroCostos.forEach(cat => {
                    this.centroCostos.push({value: cat.CentroCostos, viewValue: '[' + cat.CentroCostos + '] ' + cat.Descripcion});
                });
                res.Marcas.forEach(cat => {
                    this.marcas.push({value: cat.Id, viewValue: cat.Marca});
                });
                res.Agrupador.forEach(cat => {
                    this.agrupadores.push(cat);
                });
            }),
            catchError(err => {
                console.log('Errro::cargaCombos::', err);
                this._msjSrv.agregaError('Error al obtener los catalogos.');
                return null;
            }));

    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }

    onImagePreview(event: File) {
        this.imageName = null;
        this.imagenArt = null;
        if (event) {
          this.readThis(event);
        }
    }

    readThis(inputValue: any): void {
        const file: File = inputValue;
        const myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
            this.imagenArt = myReader.result;
        };
        myReader.readAsDataURL(file);
        this.imageName = file.name;
    }

    addOpcion(): void {
        if (this.validaOpcionAdd()) {
            this.opcionesArt.push(this.opcionesForm.value);
            this.opcionesForm.reset();
            this.expandirOpciones = false;
        }
    }


    deleteOpcion(optTemp: any): void {
        const mensaje = 'Se quitara la Opción: ' + optTemp.Opcion.viewValue + ', ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
          res => {
            if (res) {
                this._loadSrv.toggle(true);
                this.opcionesArt.splice(this.opcionesArt.indexOf(optTemp), 1);
                this.opcionesForm.reset();
                this._loadSrv.toggle(false);
            }
          });
    }

    guardarArticulo(): void {
        if (this.validaAgregarArticulo()) {
            this._dlgSrv.confirmarSimple('El articulo se afectara en Intelisis, ¿Desea continuar?').subscribe(
                res => {
                    if (res) {
                        this._loadSrv.toggle(true);
                        const artP = Object.assign({}, this.generalesForm.getRawValue(), this.adicionalesForm.value);
                        const opt = this.opcionesArt;
                        const agrupadores = this.regresaAgrupadoresForArt();
                        const imagen = { 'NombreImagen': this.imageName, 'base64': this.imagenArt};
                        this._artSrv.AlmacenaArticulo(this.accion, artP, opt, agrupadores, imagen).subscribe(
                            resWS => {
                                if (resWS.code === 0) {
                                    this._msjSrv.agregaOK('Se ha insertado el articulo correctamente.');
                                    this.cerrarDialogo();
                                } else {
                                    this._msjSrv.agregaError(resWS.message);    
                                }
                                this._loadSrv.toggle(false);
                            },
                            err => {
                                this._msjSrv.agregaError('Error al insertar el articulo, intente más tarde.');
                                this._loadSrv.toggle(false);
                            }
                        );
                    }
                }
            );
        }
    }

    private validaOpcionAdd(): boolean {

        let opt: boolean = true;
        // Valida que no exista la opcion
        this.opcionesArt.forEach(element => {
            if (element.Opcion.value === this.opcionesForm.value.Opcion.value) {
                this._msjSrv.agregaWarn('Ya existe la opción que desea agregar.');
                opt = false;
                return false;
            }

            if (element.Asistente === this.opcionesForm.value.Asistente) {
                this._msjSrv.agregaWarn('No puede agregar más de uno Asistente ['+this.opcionesForm.value.Asistente+'].');
                opt = false;
                return false;
            }

        });

        if (opt) {
            return true;
        } else {
            return false;
        }
    }

    private validaAgregarArticulo(): boolean {

        let continua: boolean = true;
        this.agrupadores.forEach(agpo => {
            if (!agpo.Valor) {
                continua = false;
            }
        });

        if (!continua) {
            this._msjSrv.agregaWarn('Debe especificar todos los valores de los agrupadores.');
            return false;
        }

        if (this.accion === 'N') {
            if (this.imageName === null) {
                this._msjSrv.agregaWarn('Debe seleccionar una foto del articulo.');
                return false;
            }
        }

        if (this.generalesForm.getRawValue().TipoOpcion === 'Si') {
            if (this.opcionesArt.length <= 0) {
                this._msjSrv.agregaWarn('Cuando selecione "Sí" en Opciones debe especificar las opciones del articulo.');
                return false;
            }
        } else {
            if (this.opcionesArt.length > 0) {
                this._msjSrv.agregaWarn('Las opciones serán ignoradas, debido a que selecciono "No" en opciones.');
            }
        }

        return true;
    }

    private regresaAgrupadoresForArt(): any[] {

        let agpoResul: any[] = [];
        this.agrupadores.forEach(agpo => {
            agpoResul.push({'Valor': agpo.Valor.NombreDet, 'Agrupador': agpo.Valor.NombreEnc})
        });
        return agpoResul;


    }
}
