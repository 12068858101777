import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PedidoService } from './../services/pedidos.service';
import { PedidosModel } from '../modelos/PedidosModel';
import { FormGroup, FormBuilder, Validators } from '../../../../../../../node_modules/@angular/forms';
import { Router } from '../../../../../../../node_modules/@angular/router';

@Component({
    selector: 'krb-update-situacion-pedido',
    templateUrl: './update-sit-pedido.component.html',
    styleUrls: ['./update-sit-pedido.component.css']
})
export class UpdateSitPedidoComponent implements OnInit {

    pedido: PedidosModel;
    formSituacion: FormGroup;
    situaciones: any[];

    constructor(@Inject(MAT_DIALOG_DATA) public pedidoSelected: any,
                public dialogRef: MatDialogRef<UpdateSitPedidoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _router: Router,
                private _dlgSrv: ConfirmacionService,
                private _pedidoSrv: PedidoService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.inicializaForm();
        this.formSituacion.reset();
        this.pedido = null;
    }

    private InitComponente(): void {
        this.pedido = <PedidosModel> this.pedidoSelected.pedidoSelected;
    }

    private inicializaForm(): void {
        this.formSituacion = this._builder.group({
            situacion: [null, Validators.required]
          });

        this.situaciones = [
            {value: 'Entregado', viewValue: 'Entregado'},
            {value: 'Entregado Parcialidades', viewValue: 'Entregado Parcialidades'},
            {value: 'Pendiente de Pago', viewValue: 'Pendiente de Pago'},
            {value: 'Cancelado', viewValue: 'Cancelado'}
        ];
    }

    cambiar(): void {
        const mensaje = 'Se actualizará la situación del pedido, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
            res => {
                if (res) {
                    this._loadSrv.toggle(true);
                    this._pedidoSrv.cambiaSituacion(this.pedido.id_pedido, 
                                                    this.formSituacion.value.situacion).toPromise().then(
                        res => {
                            if(res.code === 0) {
                                this._msjSrv.agregaInfo(res.message);
                                this.cerrarDialogo();
                            } else {
                                this._msjSrv.agregaError(res.message);
                            }
                            this._loadSrv.toggle(false);
                        }).catch(error => {
                            console.log('Error::UpdateSitPedidoComponent::cambiar::', error);
                            this._msjSrv.agregaError('Error al actualizar el pedido, intente más tarde.');
                            this._loadSrv.toggle(false);
                        }
                    );   
                }
            }
        );
    }

    cancelar(): void {
        this.cerrarDialogo();
    }

    navegar(ruta: string) {
        this._router.navigate([ruta]);
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }
}
