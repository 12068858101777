import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MensajesService, TIPOS_MENSAJE, Identity, CargandoService, HttpErrorsService } from 'app/shared';
import { UtilsApps } from './../../shared/utils/utils.app';
import { RegistroUserService } from './registro.service';
import { ConfirmacionService } from './../../shared/components/dialogo-confirmacion/confirmacion.service';

@Component({
  selector: 'krb-registro',
  templateUrl: './registro.component.html',
  styles: []
})
export class RegistroComponent {

  busquedaForm: FormGroup;
  registroForm: FormGroup;
  ocultaBusqueda: boolean;
  datosUsuario: any;
  muestraPass: boolean;


  constructor(private _router: Router,
              private _msjSrv: MensajesService,
              private _loadSrv: CargandoService,
              private _builder: FormBuilder, 
              private _title: Title,
              private _httpErroSrv: HttpErrorsService,
              private _regSrv: RegistroUserService,
              private _dlgSrv: ConfirmacionService,
              @Inject('IDLE_TIMEOUT') private timeout: number) {
    
    this._title.setTitle('Registro Pedidos en línea');
    this.ocultaBusqueda = true;
    this.muestraPass = true;
    this.inicializaForm();

  }

  private inicializaForm(): void {
    this.busquedaForm = this._builder.group({
      usuarioIntelisis: ['', Validators.required]
    });

    this.registroForm = this._builder.group({
      usuario: [null, null],
      nombre: [null, null],
      agente: [null, null],
      nivel: [null, null],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  buscarIntelisis(): void {
    this.buscaDatosUsuario();
  }

  volverBusqueda(): void {
    this.ocultaBusqueda = true;
    this.registroForm.controls.usuario.enable();
    this.registroForm.controls.nombre.enable();
    this.registroForm.controls.agente.enable();
    this.registroForm.controls.nivel.enable();
    this.registroForm.reset();
    this.busquedaForm.reset();
  }

  registro(): void {
    this.crearUsuario();
  }

  cancelar(): void {
    this.navegar('/login');
  }

  navegar(ruta: string) {
    this._router.navigate([ruta]);
  }

  private buscaDatosUsuario(): void {
    this.datosUsuario = null;
    this._loadSrv.toggle(true);
    this._regSrv.BuscaUsuarioIntelisis(this.busquedaForm.value.usuarioIntelisis).toPromise().then(
      resService => {
        // console.log(JSON.stringify(resService));
        if(resService.code === 0) {
            this.datosUsuario = resService.datos;
            this.distribuyeInfo();
            this._msjSrv.agregaInfo(resService.message);
        } else {
          this._msjSrv.agregaError(resService.message);
        }
        this._loadSrv.toggle(false);
      }).catch(errorService => {
        this._loadSrv.toggle(false);
        console.log('Error ==> ', errorService);
        this._msjSrv.agregaError('Error al realizar la busqueda en Intelisis, intente más tarde..');
      });
  }

  private distribuyeInfo(): void {
    // console.log(JSON.stringify(this.datosUsuario));
    this.registroForm.controls.usuario.patchValue(this.datosUsuario.Usuario);
    this.registroForm.controls.nombre.patchValue(this.datosUsuario.Nombre);
    this.registroForm.controls.agente.patchValue(this.datosUsuario.Agente);
    this.registroForm.controls.nivel.patchValue(this.datosUsuario.NombreNivel);
    this.registroForm.controls.email.patchValue(this.datosUsuario.Email);
    this.registroForm.controls.usuario.disable();
    this.registroForm.controls.nombre.disable();
    this.registroForm.controls.agente.disable();
    this.registroForm.controls.nivel.disable();
    this.ocultaBusqueda = false;
  }

  private crearUsuario(): void {
    const mensaje = 'Se creará el usuario, ¿Desea continuar?';
    this._dlgSrv.confirmarSimple(mensaje).subscribe(
      res => {
        if (res) {
          this._loadSrv.toggle(true);
          this._regSrv.RegistroNuevoUsuario(this.datosUsuario.Nombre,
                                            this.registroForm.value.email,
                                            this.datosUsuario.Usuario,
                                            UtilsApps.sha1(this.registroForm.value.password),
                                            this.datosUsuario.Agente,
                                            this.datosUsuario.Nivel).toPromise().then(
            resService => {
              if(resService.code === 0) {
                this._msjSrv.agregaInfo(resService.message);
                this._loadSrv.toggle(false);
                this.navegar('/login');
              } else {
                this._loadSrv.toggle(false);
                this._msjSrv.agregaError(resService.message);
              }
            }).catch(error => {
              this._loadSrv.toggle(false);
              console.log('Error ==> ', error);
              this._msjSrv.agregaError('Error al crear el usuario, intente más tarde.');
            });
        }
      });
  }

}
