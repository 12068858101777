import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CargandoService, ConfirmacionService, SessionService, MensajesService } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { PedidoService } from './services/pedidos.service';
import { MatDialog } from '@angular/material';
import { PedidosModel } from './modelos/PedidosModel';
import { DataService } from '../../../../shared/services/DataService';
import { AutorizacionCtePedidoComponent } from './autorizacion-cte/autorizacion-cte.component';
import { UploadAutorizacionCteComponent } from './upload-autorizacion-cte/upload-autoricacion.component';
import { ComentarioPedidoComponent } from './comentarios-pedidos/comentarios-pedidos.component';
import { HttpResponse } from '@angular/common/http';
import { UpdateUPCPedidoComponent } from './update-upc/update-upc.component';
import { UpdateCantidadPedidoComponent } from './update-cantidad/update-cantidad.component';
import { UpdateOpcionesPedidoComponent } from './update-opciones/update-opciones.component';

@Component({
    selector: 'krb-view-pedidos',
    templateUrl: './html/view-pedido.component.html',
    styleUrls: ['./css/view-pedido.component.css']
})
export class VerPedidoComponent implements OnInit {

    pedidoSelect: PedidosModel;
    infoPedido: any;

    esEnviarAutorizacion: boolean;
    esSubirAutorizacion: boolean;
    esRechazar: boolean;
    esCancelar: boolean;
    esAvanzar: boolean;
    esAfectar: boolean;
    esEditar: boolean;
    esReditar: boolean;
    esClonar: boolean;
    esRetomar: boolean;

    totalCantidad: number = 0;
    totalPrecio: number = 0;
    granTotal: number = 0;

    constructor(private _title: Title,
        private _router: Router,
        private _loadSrv: CargandoService,
        private _msjSrv: MensajesService,
        private ssnSrv: SessionService,
        private _paramSrv: DataService,
        private config: PortalConfigService,
        private _dlgSrv: ConfirmacionService,
        private _pedidoSrv: PedidoService,
        private dialog: MatDialog) {
        config.app = 'Detalle Pedido';
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.pedidoSelect = null
        this.infoPedido = null;
    }

    private InitComponente(): void {
        this._loadSrv.toggle(true);
        this.pedidoSelect = this._paramSrv.sessionMap['PedidoSelect'];
        if (this.pedidoSelect == null) {
            this.cancelar();
        } else {
            // console.log(JSON.stringify(this.pedidoSelect));
            this._loadSrv.toggle(false);
            this.getInfoPedido();
        }
    }

    private getInfoPedido(): void {
        this._loadSrv.toggle(true);
        this._pedidoSrv.dameDetallePedido(this.pedidoSelect.id_pedido, this.ssnSrv.principal.nivel).toPromise().then(
            res => {
                // console.log(JSON.stringify(res));
                this.infoPedido = res;
                this.actualizaTotales();
                this.inicializaBotonera();
                this._loadSrv.toggle(false);
            }).catch(error => {
                console.log('Error::VerPedidoComponent::getInfoPedido::', error);
                this._loadSrv.toggle(false);
                this._msjSrv.agregaError('Error al buscar el pedido, intente más tarde.');
                this.cancelar();
            });
    }

    private inicializaBotonera(): void {
        this._loadSrv.toggle(true);        
        this.esEnviarAutorizacion = false;
        this.esSubirAutorizacion = false;
        this.esAvanzar = false;
        this.esRechazar = false;
        this.esEditar = false;
        this.esReditar = false;
        this.esRetomar = false;
        this.esCancelar = false;
        this.esClonar = false;

        if (this.ssnSrv.principal.almacen === 'T' && (this.infoPedido.estatus === 'GENERADO' || this.infoPedido.estatus === 'CLIENTE')) {
            this.esEnviarAutorizacion = true;
        }

        if (this.ssnSrv.principal.almacen === 'T' && (this.infoPedido.estatus === 'CLIENTE')) {
            this.esSubirAutorizacion = true;
        }

        if(this.infoPedido.estatus === 'CONCLUIDO') {
            if (this.infoPedido.tipo_pedido === 'PE' || this.infoPedido.tipo_pedido === 'PT') {
                this.esCancelar = true;
            } else {
                if (Number(this.ssnSrv.principal.nivel) === 5) {
                    this.esCancelar = true;
                }
            }
        }

        if (this.ssnSrv.principal.almacen === 'P') {
            if (this.infoPedido.estatus === 'GENERADO' || this.infoPedido.estatus === 'VENTAS') {
                this.esReditar = true;
            }
        }

        if (this.ssnSrv.principal.almacen === 'P') {
            this.esClonar = true;
        }

        this._pedidoSrv.damePosibilidadesPedido(this.infoPedido.tipo_pedido, this.ssnSrv.principal.nivel, this.infoPedido.estatus).subscribe(
            resPos => {
                resPos.forEach(e => {
                    if (e.tipo_flujo === 'A') {
                        this.esAvanzar = true;
                    }
                    if (e.tipo_flujo === 'R') {
                        this.esRechazar = true;
                    }
                    if (e.es_afectar === 'V') {
                        this.esAfectar = true;
                    }
                    /*
                    if (e.es_cancelar === 'V') {
                        this.esCancelar = true;
                    }*/
                    this._loadSrv.toggle(false);
                });
                if (this.ssnSrv.principal.almacen === 'T' && (this.infoPedido.estatus === 'GENERADO')) {
                    this.esAvanzar = false;
                }
                // Si esta rechazado en el nivel de agente permite editar y volver a enviar
                if (this.infoPedido.estatus === 'RECHAZADO' && (Number(this.ssnSrv.principal.nivel) === 6 || Number(this.ssnSrv.principal.nivel) === 3)) {
                    this.esAvanzar = false;
                    this.esEditar = true;
                }
                if (this.infoPedido.estatus === 'CANCELADO' && (Number(this.ssnSrv.principal.nivel) === 6 || Number(this.ssnSrv.principal.nivel) === 3)) {
                    this.esAvanzar = false;
                    this.esRetomar = true;
                }
            },
            errPos => {
                console.log('Error::VerPedidoComponent::inicializaBotonera::', errPos);
                this._loadSrv.toggle(false);
                this._msjSrv.agregaError('Error al buscar el pedido, intente más tarde.');
                this.cancelar();
            }            
        )

    }

    navegar(ruta: string) {
        this._router.navigate([ruta]);
    }

    cancelar(): void {
        this.navegar('/pedidos');
    }

    mandaAutorizacion(): void {
        const dialogRef = this.dialog.open(AutorizacionCtePedidoComponent, {
            width: '95%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                id_pedido: this.infoPedido.id_pedido
            }
        });
        
        dialogRef.afterClosed().subscribe(result => {
            if (result !== null) {
                if (Number(result.Code) === 0) {
                    if (this.infoPedido.estatus === 'GENERADO' && this.infoPedido.almacen === 'T') {
                        this.flujoPedido(false, 'Se ha enviado la autorización al cliente, esta en espera de su Vo.Bo.');
                    }
                }
            }
        });
    }

    subirAutorizacion(): void {
        const dialogRef = this.dialog.open(UploadAutorizacionCteComponent, {
            width: '50%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                id_pedido: this.infoPedido.id_pedido
            }
        });
        
        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
        });
    }

    downloadAutorizacion(): void {
        this._loadSrv.toggle(true);
        this.descargaBase64(this.infoPedido.url_autorizacion.base64, this.infoPedido.url_autorizacion.type, this.infoPedido.url_autorizacion.nameFile);
        this._loadSrv.toggle(false);
    }

    avanzaPedidos(): void {
        // Validaciones iniciales
        // Si es pedido de telas valida exista una autorizacion cargada
        if (this.infoPedido.almacen === 'T' && this.infoPedido.url_autorizacion === null) {
            this._msjSrv.agregaWarn("Para avanzar el pedido debe anexar la autorización del cliente.");
        } else {
            const dialogRef = this.dialog.open(ComentarioPedidoComponent, {
                width: '80%',
                closeOnNavigation: true,
                disableClose: true,
                hasBackdrop: true,
                data: {
                    titulo: 'Comentario adicional para el pedido ['+ this.infoPedido.referencia +']',
                    requerido: false
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result !== null) {
                    let mensajeAviso = this.esAfectar ? 'El pedido se enviará para la afectación en Intelisis, ¿Desea continuar?' : 'Se avanzará el pedido a su siguiente flujo, ¿Desea continuar?';
                    this._dlgSrv.confirmarSimple(mensajeAviso).subscribe(
                        res => {
                            if (res) {
                                if (this.esAfectar) {
                                    this.afectaIntelisis(result.Comentario);
                                } else {
                                    this.flujoPedido(false, result.Comentario);
                                }
                            }
                        }
                    );
                }
            });
        }
    }

    retrocedePedidos(): void {
        const dialogRef = this.dialog.open(ComentarioPedidoComponent, {
            width: '80%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                titulo: 'Motivo de rechazo para el pedido ['+ this.infoPedido.referencia +']',
                requerido: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== null) {
                if (result.Comentario !== null) {
                    const mensaje = 'El pedido se regresa por rechazo, ¿Desea continuar?';
                    this._dlgSrv.confirmarSimple(mensaje).subscribe(
                        res => {
                            if (res) {
                                this.flujoPedido(true, result.Comentario);
                            } 
                        }
                    );
                }
            }
        });
    }

    cancelarPedido(): void {
        const dialogRef = this.dialog.open(ComentarioPedidoComponent, {
            width: '80%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                titulo: 'Motivo de cancelación para el pedido ['+ this.infoPedido.referencia +']',
                requerido: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== null) {
                if (result.Comentario !== null) {
                    const mensaje = 'El pedido se cancelará en Intelisis, ¿Desea continuar?';
                    this._dlgSrv.confirmarSimple(mensaje).subscribe(
                        res => {
                            if (res) {
                                this.cancelaIntelisis(result.Comentario);
                            } 
                        }
                    );
                }
            }
        });
    }

    generaReporte(): void {
        this._loadSrv.toggle(true);
        if (this.ssnSrv.principal.almacen === 'T') {
            this._pedidoSrv.getPedidoReporte(this.infoPedido.id_pedido, this.ssnSrv.principal.nivel).subscribe(
                resDet => {
                    if (resDet.code === 0) {
                        this.descargaBase64(resDet.reporte, 'application/pdf', 'Pedido_' + this.infoPedido.id_pedido + '.pdf');
                        this._loadSrv.toggle(false);
                    } else {
                        this._msjSrv.agregaError('Algo no salio bien al generar el reporte del pedido. Intente más tarde.');
                        this._loadSrv.toggle(false);
                    }
                },
                errDet => {
                    this._msjSrv.agregaError('Error al generar el reporte del pedido.');
                    console.log('Error::generaReporte::', errDet);
                    this._loadSrv.toggle(false);
                }
            );
        } else {
            this._pedidoSrv.descargaReporteJack(this.infoPedido.id_pedido, this.ssnSrv.principal.nivel).subscribe(
                result => {
                    this.extractContent(result, 'PedidoWeb_' + this.infoPedido.id_pedido + '.pdf');
                    this._loadSrv.toggle(false);
                }
            );
        }
    }

    descargaBase64(base64Data: string, contentType: string, nombre: string) {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        // const nombre = 'ArchivoDescarga.pdf';
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        if (ua.indexOf('MSIE ') > 0
            || ua.indexOf('Edge') > 0
            || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            window.navigator.msSaveOrOpenBlob(blob, nombre);
        } else {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = nombre;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }

    private extractContent(res: HttpResponse<Blob>, nombre: string): void {
        const blob: Blob = res.body;
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        if (ua.indexOf('MSIE ') > 0
          || ua.indexOf('Edge') > 0
          || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
          window.navigator.msSaveOrOpenBlob(blob, nombre);
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = nombre;
          a.click();
          window.URL.revokeObjectURL(url);
        }
    }

    private flujoPedido(esRetroceder: boolean, comentario: string): void {
        this._loadSrv.toggle(true);
        this._pedidoSrv.ContinuaFlujoPedido(this.infoPedido.id_pedido,
                                            this.ssnSrv.principal.id_usuario,
                                            this.pedidoSelect.id_estatus,
                                            this.infoPedido.tipo_pedido,
                                            (esRetroceder ? 'R' : 'A'),
                                            comentario).subscribe(
            resAv => {
                if(resAv.code === 0) {
                    this._msjSrv.agregaInfo(resAv.message);
                    this.cancelar();
                } else {
                    this._msjSrv.agregaError(resAv.message);
                }
                this._loadSrv.toggle(false);
            },
            errAv => {
                console.log('Error::VerPedidoComponent::flujoNormal::', errAv);
                this._msjSrv.agregaError('Error al avanzar el pedido, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        );
    }

    private actualizaTotales(): void {
        this.totalCantidad = 0;
        this.totalPrecio = 0;
        this.granTotal = 0;
        this.infoPedido.articulos.forEach(e => {
            this.totalCantidad = this.totalCantidad + Number(e.cantidad);
            this.totalPrecio = this.totalPrecio + Number(e.precio);
            this.granTotal = this.granTotal + (Number(e.precio) * Number(e.cantidad));
        });
    }

    editarPedido(): void {
        this._paramSrv.sessionMap['TipoEdicion'] = 'E';
        this._paramSrv.sessionMap['OpcionEdicion'] = 'N';
        this._paramSrv.sessionMap['id_pedido'] = this.infoPedido.id_pedido;
        this.navegar('/pedidos/create');
    }

    reditarPedido(): void {
        this._paramSrv.sessionMap['TipoEdicion'] = 'E';
        this._paramSrv.sessionMap['OpcionEdicion'] = 'A';
        this._paramSrv.sessionMap['id_pedido'] = this.infoPedido.id_pedido;
        this.navegar('/pedidos/create');
    }

    retomarPedido(): void {
        this._paramSrv.sessionMap['TipoEdicion'] = 'R';
        this._paramSrv.sessionMap['OpcionEdicion'] = 'N';
        this._paramSrv.sessionMap['id_pedido'] = this.infoPedido.id_pedido;
        this.navegar('/pedidos/create');
    }

    clonarPedido(): void {
        this._paramSrv.sessionMap['TipoEdicion'] = 'R';
        this._paramSrv.sessionMap['OpcionEdicion'] = 'A';
        this._paramSrv.sessionMap['id_pedido'] = this.infoPedido.id_pedido;
        this.navegar('/pedidos/create');
    }

    private afectaIntelisis(comentario): void {
        this._loadSrv.toggle(true);
        this._pedidoSrv.RegistroPedidoIntelisis(this.infoPedido.id_pedido, this.ssnSrv.principal.id_usuario).subscribe(
            resReg => {
                if(resReg.code === 0) {
                    this.flujoPedido(false, comentario);
                } else {
                    this._msjSrv.agregaError(resReg.message);
                    this._loadSrv.toggle(false);
                }
            },
            errReg => {
                console.log('Error::VerPedidoComponent::afectaIntelisis::', errReg);
                this._msjSrv.agregaError('Error al registrar el pedido, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        );
    }

    private cancelaIntelisis(comentario): void {
        this._loadSrv.toggle(true);
        this._pedidoSrv.CancelaPedidoIntelisis(this.infoPedido.ID).subscribe(
            resReg => {
                if(resReg.code === 0) {
                    this.flujoPedido(true, comentario);
                } else {
                    this._msjSrv.agregaError(resReg.message);
                    this._loadSrv.toggle(false);
                }
            },
            errReg => {
                console.log('Error::VerPedidoComponent::cancelaIntelisis::', errReg);
                this._msjSrv.agregaError('Error al cancelar el pedido, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        );
    }


    cambiaUPC(id_pedidoD: number, upcActual: string): void {
        const dialogRef = this.dialog.open(UpdateUPCPedidoComponent, {
            width: '60%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                id_pedido: this.infoPedido.id_pedido,
                id_pedidoD: id_pedidoD,
                upcActual: upcActual,
                estatus: this.infoPedido.estatus
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getInfoPedido();
        });
    }

    cambiaCantidad(id_pedidoD: number, cantidadActual: number): void {
        // console.log('Cambia cantidad:: ' + id_pedidoD + ' ' + cantidadActual);
        //console.log(JSON.stringify(this.infoPedido));
        if (this.infoPedido.estatus !== 'CONCLUIDO' && this.infoPedido.estatus !== 'CANCELADO') {
            const dialogRef = this.dialog.open(UpdateCantidadPedidoComponent, {
                width: '60%',
                closeOnNavigation: true,
                disableClose: true,
                hasBackdrop: true,
                data: {
                    id_pedido: this.infoPedido.id_pedido,
                    id_pedidoD: id_pedidoD,
                    cantidadActual: cantidadActual,
                    estatus: this.infoPedido.estatus
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.getInfoPedido();
            });
        } else {
            this._msjSrv.agregaWarn('No se permite modificar cantidades a pedidos con estatus: ' + this.infoPedido.estatus);
        }
    }

    cambiaVariante(id_pedidoD: number, articulo: any) {
        if (this.infoPedido.estatus !== 'CONCLUIDO' && this.infoPedido.estatus !== 'CANCELADO') {
            const dialogRef = this.dialog.open(UpdateOpcionesPedidoComponent, {
                width: '60%',
                closeOnNavigation: true,
                disableClose: true,
                hasBackdrop: true,
                data: {
                    id_pedido: this.infoPedido.id_pedido,
                    id_pedidoD: id_pedidoD,
                    artActual: articulo,
                    articulos: this.infoPedido.articulos,
                    estatus: this.infoPedido.estatus
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.getInfoPedido();
            });
        } else {
            this._msjSrv.agregaWarn('No se permite modificar variantes a pedidos con estatus: ' + this.infoPedido.estatus);
        }
    }

}
