import { Injectable } from '@angular/core';

@Injectable()
export class KrbAppsService {

    private _aplicaciones: Aplicacion[] = [
        { Id: 1, Nombre: 'PortalE', cveAplicacion: 'PE', Descripcion: 'Portal para empleados' },
    ];

    get aplicaciones(): Aplicacion[] {
        return this._aplicaciones;
    }
}

export interface Aplicacion {
    Id: number;
    Nombre: string;
    cveAplicacion?: string;
    Descripcion: string;
    IdRol?: number;
}
