import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CargandoService, ConfirmacionService, SessionService, MensajesService, DataService } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { PedidoService } from './services/pedidos.service';
import { ClienteSelected } from './modelos/ClienteSelected';
import { PedidoArticulos } from './modelos/PedidoArticulos';
import { MatStepper, MatDialog } from '@angular/material';
import { AutorizacionCtePedidoComponent } from './autorizacion-cte/autorizacion-cte.component';
import { SelectorTipoPedidoComponent } from './selector-tipo-pedido/selector-tipo-pedido.component';
import { DlgAddArticuloComponent } from './dlg-add-art/dlg-add-art.component';

@Component({
  selector: 'krb-new-pedidos',
  templateUrl: './html/new-pedido.component.html',
  styleUrls: ['./css/new-pedido.component.css']
})
export class NuevoPedidoComponent implements OnInit {

    isTrabajando: boolean = true;
    tipo_pedido: string;
    tipo_edicion: string;
    opcion_edicion: string;
    id_pedido_retomado: any = null;
    id_pedido_edicion: number = null;

    pedidoForm: FormGroup;
    clienteForm: FormGroup;
    monedas: any[];
    agentes: any[];
    conceptos: any[];
    centroCostos: any[];
    condiciones: any[];
    pedidosRefs: any[];
    clientes: any[];
    sucursalesCat: any[];
    sucursalesCmb: any[];
    opciones: any[] = [];
    artPedidos: PedidoArticulos[] = [];
    minDaySelected: Date = new Date();

    confirmacion: any;

    responsePedido: any = null;

    totalCantidad: number = 0;
    totalPrecio: number = 0;
    granTotal: number = 0;

    cteIni = null;
    sucursalTmp = null;

    constructor(private _title: Title, 
                private _router: Router,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _config: PortalConfigService,
                private _paramSrv: DataService,
                private _dlgSrv: ConfirmacionService,
                private _pedidoSrv: PedidoService,
                private dialog: MatDialog,
                private datepipe: DatePipe) {
        _config.app = 'Nuevo Pedido';
    }

    ngOnInit(): void {
        this.isTrabajando = true;
        this.InitComponente();
    }

    private InitComponente(): void {
        this.createFormularios();
        this.llenaCombos();
    }

    private llamaSelectorPedido(): void {
        if (this.ssnSrv.principal.almacen === 'T') {
            this.tipo_pedido = 'TE';
            this.obtieneReferencia();
            this.isTrabajando = false;
        } else {

            const dialogRef = this.dialog.open(SelectorTipoPedidoComponent, {
                width: '40%',
                closeOnNavigation: true,
                disableClose: true,
                hasBackdrop: true,
                data: null
            });
            dialogRef.afterClosed().subscribe(result => {
                this.tipo_pedido = result;
                this.obtieneReferencia();
                this.isTrabajando = false;
            });
        }
    }

    private createFormularios(): void {
        this.pedidoForm = this._builder.group({
            requiere_importacion: [false, Validators.required],
            moneda: [null, Validators.required],
            fh_emision: [null, Validators.required],
            fh_requerida: [null, Validators.required],
            agente: [null, Validators.required],
            referencia: [null, Validators.required],
            concepto: [null, Validators.required],
            comentarios: [null, null],
            comprador: [null, null],
            ref_telas: [null, null],
            info_fit: [null, null],
            info_empaque: [null, null],
            ContUso: [null, Validators.required],
            Condicion: [null, null],
            folio_cotizacion: [null, null],
            tipo_cambio: [null, null],
            pedido_ref: [null, null]
          });

          this.clienteForm = this._builder.group({
            cliente: [null, Validators.required],
            matriz: [false, Validators.required],
            sucursal: [null, Validators.required],
          });

    }

    private llenaCombos(): void {
        this.monedas = [
            {value: 'MXN', viewValue: 'Moneda Nacional'},
            {value: 'USD', viewValue: 'Dolar Americano'}
        ];

        this.llenaComboAgentes();
        this.llenaComboConceptos();
        this.llenaComboCentroCostos();
        this.llenaComboCondiciones();
        this.llenaComboPedidosReferencias();

    }

    private llenaComboAgentes(): void {
        this._pedidoSrv.getAgentes(this.ssnSrv.principal.almacen).toPromise().then(
            res => {
                this.agentes = [];
                res.forEach(e => {
                    this.agentes.push({value: e, viewValue: e.nombre});
                });
                this.validaAgente();
            }).catch(error => {
                console.error('Error::NuevoPedidoComponent::llenaComboAgentes::', error);
                this._msjSrv.agregaError('Error al buscar los agentes, intente más tarde.');
            }
        );
    }

    private llenaComboConceptos(): void {
        this._pedidoSrv.getConceptos().toPromise().then(
            res => {
                this.conceptos = [];
                res.forEach(e => {
                    this.conceptos.push({value: e.concepto, viewValue: e.concepto});
                });
            }).catch(error => {
                console.error('Error::NuevoPedidoComponent::llenaComboConceptos::', error);
                this._msjSrv.agregaError('Error al buscar los conceptos, intente más tarde.');
            }
        );
    }

    private llenaComboCentroCostos(): void {
        this.centroCostos = [];
        this._pedidoSrv.getCentroCosto().toPromise().then(
            res => {
                res.forEach(e => {
                    this.centroCostos.push({value: e, viewValue: '[' + e.CentroCostos + '] ' + e.Descripcion});
                });
                this.validaCentroConsto();
            }).catch(error => {
                console.error('Error::NuevoPedidoComponent::llenaComboCentroCostos::', error);
                this._msjSrv.agregaError('Error al buscar los centros de costo, intente más tarde.');
            }
        );
    }

    private llenaComboCondiciones(): void {
        this.condiciones = [];
        this._pedidoSrv.getCondiciones().toPromise().then(
            res => {
                res.forEach(e => {
                    this.condiciones.push({value: e, viewValue: e.Condicion});
                });
            }).catch(error => {
                console.error('Error::NuevoPedidoComponent::llenaComboCentroCostos::', error);
                this._msjSrv.agregaError('Error al buscar los centros de costo, intente más tarde.');
            }
        );
    }

    private llenaComboPedidosReferencias(): void {
        this.pedidosRefs = [];
        this.pedidosRefs.push({value: null, viewValue: 'No Aplica'});
        this._pedidoSrv.getPedidosTelasRef().toPromise().then(
            res => {
                res.forEach(e => {
                    this.pedidosRefs.push({value: e, viewValue: e.referencia});
                });
                this.validaEdicion();
            }).catch(error => {
                console.error('Error::NuevoPedidoComponent::llenaComboPedidosReferencias::', error);
                this._msjSrv.agregaError('Error al buscar los pedidos, intente más tarde.');
            }
        );
    }

    public validaTipoCambio(event): void {
        if (this.pedidoForm.value.requiere_importacion) {
            this.pedidoForm.controls['tipo_cambio'].setValidators([Validators.required]);
        } else {
            this.pedidoForm.controls['tipo_cambio'].clearValidators();
        }
        this.pedidoForm.controls['tipo_cambio'].updateValueAndValidity();
    }

    private validaAgente(): void {
        if (Number(this.ssnSrv.principal.nivel) === 3 || Number(this.ssnSrv.principal.nivel) === 6) {
            let agenteObj = null;
            this.agentes.forEach(e => {
                if (e.value.agente === this.ssnSrv.principal.agente) {
                    agenteObj = e.value;
                }
            });
            this.pedidoForm.controls.agente.patchValue(agenteObj);
            this.pedidoForm.controls.agente.disable();
        } else {
            this.pedidoForm.controls.agente.patchValue(null);
            this.pedidoForm.controls.agente.enable();
        }
    }

    private validaCentroConsto(): void {

        let objCentroCosto = null;
        this.centroCostos.forEach(e => {
            if (this.ssnSrv.principal.almacen === 'P') {
                if (e.value.CentroCostos === 'C002') {
                    objCentroCosto = e;
                }
            } else {
                if (e.value.CentroCostos === 'C001') {
                    objCentroCosto = e;
                }
            }
        });

        this.pedidoForm.controls.ContUso.patchValue(objCentroCosto);
        this.pedidoForm.controls.ContUso.disable();

    }

    private obtieneReferencia(): void {
        this._pedidoSrv.getNextReferencia(this.tipo_pedido).toPromise().then(
            res => {
                this.pedidoForm.controls.referencia.patchValue(res.ReferenciaPedido);
                this._loadSrv.toggle(false);
            }).catch(error => {
                console.error('Error::NuevoPedidoComponent::obtieneReferencia::', error);
                this._loadSrv.toggle(false);
                this._msjSrv.agregaError('Error al buscar la referencia, intente más tarde.');
            }
        );
    }

    private cargaSucursales(): void {
        if (this.clienteForm.value.cliente !== null) {
            this.llenaSucursales();
        } else {
            this.sucursalesCmb = [];
        }
    }

    private llenaSucursales(): void {
        this._loadSrv.toggle(true);
        this._pedidoSrv.getSucursalesCliente(this.clienteForm.value.cliente.Cliente).toPromise().then(
            res => {
                this.sucursalesCmb = [];
                this.sucursalesCat = [];
                res.forEach(e => {
                    this.sucursalesCmb.push({value: e.ID, viewValue: e.Nombre});
                });
                this.sucursalesCat = res;
                if (this.sucursalTmp !== null) {
                    this.clienteForm.controls.sucursal.enable();
                    this.clienteForm.controls.sucursal.patchValue(this.sucursalTmp);
                    this.sucursalTmp = null;
                }
                this._loadSrv.toggle(false);
            }).catch(error => {
                console.error('Error::NuevoPedidoComponent::llenaSucursales::', error);
                this._loadSrv.toggle(false);
                this._msjSrv.agregaError('Error al buscar los clientes, intente más tarde.');
            }
        );
    }

    validaMatriz(event) {
        if (this.clienteForm.value.matriz) {
            this.sucursalesCmb = [];
            this.clienteForm.controls.sucursal.patchValue(null);
            this.clienteForm.controls.sucursal.disable();
        } else {
            this.clienteForm.controls.sucursal.patchValue(null);
            this.clienteForm.controls.sucursal.enable();
            this.cargaSucursales();
        }
    }

    setCliente(cliente: ClienteSelected) {
        this.clienteForm.controls.cliente.patchValue(cliente);
        this.cargaSucursales();
    }

    addArticulo(): void {

        const dialogRef = this.dialog.open(DlgAddArticuloComponent, {
            width: '50%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: { 
                tipo_pedido: this.tipo_pedido,
                requiere_importacion: this.pedidoForm.value.requiere_importacion
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                result.forEach(e => {
                    this.artPedidos.push(e);
                });
                this.actualizaTotales();
            }
        });
    }

    private actualizaTotales(): void {
        this.totalCantidad = 0;
        this.totalPrecio = 0;
        this.granTotal = 0;
        this.artPedidos.forEach(e => {
            this.totalCantidad = this.totalCantidad + Number(e.cantidad);
            this.totalPrecio = this.totalPrecio + Number(e.precio);
            this.granTotal = this.granTotal + (Number(e.precio) * Number(e.cantidad));
        });
    }

    deleteArtAdd(artDeleted: PedidoArticulos): void {
        const mensaje = 'Se quitara el artículo del pedido, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
          res => {
            if (res) {
                this.artPedidos.splice(this.artPedidos.indexOf(artDeleted), 1);
                this.actualizaTotales();
            }
        });
    }

    next_step(stepper: MatStepper) {
        if (stepper.selectedIndex === 2) {
            if (this.artPedidos.length <= 0) {
                this._msjSrv.agregaWarn('Debe agregar al menos un artículo al pedido.');
            } else {
                // Se va a confirmacion
                this.joinToInformation();
                stepper.next();
            }
        } else if (stepper.selectedIndex === 3) {
            if (this.tipo_edicion === 'E') {
                this.updatePedido(stepper);
            } else {
                this.insertaPedido(stepper);
            }
        } else {
            stepper.next();
        }
    }

    private joinToInformation(): void {
        this.confirmacion = null;
        this.confirmacion = {
            referencia: this.pedidoForm.value.referencia,
            fechaEmision: this.pedidoForm.value.fh_emision,
            requiere_importacion: this.pedidoForm.value.requiere_importacion,
            moneda: this.pedidoForm.value.moneda,
            fechaRequerida: this.datepipe.transform(this.pedidoForm.value.fh_requerida, 'yyyy-MM-dd'),
            agente: this.pedidoForm.getRawValue().agente,
            concepto: this.pedidoForm.value.concepto,
            comentarios: this.pedidoForm.value.comentarios,
            comprador: this.pedidoForm.value.comprador,
            ref_telas: this.pedidoForm.value.ref_telas,
            info_fit: this.pedidoForm.value.info_fit,
            info_empaque: this.pedidoForm.value.info_empaque,
            cliente: this.clienteForm.value.cliente,
            sucursal_matriz: this.clienteForm.value.matriz,
            sucursal: this.dameObjCombo(this.clienteForm.value.sucursal, 'Sucursal'),
            ContUso: this.pedidoForm.getRawValue().ContUso,
            Condicion: this.pedidoForm.value.Condicion,
            almacen: this.ssnSrv.principal.almacen,
            folio_cotizacion: this.pedidoForm.value.folio_cotizacion,
            tipo_cambio: this.pedidoForm.getRawValue().tipo_cambio,
            id_pedido_tela: this.pedidoForm.getRawValue().pedido_ref,
            tipo_pedido: this.tipo_pedido,
            id_pedido_retomado: this.id_pedido_retomado,
            id_pedido_edicion: this.id_pedido_edicion,
            tipo_edicion: this.tipo_edicion,
            opcion_edicion: this.opcion_edicion,
            articulos: this.artPedidos
        };
        // console.log('Confirmacion.... ', JSON.stringify(this.confirmacion));
    }

    private insertaPedido(stepper: MatStepper): void {
        const mensaje = 'Se generará el pedido, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
          res => {
            if (res) {
                this._loadSrv.toggle(true);
                this.responsePedido = null;
                this._pedidoSrv.InsertPedido(this.confirmacion, this.ssnSrv.principal.id_usuario).toPromise().then(
                    res => {
                        if(res.code === 0) {
                            this.responsePedido = res;
                            this._msjSrv.agregaInfo(res.message);
                            stepper.next();
                        } else {
                            this._msjSrv.agregaError(res.message);
                        }
                        this._loadSrv.toggle(false);
                    }).catch(error => {
                        console.log('Error::NuevoPedidoComponent::insertaPedido::', error);
                        this._msjSrv.agregaError('Error al insertar el pedido, intente más tarde.');
                        this._loadSrv.toggle(false);
                    }
                );
            }
          });
    }

    private updatePedido(stepper: MatStepper): void {
        const mensaje = 'Se actualizará el pedido y se enviará a su siguiente flujo, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
          res => {
            if (res) {
                this._loadSrv.toggle(true);
                this.responsePedido = null;
                this._pedidoSrv.UpdatePedido(this.confirmacion, this.ssnSrv.principal.id_usuario).toPromise().then(
                    res => {
                        if(res.code === 0) {
                            this.responsePedido = res;
                            this._msjSrv.agregaInfo(res.message);
                            stepper.next();
                        } else {
                            this._msjSrv.agregaError(res.message);
                        }
                        this._loadSrv.toggle(false);
                    }).catch(error => {
                        console.log('Error::NuevoPedidoComponent::updatePedido::', error);
                        this._msjSrv.agregaError('Error al actualizar el pedido, intente más tarde.');
                        this._loadSrv.toggle(false);
                    }
                );
            }
          });
    }

    mandaAutorizacion(): void {
        const dialogRef = this.dialog.open(AutorizacionCtePedidoComponent, {
            width: '95%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                id_pedido: this.responsePedido.id_pedido
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.irPedidos();
        });
    }

    irPedidos(): void {
        this.navegar('/pedidos');
    }

    navegar(ruta: string) {
        this._router.navigate([ruta]);
    }

    private validaEdicion(): void {
        // Declara variables        
        // Obtiene el tipo de pedidos
        this.tipo_edicion = null;
        this.opcion_edicion = null;
        const tipoTemp = this._paramSrv.sessionMap['TipoEdicion'];
        const optEdicionTmep = this._paramSrv.sessionMap['OpcionEdicion'];
        // Valida el tipo de pedido
        if (tipoTemp === undefined || tipoTemp === null) {
            this.tipo_edicion = 'N';
        } else {
            this.tipo_edicion = tipoTemp;
        }
        // Valida opcion de edicion
        if (optEdicionTmep === undefined || optEdicionTmep === null) {
            this.opcion_edicion = 'N';
        } else {
            this.opcion_edicion = optEdicionTmep;
        }
        // Realiza accion dependiendo del pedido
        if (this.tipo_edicion === 'N') {
            this.pedidoForm.controls.fh_emision.patchValue(Date.now());
            this._config.app = 'Nuevo Pedido';
            setTimeout(() => {
                this.llamaSelectorPedido();
            }, 500);
        } else {
            let id_pedidoTmp = this._paramSrv.sessionMap['id_pedido'];
            this.recuperaInfoPedido(id_pedidoTmp);
        }
        this._paramSrv.sessionMap['TipoEdicion'] = null;
        this._paramSrv.sessionMap['id_pedido'] = null;
    }

    private recuperaInfoPedido(id_pedidoTemp: any): void {
        this._pedidoSrv.RecuperaInfoPedido(id_pedidoTemp).subscribe(
            resPed => {
                if (Number(resPed.code) === 0) {
                    this.distribuyeInfo(resPed);
                } else {
                    this._msjSrv.agregaError('Error al solicitar la información del pedido, intente más tarde.');
                    this.navegar('/pedidos/create');
                }
            },
            errPed => {
                this._msjSrv.agregaError('Error al solicitar la información del pedido, intente más tarde.');
                console.error('ERROR:::recuperaInfoPedido', errPed);
                this.navegar('/pedidos/create');
            }
        );
    }

    private distribuyeInfo(pedido: any): void {

        this.tipo_pedido = pedido.tipo_pedido;
        if (this.tipo_edicion === 'R') {
            if (this.opcion_edicion === 'N') {
                this._config.app = 'Retomar Pedido';
                this.id_pedido_retomado = {id_pedido: pedido.id_pedido, referencia: pedido.referencia};
            } else {
                this._config.app = 'Clonar Pedido';
                this.id_pedido_edicion = null;
            }
            this.pedidoForm.controls.fh_emision.patchValue(Date.now());
            this.obtieneReferencia();
        } else {
            if (this.opcion_edicion === 'N') {
                this._config.app = 'Reenviar Pedido';
            } else {
                this._config.app = 'Editar Pedido';
            }
            this.pedidoForm.controls.referencia.patchValue(pedido.referencia);
            this.pedidoForm.controls.fh_emision.patchValue(pedido.fh_emision);
            this.id_pedido_edicion = pedido.id_pedido;
            // this.id_pedido_retomado = pedido.id_pedido_retomado;
            this.id_pedido_retomado = {id_pedido: pedido.id_pedido_retomado, referencia: pedido.ref_pedido_retomado};
        }

        this.pedidoForm.controls.requiere_importacion.patchValue((pedido.requiere_importacion === 'V' ? true : false));
        this.pedidoForm.controls.moneda.patchValue(this.dameObjCombo(pedido.moneda, 'Moneda'));
        this.pedidoForm.controls.fh_requerida.patchValue(pedido.f_requerida);

        this.pedidoForm.controls.agente.enable();
        this.pedidoForm.controls.agente.patchValue(this.dameObjCombo(pedido.Agente, 'Agente'));
        this.pedidoForm.controls.agente.disable();

        this.pedidoForm.controls.concepto.patchValue(pedido.concepto);

        this.pedidoForm.controls.comentarios.patchValue(pedido.comentarios);
        this.pedidoForm.controls.comprador.patchValue(pedido.comprador);
        this.pedidoForm.controls.ref_telas.patchValue(pedido.ref_telas);
        this.pedidoForm.controls.info_fit.patchValue(pedido.info_fit);
        this.pedidoForm.controls.info_empaque.patchValue(pedido.info_empaque);
        
        this.pedidoForm.controls.ContUso.enable();
        this.pedidoForm.controls.ContUso.patchValue(this.dameObjCombo(pedido.ContUso, 'CC'));
        this.pedidoForm.controls.ContUso.disable();

        this.pedidoForm.controls.Condicion.patchValue(this.dameObjCombo(pedido.Condicion, 'Condiciones'));
        this.pedidoForm.controls.folio_cotizacion.patchValue(pedido.folio_cotizacion);
        this.pedidoForm.controls.tipo_cambio.patchValue(pedido.tipo_cambio);
        this.pedidoForm.controls.pedido_ref.patchValue(this.dameObjCombo(pedido.id_pedido_tela, 'Telas'));

        this.sucursalTmp = pedido.id_sucursal;
        this.cteIni = pedido.Cliente;
        this.clienteForm.controls.matriz.patchValue((pedido.sucursal_matriz === 'V' ? true : false));
        if (pedido.sucursal_matriz === 'V') {
            this.clienteForm.controls.sucursal.patchValue(null);
            this.clienteForm.controls.sucursal.disable();
        }

        this.artPedidos = pedido.Articulos;
        this.actualizaTotales();
        this.isTrabajando = false;
    }

    private dameObjCombo(key: any, lista: string): void {
        let obj = null;
        // Catalogo de monedas
        if (lista === 'Moneda') {
            this.monedas.forEach(moneda => {
                if (moneda.value === key) {
                    obj = moneda;
                }
            });
        }
        // Catalogo de agentes
        if (lista === 'Agente') {
            this.agentes.forEach(e => {
                if (e.value.agente === key) {
                    obj = e.value;
                }
            });
        }
        // Centro de costos
        if (lista === 'CC') {
            this.centroCostos.forEach(e => {
                if (e.value.CentroCostos === key) {
                    obj = e;
                }
            });
        }
        // Ref telas
        if (lista === 'Telas') {
            this.pedidosRefs.forEach(e => {
                if (e.value !== null) {
                    if (Number(e.value.id_pedido) === Number(key)) {
                        obj = e;
                    }
                }
            });
        }
        // Centro de Condiciones
        if (lista === 'Condiciones') {
            this.condiciones.forEach(e => {
                if (e.value.Condicion === key) {
                    obj = e.value;
                }
            });
        }
        
        if (lista === 'Sucursal') {
            this.sucursalesCat.forEach(e => {
                if (Number(e.ID) === Number(key)) {
                    obj = e;
                }
            });
        }
        return obj;
    }
 
}
