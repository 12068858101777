import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthGuard, Login2Guard } from './guards';
import { Auth2Service, IdlebootService } from './services';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [Auth2Service, AuthGuard, Login2Guard, IdlebootService]
})
export class SecurityModule { }
