import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import 'rxjs/add/observable/interval';
import { SessionService, MensajesService, CargandoService, DataService } from 'app/shared';
import { NotificacionesService } from './notificaciones.service';
import { Observable } from 'rxjs';
import { NotificacionDetalleComponent } from './det-noti/det-noti.component';
import { MatDialog } from '@angular/material';

  @Component({
    selector: 'karabe-notificaciones-menu',
    templateUrl: './noti-modal.component.html',
    styleUrls: []
  })
  export class NotificacionesMenuComponent implements OnInit {

    actividad: any[];
    numNotificaciones: number;
  
    constructor(private ssnSrv: SessionService,
                private msjSrv: MensajesService,
                private _router: Router,
                private _loadSrv: CargandoService,
                private _paramSrv: DataService,
                private _notiSrv: NotificacionesService,
                private dialog: MatDialog) {}
  
    ngOnInit() {
        this.actividad = [];
        this.numNotificaciones = 0;
        this.initComponente();
        Observable.interval(5 * 60 * 1000).subscribe(x => {
            this.initComponente();
        });

        this._router.events.subscribe(
        (changeRoute) => {
            if (changeRoute instanceof NavigationEnd) {
                this.initComponente();
            }
        }
        );
    }

    private initComponente(): void {
        this.actividad = [];
        this.numNotificaciones = 0;
        if (this.ssnSrv.principal !== null) {
            this._notiSrv.getNotificaciones(this.ssnSrv.principal.id_usuario).subscribe(
                resNoti => {
                    this.actividad = resNoti
                    this._notiSrv.getCountNotificaciones(this.ssnSrv.principal.id_usuario).subscribe(
                        resNum => {
                            if (resNum[0].TOTAL > this.numNotificaciones) {
                                this.msjSrv.agregaInfo('Tiene nuevas notificaciones.');
                            }
                            this.numNotificaciones = resNum[0].TOTAL;
                        },
                        errNum => {
                            console.log('Error al recuperar las notificaciones::: ', errNum);
                            this.msjSrv.agregaError('Algo no esta bien, no se pudieron recuperar las notificaciones')                        
                        }
                    );
                },
                errNoti => {
                    console.log('Error al recuperar las notificaciones::: ', errNoti);
                    this.msjSrv.agregaError('Algo no esta bien, no se pudieron recuperar las notificaciones')
                }
            );
        }
    }

    verNotificacion(noti: any): void {
        if (noti.es_leido === 'F') {
            this._loadSrv.toggle(true);
            this.managerNotificacion('L', noti.id_notificacion);
        }
        this.dialog.open(NotificacionDetalleComponent, {
            width: '30%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                notiSelected: noti,
            }
        });
    }

    eliminarNoti(noti: any): void {
        this._loadSrv.toggle(true);
        this.managerNotificacion('E', noti.id_notificacion);
    }

    managerNotificacion(accion: string, id_noti: number): void {
        this._notiSrv.managerNotificacion(accion, id_noti).subscribe(
            res => { console.log('Actualiza Notificacion:::', JSON.stringify(res)); this.initComponente(); this._loadSrv.toggle(false); },
            err => { console.log('Errro Notificacion:::', JSON.stringify(err)); this.initComponente(); this._loadSrv.toggle(false); }
        );
    }

    irAccion(noti: any): void {
        this.managerNotificacion('L', noti.id_notificacion);
        this.damePedidoSelecionado(noti.acccion);
    }

    private navegar(ruta: string) {
        this._router.navigate([ruta]);
    }

    private damePedidoSelecionado(id_pedido: any): void {
        this._loadSrv.toggle(true);
        this._notiSrv.getPedidoSelected(id_pedido, 
            this.ssnSrv.principal.almacen,
            this.ssnSrv.principal.nivel,
            (this.ssnSrv.principal.agente.length === 0 ? null : this.ssnSrv.principal.agente)).subscribe(
            res => {
                this._paramSrv.sessionMap['PedidoSelect'] = res;
                this.navegar('/pedidos/view');
                //this._loadSrv.toggle(false);
            }, err => {
                console.error('ERROR:Notificaciones::damePedidoSelecionado', JSON.stringify(err));
                this.msjSrv.agregaError('Error al acceder al pedido, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        );
    }

  }