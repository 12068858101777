import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from './../../../../shared/utils/store-management';

@Injectable()
export class UpcService {

    private _relPath = {
        dameValoresUPCURL: '/services/upc/dameValoresUPC.php',
        updateConfUpcURL: '/services/upc/updateConfUpc.php'
    };

    constructor(
        @Inject('AUTH_API_ENDPOINT') private api: string,
        private http: HttpClient,
        private _storeSrv: StoreManagement
    ) { }

    dameValoresUPC(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this._storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this._relPath.dameValoresUPCURL}`;
        const param = {};
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => <any>response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('Pedidos', <any>null)),
            );
    }

    updateConfUpc(cod_pais: number, cod_empresa: number, calculo: number, consecutivo: number): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this._storeSrv.getToken()}`
            })
        };

        const url = `${this.api}${this._relPath.updateConfUpcURL}`;
        const param = { cod_pais: cod_pais, cod_empresa: cod_empresa, calculo: calculo, consecutivo: consecutivo };
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => <any>response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('updateConfUpc', <any>null)),
            );
    }



    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(`${operation} failed:`, error); // log to console instead

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


}
