import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { ClientesModel } from './ClientesModel';
import { SucursalModel } from './SucursalesModel';
import { StoreManagement } from './../../../../shared/utils/store-management';
import { DomicilioModel } from './selector-cp/DomicilioModel';

@Injectable()
export class ClientesService {

  private _relPath = {
    getClientesURL: '/services/clientes/getClientes.php',
    getSucursalesURL: '/services/clientes/getSucursales.php',
    AlmacenaClienteURL: '/services/clientes/AlmacenaCliente.php',
    buscaDomicilioURL: '/services/clientes/buscaDomicilio.php',
    AlmacenaSucursalURL: '/services/clientes/AlmacenaSucursal.php',
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient,
    private _storeSrv: StoreManagement
  ) { }

  getClientes(agente: string): Observable<ClientesModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getClientesURL}`;
    const param = { 'agente': agente };
    return this.http
      .post<ClientesModel[]>(url, param, httpOptions)
      .pipe(
        map(response => <ClientesModel[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <ClientesModel[]>null)),
    );
  }

  getSucursales(cliente: string): Observable<SucursalModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getSucursalesURL}`;
    const param = { 'cliente': cliente };
    return this.http
      .post<SucursalModel[]>(url, param, httpOptions)
      .pipe(
        map(response => <SucursalModel[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Sucursales', <SucursalModel[]>null)),
    );
  }

  AlmacenaCliente(cte: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.AlmacenaClienteURL}`;
    const param = { 'cte': cte };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('AlmacenaCliente', <any>null)),
    );
  }

  buscaDomicilio(cp: string): Observable<DomicilioModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.buscaDomicilioURL}`;
    const param = { 'codigo_postal': cp };
    return this.http
      .post<DomicilioModel[]>(url, param, httpOptions)
      .pipe(
        map(response => <DomicilioModel[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Sucursales', <DomicilioModel[]>null)),
    );
  }

  AlmacenaSucursal(sucursal: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.AlmacenaSucursalURL}`;
    const param = { 'sucursal': sucursal };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('AlmacenaSucursal', <any>null)),
    );
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed:`, error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
