import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MatDialog, MatPaginator, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { AgrupadoresService } from './../agrupadores.service';
import { MergeAgpDetComponent } from '../new-det-agrupador/new-det-agrupador.component';

@Component({
    selector: 'krb-det-agrupador',
    templateUrl: './det-agrupador.component.html',
    styleUrls: ['./det-agrupador.component.css']
})
export class AgrupadoresDetalleComponent implements OnInit {

    agrupadorSelected: any;
    agrupadorDetSelected: any;
    esInactivar: boolean = false;
    esActivar: boolean = false;

    displayedColumns = ['NombreDet', 'Situacion'];
    dataSource = new MatTableDataSource<any>();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<AgrupadoresDetalleComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _dlgSrv: ConfirmacionService,
                private dialog: MatDialog,
                private _agpSrv: AgrupadoresService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.agrupadorSelected = null;
        this.agrupadorDetSelected = null;
    }

    private InitComponente(): void {
        this.agrupadorSelected = <any> this.params.agrupadorSelected;
        this.dataSource = new MatTableDataSource<any>(this.agrupadorSelected.Detalle);
        this.dataSource.paginator = this.paginator;
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    seleccionaElemento(elementSelected: any): void {
        this.agrupadorDetSelected = elementSelected;
        this.manegadorBotonera();
    }

    manegadorBotonera(): void {
        this.esInactivar = false;
        this.esActivar = false;
        if (this.agrupadorDetSelected !== null) {
            if (Number(this.agrupadorDetSelected.Situacion) === 1) {
                this.esInactivar = true;
                this.esActivar = false;
            } else {
                this.esInactivar = false;
                this.esActivar = true;
            }
        }
    }

    mergeDetalle(accion: string): void {
        const dialogRef = this.dialog.open(MergeAgpDetComponent, {
            width: '20%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                agrupadorSelected: this.agrupadorSelected,
                agrupadorDetSelected: this.agrupadorDetSelected,
                accion: accion
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            this.seleccionaElemento(null);
            this.updateDetalle();
        });
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }

    updateDetalle(): void {
        this._loadSrv.toggle(true);
        this._agpSrv.getAgrupadores().subscribe(
            resAgp => {
                resAgp.forEach(e => {
                    if (e.NombreEnc === this.agrupadorSelected.NombreEnc) {
                        this.agrupadorSelected = e;
                        this.dataSource = new MatTableDataSource<any>(this.agrupadorSelected.Detalle);
                        this.dataSource.paginator = this.paginator;
                        this._loadSrv.toggle(false);
                    }
                });
            },
            errAgp => {
                console.log("Error:::", errAgp);
                this.cerrarDialogo();
                this._loadSrv.toggle(false);
            }
        );
    }

    cambiaEstatus(estatus: number) {
        this._dlgSrv.confirmarSimple('Se guardaran los cambios del valor del agrupador, ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this._loadSrv.toggle(true);
                    this._agpSrv.MergeAgrupadores('D', 'E', this.agrupadorSelected.NombreEnc, this.agrupadorDetSelected.NombreDet, estatus).subscribe(
                        resWS => {
                            if (resWS.code === 0) {
                                this._msjSrv.agregaOK('Se completo con exitó la accion..');
                                this.ngOnInit();
                                this.seleccionaElemento(null);
                                this.updateDetalle();
                            } else {
                                this._msjSrv.agregaError(resWS.message);    
                            }
                            this._loadSrv.toggle(false);
                        },
                        err => {
                            this._msjSrv.agregaError('Error al completar la acción, intente más tarde.');
                            this._loadSrv.toggle(false);
                        }
                    );
                }
            }
        );
    }
}
