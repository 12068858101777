import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { PedidoService } from './../services/pedidos.service';
import { PedidosModel } from '../modelos/PedidosModel';

@Component({
    selector: 'krb-history-pedido',
    templateUrl: './historial-pedido.component.html',
    styleUrls: ['./historial-pedido.component.css']
})
export class HistorialPedidoComponent implements OnInit {

    historialPedido: any[];
    pedido: PedidosModel;

    constructor(@Inject(MAT_DIALOG_DATA) public pedidoSelected: any,
                public dialogRef: MatDialogRef<HistorialPedidoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _pedidoSrv: PedidoService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.pedido = null;
        this.historialPedido = [];
    }

    private InitComponente(): void {
        this.pedido = <PedidosModel> this.pedidoSelected.pedidoSelected;
        this.obtieneInfoPedido();
    }

    private obtieneInfoPedido(): void {
        this._loadSrv.toggle(true);
        this._pedidoSrv.dameHistorial(this.pedidoSelected.pedidoSelected.id_pedido).toPromise().then(
            res => {
              this.historialPedido = res;
              this._loadSrv.toggle(false);
            }).catch(error => {
              console.log('Error::HistorialPedidoComponent::obtieneInfoPedido::', error);
              this.historialPedido = [];
              this._loadSrv.toggle(false);
              this._msjSrv.agregaError('Error al buscar las opciones, intente más tarde.');
            });
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }
}
