import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from './../../../../../shared/utils/store-management';

@Injectable()
export class SelectorOptArtService {

  private _relPath = {
    getOpcionesRegColURL: '/services/opciones/getOpcionesRegCol.php',
    getDisponibleSubcuentaURL: '/services/opciones/getDisponibleSubcuenta.php',
    getOpcionesModeloSiguientesURL: '/services/opciones/getOpcionesModeloSiguientes.php',
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient,
    private _storeSrv: StoreManagement
  ) { }

  getOpcionesRegCol(articulo: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getOpcionesRegColURL}`;
    const param = { 'articulo': articulo };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
    );
  }

  getDisponibleSubcuenta(Articulo: string, Almacen: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getDisponibleSubcuentaURL}`;
    const param = { 'Articulo': Articulo, 'Almacen': Almacen };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
    );
  }

  getOpcionesModeloSiguientes(numero: number): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getOpcionesModeloSiguientesURL}`;
    const param = { 'numero': numero };
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
    );
  }
  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed:`, error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
