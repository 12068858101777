import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from './../../../../shared/utils/store-management';

@Injectable()
export class NotificacionesService {

  private _relPath = {
    getNotificacionesURL: '/services/notificaciones/getNotificaciones.php',
    getCountNotificacionesURL: '/services/notificaciones/getCountNotificaciones.php',
    managerNotificacionURL: '/services/notificaciones/managerNotificacion.php',
    getPedidoSelectedURL: '/services/notificaciones/getPedidoSelected.php'
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient,
    private _storeSrv: StoreManagement
  ) { }

  getNotificaciones(id_usuario: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getNotificacionesURL}`;
    const param = {'id_usuario' : id_usuario};
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('NotificacionesService', <any[]>null)),
    );
  }

  getCountNotificaciones(id_usuario: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getCountNotificacionesURL}`;
    const param = {'id_usuario' : id_usuario};
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('NotificacionesService', <any[]>null)),
    );
  }

  managerNotificacion(accion: any, id_notificacion: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.managerNotificacionURL}`;
    const param = {'accion' : accion, 'id_notificacion': id_notificacion};
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('NotificacionesService', <any>null)),
    );
  }

  getPedidoSelected(id_pedido: any, almacen: any, nivel: any, Agente: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getPedidoSelectedURL}`;
    const param = {'id_pedido' : id_pedido, 'almacen': almacen, 'nivel': nivel, 'Agente': Agente };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('NotificacionesService', <any>null)),
    );
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed:`, error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
