import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { PedidoService } from './../services/pedidos.service';
import { ClienteSelected } from './../modelos/ClienteSelected';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/finally';
import { isArray } from '../../../../../../../node_modules/rxjs/internal/util/isArray';

@Component({
    selector: 'karabe-cliente-selector',
    templateUrl: './selector-cliente.component.html',
    styleUrls: ['./selector-cliente.component.css']
})
export class ClienteSelectorComponent implements OnInit {

    autocompleteCtrl: FormControl;
    filteredOptions: Observable<ClienteSelected[]>;
    cliente: ClienteSelected;
    hasError = false;
    showSearchSpinner = 'hidden';

    @Output()
    selccionado = new EventEmitter<ClienteSelected>();
    
    @Input()
    minInput = 2;
    
    @Input()
    agente = '';

    @Input()
    cteIni = null;
    
    constructor(private _pedidoSrv: PedidoService) { }

    ngOnInit() {
        //console.log('minInput', this.minInput);
        this.autocompleteCtrl = new FormControl([''], Validators.minLength(this.minInput));
        this.filteredOptions = this.autocompleteCtrl.valueChanges
            .startWith(null)
            .debounceTime(500)
            .distinctUntilChanged()
            .map(cte => {
                if (cte && typeof cte === 'object') {
                    // this.setUsuario(<Usuario>usuario);
                    //console.log('object', usuario);
                    this.selccionado.emit(<ClienteSelected>cte);
                    this.setCliente(<ClienteSelected>cte);
                    return cte.Nombre;
                } else {
                    return cte;
                }
            }).flatMap(nombre => {
                return this.filter(nombre);
            });
        this.cteInicial();
    }

    filter(cteFind: string): Promise<ClienteSelected[]> | Observable<ClienteSelected[]> {
        if (cteFind && cteFind.length) {
            if (cteFind.length < this.minInput) {
                return Observable.of(<ClienteSelected[]>[]);
            } else if (this.cliente && cteFind === this.cliente.Nombre) {
                return Observable.of(<ClienteSelected[]>[]);
            } else {
                this.showSearchSpinner = 'visible';
                return this._pedidoSrv.buscaCliente(this.agente, cteFind).finally(() => {
                    this.showSearchSpinner = 'hidden';
                });
            }
        } else {
            this.showSearchSpinner = 'visible';
            return this._pedidoSrv.buscaCliente(this.agente, '').finally(() => {
                this.showSearchSpinner = 'hidden';
            });
        }
    }

    displayFn(cliente: ClienteSelected): string {
        if(cliente && cliente !==null && !isArray(cliente) && cliente.Cliente ) {
            return '(' + cliente.Cliente + ') ' + cliente.Nombre;
          } else {
            return '';
          }
        //return cliente ? cliente.Cliente : JSON.stringify(cliente);
    }

    setCliente(cliente: ClienteSelected): void {
        this.cliente = cliente;
        // this.select.emit(usuario);
    }

    @Input() set disabled(disabled: boolean) {
        // console.log('disable', disabled);
        if (this.autocompleteCtrl) {
            if (disabled) {
                this.autocompleteCtrl.disable({ emitEvent: false });
            } else {
                this.autocompleteCtrl.enable();
            }
        }
    }

    get disabled() {
        return this.autocompleteCtrl.disabled;
    }

    cancel() {
        if (!this.disabled) {
            this.autocompleteCtrl.setValue({});
        }
    }

    private cteInicial(): void {
        if (this.cteIni !== null) {
            this._pedidoSrv.buscaCliente(this.agente, this.cteIni).subscribe(
                resCte => {
                    this.autocompleteCtrl.setValue(<ClienteSelected>resCte[0]);
                    this.selccionado.emit(<ClienteSelected>resCte[0]);
                    this.setCliente(<ClienteSelected>resCte[0]);
                },
                errCte => {
                    console.log('Error al buscar el cliente:::', errCte);
                }
            );
        }
    }
}