import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from './../../shared/utils/store-management';

@Injectable()
export class RegistroUserService {

  private _relPath = {
    BuscaUsuarioIntelisisURL: '/services/registro/BuscaUsuarioIntelisis.php',
    RegistroNuevoUsuarioURL: '/services/registro/RegistroNuevoUsuario.php'
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient,
    private _storeSrv: StoreManagement
  ) { }

  BuscaUsuarioIntelisis(usuario: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };
    const url = `${this.api}${this._relPath.BuscaUsuarioIntelisisURL}`;
    const param = { 'usuario' : usuario };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('BuscaUsuarioIntelisis', <any>null)),
    );
  }

  RegistroNuevoUsuario(nombre: string, email: string, usuario: string,
                       password: string, agente: string, nivel: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };
    const url = `${this.api}${this._relPath.RegistroNuevoUsuarioURL}`;
    const param = { 'registro': { 'nombre' : nombre,
                                  'email' : email,
                                  'usuario' : usuario,
                                  'password' : password,
                                  'agente' : agente,
                                  'nivel' : nivel
                                }};
    return this.http.post<any>(url, param, httpOptions).pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('RegistroNuevoUsuario', <any>null)),
    );
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed:`, error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
