import { Component, OnInit, Input } from '@angular/core';
import { SessionService } from 'app/shared';

@Component({
  selector: 'krb-app-container',
  templateUrl: './app-container.component.html',
  styles: []
})
export class AppContainerComponent implements OnInit {

  constructor(private _ssnSrv: SessionService) { }

  @Input()
  nombreAplicacion: string;
  @Input()
  cveAplicacion: string;

  isAdmin = false;
  rolesApp: string[];

  ngOnInit() {
    console.log('AppContainerComponent::init');
    this.isAdmin = this._ssnSrv.isAdmin;
    // this.rolesApp = this._ssnSrv.principal.rolesApp;
  }

  hasAccess(app: string) {
    return this.rolesApp.findIndex(ra => ra.indexOf(app) >= 0) >= 0;
  }

  get isAppAdmin() {
    // console.log('rolesApp', this.rolesApp);
    // return this.rolesApp.findIndex(ra => (ra.toUpperCase().indexOf('ADMINISTRADOR') > 0)) >= 0;
    return 0;
  }

}
