import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { ArticulosService } from './articulos.service';
import { ArticuloModel } from './ArticuloModel';

@Component({
    selector: 'krb-det-articulos',
    templateUrl: './detalle_art.component.html',
    styleUrls: ['./detalle_art.component.css']
})
export class ArticulosDetalleComponent implements OnInit {

    artSelected: ArticuloModel;
    opciones: any[];
    agrupadores: any[];
    almacen: string;

    constructor(@Inject(MAT_DIALOG_DATA) public articuloSelected: any,
                public dialogRef: MatDialogRef<ArticulosDetalleComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _artSrv: ArticulosService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.artSelected = null;
        this.opciones = [];
        this.agrupadores = [];
    }

    private InitComponente(): void {
        this.almacen = this.ssnSrv.principal.almacen;
        this.artSelected = <ArticuloModel> this.articuloSelected.articuloSelected;
        this.obtieneOpciones();
    }

    private obtieneOpciones(): void {
        this._loadSrv.toggle(true);
        this._artSrv.getOpcionesArt(this.artSelected.Articulo).toPromise().then(
            res => {
              this.opciones = res;
              this._artSrv.getAgrupadoresArticulo(this.artSelected.Articulo).subscribe(
                  resAgpo => {
                      this.agrupadores = resAgpo;
                  },
                  err => {
                    console.log('Error::ArticulosComponent::InitComponente::', err);
                    this.opciones = [];
                    this._loadSrv.toggle(false);
                    this._msjSrv.agregaError('Error al buscar las opciones, intente más tarde.');
                  });

              this._loadSrv.toggle(false);
            }).catch(error => {
              console.log('Error::ArticulosComponent::InitComponente::', error);
              this.opciones = [];
              this._loadSrv.toggle(false);
              this._msjSrv.agregaError('Error al buscar las opciones, intente más tarde.');
            });
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }


    dameDescUPC(tipoUpc: string): string {
        let valor = '';

        if (tipoUpc === 'NA') {
            valor = 'No Aplica';
        }

        if (tipoUpc === 'A') {
            valor = 'Articulo';
        }

        if (tipoUpc === 'T') {
            valor = 'Talla';
        }

        if (tipoUpc === 'C') {
            valor = 'Color'
        }
        
        if (tipoUpc === 'D') {
            valor = 'Dibujo';
        }
        
        if (tipoUpc === 'CT') {
            valor ='Color/Talla';
        }

        if (tipoUpc === 'DT') {
            valor ='Dibujo/Talla';
        }

        return valor;
    }
}
