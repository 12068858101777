import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from './../../../../shared/utils/store-management';
import { UsuarioModel } from './UsuarioModel';

@Injectable()
export class UsuariosService {

  private _relPath = {
    getUsuariosURL: '/services/usuarios/getUsuarios.php',
    cambiaSituacionURL: '/services/usuarios/cambiaSituacion.php',
    getNivelesURL: '/services/usuarios/getNiveles.php',
    busquedaUsuarioURL: '/services/usuarios/busquedaUsuario.php',
    RegistroNuevoUsuarioURL: '/services/registro/RegistroNuevoUsuario.php',
    EditarUsuarioURL: '/services/registro/EditarUsuario.php'
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient,
    private _storeSrv: StoreManagement
  ) { }


  getUsuarios(): Observable<UsuarioModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getUsuariosURL}`;
    const param = null;
    
    return this.http.post<UsuarioModel[]>(url, param, httpOptions)
      .pipe(
        map(response => <UsuarioModel[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <UsuarioModel[]>null)),
      );
  }

  cambiaSituacion(id_usuario: any, estatus: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.cambiaSituacionURL}`;
    const param = { 'id_usuario': id_usuario, 'estatus' : estatus };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <any>null)),
    );
  }

  getNiveles(almacen: string): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getNivelesURL}`;
    const param = { almacen: almacen };
    
    return this.http.post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <any[]>null)),
      );
  }

  
  busquedaUsuario(agente: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.busquedaUsuarioURL}`;
    const param = { 'agente': agente };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <any>null)),
    );
  }

  RegistroNuevoUsuario(nombre: string, email: string, usuario: string,
                       password: string, agente: string, nivel: number,
                       almacen: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.RegistroNuevoUsuarioURL}`;
    const param = { 'registro': { 'nombre' : nombre,
                                  'email' : email,
                                  'usuario' : usuario,
                                  'password' : password,
                                  'agente' : agente,
                                  'nivel' : nivel,
                                  'almacen' : almacen
                                }
                  };
    return this.http.post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('RegistroNuevoUsuario', <any>null)),
      );
  }

  EditarUsuario(nombre: string, email: string, password: string, id_usuario: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.EditarUsuarioURL}`;
    const param = { 'registro': { 
                                  'nombre' : nombre,
                                  'email' : email,
                                  'password' : password,
                                  'id_usuario' : id_usuario
                                }
                  };
    return this.http.post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('RegistroNuevoUsuario', <any>null)),
      );
  }


    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(`${operation} failed:`, error); // log to console instead

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


}
