import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { UsuariosService } from './../usuarios.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'krb-find-user',
    templateUrl: './find-user.component.html'
})
export class UsuariosBusquedaComponent implements OnInit {

    userForm: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<UsuariosBusquedaComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _usrSrv: UsuariosService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        // this.userForm.reset();
    }

    private InitComponente(): void {
        this.creaForm();
    }

    private creaForm(): void {
        this.userForm = this._builder.group({
            agente: [null, Validators.required],
        });
    }

    buscarUsuario(): void {
        this._loadSrv.toggle(true);
        this._usrSrv.busquedaUsuario(this.userForm.value.agente).subscribe(
            res => {
                // console.log(JSON.stringify(res));
                if(res.code === 0) {
                    this._msjSrv.agregaInfo(res.message);
                    this.dialogRef.close(res.datos);
                } else {
                  this._msjSrv.agregaError(res.message);
                }
                this._loadSrv.toggle(false);
            },
            err => {
                console.log('Error::UsuariosBusquedaComponent::buscarUsuario', err);
                this._msjSrv.agregaError('Error al buscar el usuario en Intelisis, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        );
    }

    cerrarDialogo(): void {
        this.dialogRef.close(null);
    }
}
