import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MatPaginator, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { AgrupadoresService } from './../agrupadores.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'krb-merge-det-agrupador',
    templateUrl: './new-det-agrupador.component.html',
    styleUrls: []
})
export class MergeAgpDetComponent implements OnInit {

    agrupadorSelected: any;
    agrupadorDetSelected: any;
    accion: string;
    formDet: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<MergeAgpDetComponent>,
                public _builder: FormBuilder,
                private _loadSrv: CargandoService,
                private _dlgSrv: ConfirmacionService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _agpSrv: AgrupadoresService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.agrupadorSelected = null;
        this.agrupadorDetSelected = null;
        this.accion = null;
    }

    private InitComponente(): void {
        this.agrupadorSelected = <any> this.params.agrupadorSelected;
        this.agrupadorDetSelected = <any> this.params.agrupadorDetSelected;
        this.accion = <string> this.params.accion;

        this.formDet = this._builder.group({
            NombreDet: [null, Validators.required]
        });

        if (this.accion === 'E') {
            this.formDet.controls.NombreDet.patchValue(this.agrupadorDetSelected.NombreDet);
        } else {
            this.formDet.reset();
        }
    }

    margeAgp(): void {
        this._dlgSrv.confirmarSimple('Se guardaran los cambios del agrupador, ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this._loadSrv.toggle(true);
                    this._agpSrv.MergeAgrupadores('D',
                                                  this.accion,
                                                  this.agrupadorSelected.NombreEnc,
                                                  this.formDet.value.NombreDet,
                                                  1).subscribe(
                        resWS => {
                            if (resWS.code === 0) {
                                this._msjSrv.agregaOK('Se completo con exitó la accion..');
                                this.cerrarDialogo();
                            } else {
                                this._msjSrv.agregaError(resWS.message);    
                            }
                            this._loadSrv.toggle(false);
                        },
                        err => {
                            this._msjSrv.agregaError('Error al completar la acción, intente más tarde.');
                            this._loadSrv.toggle(false);
                        }
                    );
                }
            }
        );
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }
}
