import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PedidoService } from './../services/pedidos.service';
import { PedidosModel } from '../modelos/PedidosModel';
import { FormGroup, FormBuilder, Validators } from '../../../../../../../node_modules/@angular/forms';
import { Router } from '../../../../../../../node_modules/@angular/router';

@Component({
    selector: 'krb-comentarios-pedido',
    templateUrl: './comentarios-pedidos.component.html'
})
export class ComentarioPedidoComponent implements OnInit {

    titulo: string;
    requerido: boolean = false;
    formComentario: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public param: any,
                public dialogRef: MatDialogRef<ComentarioPedidoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _router: Router,
                private _dlgSrv: ConfirmacionService,
                private _pedidoSrv: PedidoService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.inicializaForm();
        this.formComentario.reset();
        this.titulo = '';
    }

    private InitComponente(): void {
        this.titulo = <string> this.param.titulo;
        this.requerido = <boolean> this.param.requerido;
        // valida si es requerido
        this.formComentario.controls['comentario'].clearValidators();
        if (this.requerido) {
            this.formComentario.controls['comentario'].setValidators([Validators.required]);
        }
        this.formComentario.controls['comentario'].updateValueAndValidity();
    }

    private inicializaForm(): void {
        this.formComentario = this._builder.group({
            comentario: [null, Validators.required]
          });
    }

    aceptar(): void {
        this.dialogRef.close({'Comentario' : this.formComentario.value.comentario});

    }

    cancelar(): void {
        this.dialogRef.close({'Comentario' : null});
    }
}
