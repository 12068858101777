import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { PedidoService } from './../services/pedidos.service';
import { SelectorOptArtService } from '../selector-opt-art';
import { isArray } from 'util';

@Component({
    selector: 'krb-update-opciones',
    templateUrl: './update-opciones.component.html'
})
export class UpdateOpcionesPedidoComponent implements OnInit {

    id_pedido: number;
    id_pedidoD: number;
    artActual: any;
    articulos: any[];
    estatus: string;
    opcionesForm: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public param: any,
                public dialogRef: MatDialogRef<UpdateOpcionesPedidoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _router: Router,
                private _dlgSrv: ConfirmacionService,
                private _pedidoSrv: PedidoService,
                private _selSrv: SelectorOptArtService) {
    }

    ngOnInit(): void {
        this.InitComponente();
        this.inicializaForm();
    }

    private InitComponente(): void {
        // console.log('Parametros --> ', JSON.stringify(this.param));
        this.id_pedido = <number> this.param.id_pedido;
        this.id_pedidoD = <number> this.param.id_pedidoD;
        this.artActual = <any> this.param.artActual;
        this.articulos = <any[]> this.param.articulos;
        this.estatus = <string> this.param.estatus;
        // this.form.controls.cantidad.patchValue(this.cantidadActual);
        this.opcionesForm = this._builder.group({ opciones: this._builder.array([]) });
    }

    private inicializaForm(): void {
        this._loadSrv.toggle(true);
        // console.log(JSON.stringify(this.artActual));
        this._pedidoSrv.dameOpcionesArt(this.artActual.Articulo).subscribe(
            resSrv => {
                // console.log(JSON.stringify(resSrv));
                this.armaFormLista(resSrv);
                this._loadSrv.toggle(false);
            },
            errSrv => {
                this._loadSrv.toggle(false);
                console.error('Error en el servicio::: ', errSrv);
                this._msjSrv.agregaError('Error al obtener las opciones del articulo, intente más tarde.');
            }
        );
        /*
        this.form = this._builder.group({
            cantidad: [null, Validators.required]
          });
          */
    }

    private armaFormLista(opciones: any): void {
        const control = <FormArray>this.opcionesForm.controls.opciones;
        // Elimina las opciones existentes
        while (control.length !== 0) {
            control.removeAt(0)
        }
        // LLena las nuevas opciones
        opciones.forEach(e => {
            control.push(
                this._builder.group({
                    opcion: [e.Opcion, Validators.required],
                    descripcion: [e.Descripcion, Validators.required],
                    requerido: [e.Requerido, Validators.required],
                    valor: [null, (Number(e.Requerido) === 1 ? Validators.required: null)],
                    disponibles: [e.opciones_array],
                }));
        });
    }

    public okOpciones(): void {

        let opciones: any[] = [];
        const control = <FormArray>this.opcionesForm.controls.opciones;

        control.controls.forEach(e => {
            // console.log(JSON.stringify(e.value));
            opciones.push(
                {
                    "Opcion": e.value.opcion,
                    "requerido": e.value.requerido,
                    "Numero": (e.value.valor === null ? null : e.value.valor.Numero),
                    "Descripcion": e.value.descripcion,
                    "Nombre": (e.value.valor === null ? null : e.value.valor.Nombre)
                }
            );
        });
        if (this.validaExistente(opciones)) {
            this._loadSrv.toggle(true);
            this._pedidoSrv.UpdateVariantes(this.id_pedido,
                                           this.id_pedidoD,
                                           this.ssnSrv.principal.id_usuario,
                                           this.artActual.opciones,
                                           opciones,
                                           this.estatus).subscribe(
                resReg => {
                    if(resReg.code === 0) {
                        this.dialogRef.close({'Respuesta': 0});
                        this._loadSrv.toggle(false);
                    } else {
                        this._msjSrv.agregaError(resReg.message);
                        this._loadSrv.toggle(false);
                    }
                },
                errReg => {
                    console.log('Error::UpdateOpcionesPedidoComponent::aceptar::', errReg);
                    this._msjSrv.agregaError('Error al actualizar la variante del pedido, intente más tarde.');
                    this._loadSrv.toggle(false);
                }
            );

        }
    }


    private validaExistente(opciones: any[]): boolean {
        // Valida que se eligar un valor
        let vacio = false;
        opciones.forEach(opt => {
            if (opt.Numero === null) {
                vacio = true;
            }
        });
        // console.log('Elegidas:: ', JSON.stringify(opciones));
        // console.log('Actuales:: ', JSON.stringify(this.artActual.opciones));
        if (!vacio) {
            // Valida que no sea igual al que requiere modificar
            let iguales = 0;
            opciones.forEach(opt => {
                this.artActual.opciones.forEach(act => {
                    if (opt.Opcion === act.Opcion && opt.Numero === act.Numero) {
                        iguales = iguales + 1;
                    }
                });
            });

            if (iguales === this.artActual.opciones.length) {
                this._msjSrv.agregaError('Las variantes son iguales a la que desea modificar.');
                return false;  
            } else {
                // Valida que no sea uno ya existente
                let repetido = 0;
                this.articulos.forEach(art => {
                    if (art.id_pedidoD !== this.id_pedidoD) {
                        repetido = 0;
                        art.opciones.forEach(opt => {
                            // console.log('Opcion::: ', JSON.stringify(opt));
                            opciones.forEach(elegidad => {
                                // console.log('Actual::: ', JSON.stringify(elegidad));
                                if (opt.Opcion === elegidad.Opcion && opt.Numero === elegidad.Numero) {
                                    repetido = repetido + 1;
                                }
                            });
                        });
                        // console.log('Igual: ', repetido);
                        // console.log('Igual: ', this.artActual.opciones.length);
                        if (repetido === this.artActual.opciones.length) {
                            this._msjSrv.agregaError('Ya existe una variante similar.');
                            return false;  
                        }
                    }
                });
                return true;
            }
        } else {
            this._msjSrv.agregaError('Debe completar las variantes.');
            return false;
        }
    }

    /*
        this._loadSrv.toggle(true);
        this._pedidoSrv.UpdateCantidad(this.id_pedido,
                                       this.id_pedidoD,
                                       this.ssnSrv.principal.id_usuario,
                                       this.cantidadActual,
                                       this.form.value.cantidad,
                                       this.estatus).subscribe(
            resReg => {
                if(resReg.code === 0) {
                    this.dialogRef.close({'Respuesta': 0});
                    this._loadSrv.toggle(false);
                } else {
                    this._msjSrv.agregaError(resReg.message);
                    this._loadSrv.toggle(false);
                }
            },
            errReg => {
                console.log('Error::UpdateUPCPedidoComponent::aceptar::', errReg);
                this._msjSrv.agregaError('Error al actualizar la cantidad del pedido, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        );
    }*/

    cancelar(): void {
        this.dialogRef.close({'Respuesta': 1});
    }

    displayFn(opciones: any): string {
        if(opciones && opciones !==null && !isArray(opciones)) {
            return opciones.Nombre;
          } else {
            return '';
          }
        //return cliente ? cliente.Cliente : JSON.stringify(cliente);
    }

}
