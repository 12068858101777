import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ErrorPagesModule } from './error-pages';
import { KrbSharedRootModule, MaterialKrbModule, PrimeNgModule } from './shared'
import { LoginModule } from './login';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CookieModule } from 'ngx-cookie';
import { NgIdleModule } from '@ng-idle/core';
import { PortalModule } from './portal';
import { environment } from 'environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';

import localEsMx from '@angular/common/locales/es-MX';
registerLocaleData(localEsMx);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    DeviceDetectorModule.forRoot(),
    MaterialKrbModule,
    PrimeNgModule,
    CookieModule.forRoot(),
    NgIdleModule.forRoot(),
    KrbSharedRootModule,
    AppRoutingModule,
    LoginModule,
    PortalModule,
    ErrorPagesModule
  ],
  providers: [
    { provide: 'AUTH_API_ENDPOINT', useValue: environment.authApiURL },
    { provide: 'IDLE_TIMEOUT', useValue: environment.timeOut },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
