import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PedidoService } from './../services/pedidos.service';

@Component({
    selector: 'krb-update-cantidad',
    templateUrl: './update-cantidad.component.html'
})
export class UpdateCantidadPedidoComponent implements OnInit {

    id_pedido: number;
    id_pedidoD: number;
    cantidadActual: string;
    form: FormGroup;
    estatus: string;

    constructor(@Inject(MAT_DIALOG_DATA) public param: any,
                public dialogRef: MatDialogRef<UpdateCantidadPedidoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _router: Router,
                private _dlgSrv: ConfirmacionService,
                private _pedidoSrv: PedidoService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.inicializaForm();
        this.form.reset();
    }

    private InitComponente(): void {
        console.log('Parametros --> ', JSON.stringify(this.param));
        this.id_pedido = <number> this.param.id_pedido;
        this.id_pedidoD = <number> this.param.id_pedidoD;
        this.cantidadActual = <string> this.param.cantidadActual;
        this.estatus = <string> this.param.estatus;
        this.form.controls.cantidad.patchValue(this.cantidadActual);
    }

    private inicializaForm(): void {
        this.form = this._builder.group({
            cantidad: [null, Validators.required]
          });
    }

    aceptar(): void {
        this._loadSrv.toggle(true);
        this._pedidoSrv.UpdateCantidad(this.id_pedido,
                                       this.id_pedidoD,
                                       this.ssnSrv.principal.id_usuario,
                                       this.cantidadActual,
                                       this.form.value.cantidad,
                                       this.estatus).subscribe(
            resReg => {
                if(resReg.code === 0) {
                    this.dialogRef.close({'Respuesta': 0});
                    this._loadSrv.toggle(false);
                } else {
                    this._msjSrv.agregaError(resReg.message);
                    this._loadSrv.toggle(false);
                }
            },
            errReg => {
                console.log('Error::UpdateUPCPedidoComponent::aceptar::', errReg);
                this._msjSrv.agregaError('Error al actualizar la cantidad del pedido, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        );
    }

    cancelar(): void {
        this.dialogRef.close({'Respuesta': 1});
    }
}
