import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KrbSharedModule, MaterialKrbModule, PrimeNgModule } from '../../shared';
import { AppContainerComponent } from './components';

@NgModule({
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, RouterModule,
    KrbSharedModule, MaterialKrbModule, PrimeNgModule
  ],
  declarations: [AppContainerComponent],
  exports: [AppContainerComponent],
  entryComponents: []
})
export class PortalSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PortalSharedModule,
      providers: [ ]
    };
  }

}
