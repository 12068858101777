import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { UsuarioModel } from './UsuarioModel';
import { UsuariosService } from './usuarios.service';
import { UsuariosDetalleComponent } from './detalle-user/det-user.component';
import { UsuariosNuevoComponent } from './new-user/new-user.component';

@Component({
  selector: 'krb-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  displayedColumns = ['nombre', 'email', 'usuario', 'agente', 'desc_nivel', 'almacen', 'estatus'];
  usuarios: UsuarioModel[];
  dataSource = new MatTableDataSource<UsuarioModel>();
  usuarioSelected: UsuarioModel;
  
  solActiva: boolean = false;
  esDesbloquear: boolean = false;
  esInactivar: boolean = false;
  esActivar: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _title: Title,
              private _router: Router,
              private _loadSrv: CargandoService,
              private _msjSrv: MensajesService,
              private ssnSrv: SessionService,
              private usrSrv: UsuariosService,
              private _dlgSrv: ConfirmacionService,
              private config: PortalConfigService,
              private dialog: MatDialog) {
    this.validaUsuario();
    config.app = 'Catálogo de Usuarios';
  }

  ngOnInit(): void {
    this.limpiaVariables();
    this.InitComponente();
  }

  private limpiaVariables(): void {
    this.usuarios = [];
    this.usuarioSelected = null;
  }

  private InitComponente(): void {
    this._loadSrv.toggle(true);
      this.usrSrv.getUsuarios().toPromise().then(
        res => {
          this.usuarios = res;
          this.dataSource = new MatTableDataSource<UsuarioModel>(this.usuarios);
          this.dataSource.paginator = this.paginator;
          this.usuarioSelected = null;
          this.solActiva = false;
          this._loadSrv.toggle(false);
        }).catch(error => {
          console.log('Error::UsuariosComponent::InitComponente::', error);
          this._loadSrv.toggle(false);
          this._msjSrv.agregaError('Error al buscar los usuarios, intente más tarde.');
        });
  }

  seleccionaElemento(elementSelected: any): void {
    this.usuarioSelected = elementSelected;
    this.manegadorBotonera();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  manegadorBotonera(): void {
    this.solActiva = false;
    this.esDesbloquear = false;
    this.esInactivar = false;
    this.esActivar = false;

    if (this.usuarioSelected !== null) {
      
      this.solActiva = true;
      
      if (this.usuarioSelected.estatus === 'AC') {
        // Se puede inactivar
        this.esInactivar = true;
        this.esActivar = false;
        // Lo puede bloquear
        this.esDesbloquear = false;
      }
      // Lo puede activar
      if (this.usuarioSelected.estatus === 'IN') {
        this.esInactivar = false;
        this.esActivar = true;
      }
      // Lo puede desbloquear
      if (this.usuarioSelected.estatus === 'BA') {
        this.esDesbloquear = true;
      }

    }
  }

  
  verDetalle() {
    this.dialog.open(UsuariosDetalleComponent, {
      width: '60%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: {
        usuarioSelected: this.usuarioSelected
      }
    });
  }

  cambiaEstatus(estatus: any) {
    if (Number(this.ssnSrv.principal.id_usuario) !== Number(this.usuarioSelected.id_usuario)) {
      const msjEstatus: string = (estatus === 'AC' ? 'Activará' : estatus === 'IN' ? 'Inactivará' : 'Bloqueará');
      const mensaje = 'El usuario se ' + msjEstatus + ', ¿Desea continuar?';
      this._dlgSrv.confirmarSimple(mensaje).subscribe(
        res => {
          if (res) {
              this._loadSrv.toggle(true);
              this.usrSrv.cambiaSituacion(this.usuarioSelected.id_usuario, estatus).subscribe(
                resSit => {
                  if (resSit.code === 0) {
                    this._msjSrv.agregaOK(resSit.message);
                  } else {
                    this._msjSrv.agregaError(resSit.message);
                  }
                  this.ngOnInit();
                  this.seleccionaElemento(null);
                  this._loadSrv.toggle(false);
                },
                errSit => {
                  console.log('Error::UsuariosComponent::cambiaEstatus', errSit);
                  this._msjSrv.agregaError('Error al cambiar la situación del usuario, intente más tarde.');
                  this._loadSrv.toggle(false);
                }
              );
          }
        }
      );
    } else {
      this._msjSrv.agregaWarn('No puedes realizar acciones sobre si mismo.');
    }
  }

  mergeUsuario(accion: string) {
    const dialogRef = this.dialog.open(UsuariosNuevoComponent, {
      width: '80%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: {
        usuarioSelected: this.usuarioSelected,
        accion: accion
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
      this.seleccionaElemento(null);
    });
  }

  private validaUsuario(): void {
    if (Number(this.ssnSrv.principal.nivel) !== 1 && Number(this.ssnSrv.principal.nivel) !== 2) {
      this._router.navigate(['/']);
      this._msjSrv.agregaWarn('No tiene permisos para el recurso!!!');
    }
  }

}
