import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PedidoService } from './../services/pedidos.service';
import { PedidosModel } from '../modelos/PedidosModel';
import { FormGroup, FormBuilder, Validators } from '../../../../../../../node_modules/@angular/forms';
import { Router } from '../../../../../../../node_modules/@angular/router';

@Component({
    selector: 'krb-selector-tipo-pedido',
    templateUrl: './selector-tipo-pedido.component.html',
    styleUrls: []
})
export class SelectorTipoPedidoComponent implements OnInit {

    tipos_pedidos: any[];
    formTipoPedido: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<SelectorTipoPedidoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _router: Router,
                private _dlgSrv: ConfirmacionService,
                private _pedidoSrv: PedidoService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.inicializaForm();
        this.formTipoPedido.reset();
    }

    private InitComponente(): void {
        this._loadSrv.toggle(true);
        this.tipos_pedidos = [];
        this._pedidoSrv.dameTiposPedidos().subscribe(
            res => {
                res.forEach(e => {
                    if (e.tipo_pedido !== 'TE') {
                        this.tipos_pedidos.push(e); 
                    }
                });
                this.formTipoPedido.controls.tipo_pedido.patchValue('PE');
                this._loadSrv.toggle(false);
            },
            err => {
                console.error('ERROR AL OBTENER LOS TIPOS DE PEDIDOS', err);
                this._loadSrv.toggle(false);
                this.cancelar();
            }
        );
    }

    private inicializaForm(): void {
        this.formTipoPedido = this._builder.group({
            tipo_pedido: [null, Validators.required]
          });
    }

    seleccionar(): void {
        this.dialogRef.close(this.formTipoPedido.value.tipo_pedido);
    }

    cancelar(): void {
        this.cerrarDialogo();
        this.navegar('/pedidos');
    }

    navegar(ruta: string) {
        this._router.navigate([ruta]);
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }

}
