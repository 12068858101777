import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CargandoService, SessionService, MensajesService, ConfirmacionService, UtilsApps } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { FormBuilder, FormControl, Validators, FormGroup } from '../../../../../../node_modules/@angular/forms';
import { PreferenciasService } from './preferencias.service';

@Component({
  selector: 'krb-preferencias',
  templateUrl: './preferencias.component.html',
  styles: [`
  .form-control {
    padding: 0.5em 0px 0.5em 0em;
  }
  `]
})
export class PreferenciasComponent implements OnInit  {

  usuario: any;
  changePwdForm: FormGroup;
  fielConfig = { actual: false, nueva: false, confirma: false };
  expandedPwd = false;
  expandedEmail = false;
  expandedBotones = false;
  expandedNotificaciones = false;
  eMailCtrl: FormControl;
  muestraBotonera: boolean = false;
  notificaciones: any;

  constructor(private builder: FormBuilder,
              private _title: Title,
              private _router: Router,
              private _loadSrv: CargandoService,
              private _dlgSrv: ConfirmacionService,
              private msjSrv: MensajesService,
              private ssnSrv: SessionService,
              private config: PortalConfigService,
              private usrSrv: PreferenciasService) {
    config.app = 'Preferencias';

    this.notificaciones = {
        id_preferencia: null,
        id_usuario: null,
        email_flujo: false,
        email_rechazo: false,
        email_aprobacion: false,
        app_flujo: false,
        app_rechazo: false,
        app_aprobacion: false
    }

    this.changePwdForm = builder.group({
      actual: [null, Validators.required],
      nueva: [null, [Validators.required, Validators.minLength(5)]],
      confirma: [null, [Validators.required, Validators.minLength(5)]]
    }, { validator: this.pwdMatchValidator });
    this.eMailCtrl = builder.control(null, [Validators.required, Validators.email]);

  }

  ngOnInit() {
    this._loadSrv.toggle(true);
    if(Number(this.ssnSrv.principal.nivel) == 1 || Number(this.ssnSrv.principal.nivel) == 2) {
      this.muestraBotonera = true;
    }
    this.usrSrv.getUsuarioInfo(this.ssnSrv.principal.id_usuario).subscribe(
        usuario => {
          this.usuario = usuario;
          this.eMailCtrl.setValue(this.usuario.email);
          
          this.usrSrv.getPrefNotificaciones(this.ssnSrv.principal.id_usuario).subscribe(
            noti => {
              this.notificaciones = {
                id_preferencia: noti.id_preferencia,
                id_usuario: noti.id_usuario,
                email_flujo: noti.email_flujo === 'V' ? true : false,
                email_rechazo: noti.email_rechazo === 'V' ? true : false,
                email_aprobacion: noti.email_aprobacion === 'V' ? true : false,
                app_flujo: noti.app_flujo === 'V' ? true : false,
                app_rechazo: noti.app_rechazo === 'V' ? true : false,
                app_aprobacion: noti.app_aprobacion === 'V' ? true : false
              }
              this._loadSrv.toggle(false);
            },
            errNoti => {
              this.msjSrv.agregaError(errNoti);
              this._loadSrv.toggle(false);
            }
          )

        },
        error => {
          this.msjSrv.agregaError(error);
          this._loadSrv.toggle(false);
        }
      );
}

  changePassword(): void {
    if (this.changePwdForm.valid) {
      this._loadSrv.toggle(true);
      const Id: number = this.ssnSrv.principal.id_usuario;
      const apsw = UtilsApps.sha1(String(this.changePwdForm.get('actual').value));
      const npsw = UtilsApps.sha1(String(this.changePwdForm.get('nueva').value));
      this.usrSrv.changePwd(Id, apsw, npsw).subscribe(
        res => {
          if (res.code === 0) {
            this.expandedPwd = false;
            this.changePwdForm.reset();
            this.msjSrv.agregaInfo('Constraseña actualizada correctamente');
            this._loadSrv.toggle(false);
          } else {
            this.msjSrv.agregaError(res.message);
            this._loadSrv.toggle(false);
          }
        },
        error => {
          this.msjSrv.agregaError(error);
          this._loadSrv.toggle(false);
        });
    }
  }

  pwdMatchValidator(frm: FormGroup) {
    return (frm.get('nueva').dirty && frm.get('confirma').dirty
      && frm.get('nueva').value !== frm.get('confirma').value)
      ? { 'mismatch': true } : null;
  }

  actualizaEmail(): void {
    if (this.eMailCtrl.valid) {
      this._loadSrv.toggle(true);
      this.usrSrv.actualizaEmail(this.ssnSrv.principal.id_usuario, this.eMailCtrl.value).subscribe(
          usuario => {
            if (usuario.code === 0) {
              this.msjSrv.agregaInfo('Dirección de E-Mail actualizada correctamente');
              this._loadSrv.toggle(false);
              this.ngOnInit();
            } else {
              this.msjSrv.agregaError(usuario.message);
              this._loadSrv.toggle(false);
            }
          },
          error => {
            this.msjSrv.agregaError(error);
            this._loadSrv.toggle(false);
          });
    }
  }

  actualizaNotificaciones(): void {
    const mensaje = 'Sus preferencias de notificaciones cambiaran. ¿Desea continuar?';
    this._dlgSrv.confirmarSimple(mensaje).subscribe(
      res => {
        if (res) {
          this._loadSrv.toggle(true);
          this.usrSrv.cambiaPrefNotificaciones(this.getNotificacionesToSave()).subscribe(
            notiRes => {
              if (notiRes.code === 0) {
                this.msjSrv.agregaInfo(notiRes.message);
                this._loadSrv.toggle(false);
                this.ngOnInit();
              } else {
                this.msjSrv.agregaError(notiRes.message);
                this._loadSrv.toggle(false);
              }
            },
            error => {
              this.msjSrv.agregaError(error);
              this._loadSrv.toggle(false);
          });
        }
      });
  }

  private getNotificacionesToSave(): any {
      return {
                id_preferencia:   this.notificaciones.id_preferencia,
                id_usuario:       this.notificaciones.id_usuario,
                email_flujo:      this.notificaciones.email_flujo === true ? 'V' : 'F',
                email_rechazo:    this.notificaciones.email_rechazo === true ? 'V' : 'F',
                email_aprobacion: this.notificaciones.email_aprobacion === true ? 'V' : 'F',
                app_flujo:        this.notificaciones.app_flujo === true ? 'V' : 'F',
                app_rechazo:      this.notificaciones.app_rechazo === true ? 'V' : 'F',
                app_aprobacion:   this.notificaciones.app_aprobacion === true ? 'V' : 'F'
            };
  }

  ejecutarSincronizacion(): void {
    const mensaje = 'Se ejecutara la sincronización, puede durar varios minutos. ¿Desea continuar?';
    this._dlgSrv.confirmarSimple(mensaje).subscribe(
      res => {
        if (res) {
          this._loadSrv.toggle(true);
          this.usrSrv.sincronizarIntelisis().subscribe(
            resSrv => {
              if (resSrv.code === 0) {
                this.msjSrv.agregaInfo(resSrv.message);
                this._loadSrv.toggle(false);
                this.ngOnInit();
              } else {
                this.msjSrv.agregaError(resSrv.message);
                this._loadSrv.toggle(false);
              }
            },
            errSrv => {
              this.msjSrv.agregaError('Error al ejecutar la sincronización, intente más tarde.');
              this._loadSrv.toggle(false);
            }
          )
        }
      });
  }
}
