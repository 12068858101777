import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { ClientesService } from './clientes.service';
import { ClientesModel } from './ClientesModel';
import { SucursalModel } from './SucursalesModel';
import { SucursalDetalleComponent } from './det-sucursal.component';

@Component({
    selector: 'krb-det-clientes',
    templateUrl: './det-cliente.component.html',
    styleUrls: ['./det-cliente.component.css']
})
export class ClienteDetalleComponent implements OnInit {

    clieSelected: ClientesModel;

    displayedColumns = ['Nombre', 'Direccion', 'Contacto1', 'Telefonos'];
    sucursales: SucursalModel[];
  
    dataSource = new MatTableDataSource<SucursalModel>();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(@Inject(MAT_DIALOG_DATA) public clienteSelected: any,
                public dialogRef: MatDialogRef<ClienteDetalleComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private dialog: MatDialog,
                private _cliSrv: ClientesService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.clieSelected = null;
    }

    private InitComponente(): void {
        this.clieSelected = <ClientesModel> this.clienteSelected.clienteSelected;
        this.obtieneSucursales();
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }

    private obtieneSucursales(): void {
        this._cliSrv.getSucursales(this.clieSelected.Cliente).toPromise().then(
            res => {
              this.sucursales = res;
              this.dataSource = new MatTableDataSource<SucursalModel>(this.sucursales);
              this.dataSource.paginator = this.paginator;
              this._loadSrv.toggle(false);
            }).catch(error => {
              console.log('Error::ClienteDetalleComponent::obtieneSucursales::', error);
              this._loadSrv.toggle(false);
              this._msjSrv.agregaError('Error al buscar los articulos, intente más tarde.');
            });
    }

    seleccionaElemento(elementSelected: any): void {
        //console.log('Seleciona...', JSON.stringify(elementSelected));
        this.dialog.open(SucursalDetalleComponent, {
            width: '850px',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                sucursalSelected: elementSelected
            }
          });
    }
}
