import {
  Component, OnInit, Output, EventEmitter, Input,
  OnChanges, SimpleChanges, SimpleChange
} from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { PedidoService } from './../services/pedidos.service';
import { ArticuloSelected } from './../modelos/ArticuloSelected';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/finally';
import { isArray } from 'util';

@Component({
  selector: 'karabe-articulo-selector',
  templateUrl: './selector-art.component.html',
  styleUrls: ['./selector-art.component.css']
})
export class ArticulosSelectorComponent implements OnChanges, OnInit {

  autocompleteCtrl: FormControl;
  filteredOptions: Observable<ArticuloSelected[]>;
  articulo: ArticuloSelected;
  @Output() seleccionado = new EventEmitter<ArticuloSelected>();
  showSearchSpinner = 'hidden';
  hasError = false;
  @Input() minInput = 2;
  @Input() resetControl: any;
  @Input() cloneArt: any;
  @Input() almacen: string = '';

  constructor(private _pedidoSrv: PedidoService) { }

  ngOnInit() {
    this.autocompleteCtrl = new FormControl([''], Validators.minLength(this.minInput));
    this.filteredOptions = this.autocompleteCtrl.valueChanges
      .startWith(null)
      .debounceTime(500)
      .distinctUntilChanged()
      .map(articulostr => {
        if (articulostr && typeof articulostr === 'object') {
          this.seleccionado.emit(<ArticuloSelected>articulostr);
          this.setArticulo(<ArticuloSelected>articulostr);
          return articulostr.Descripcion1;
        } else {
          return articulostr;
        }
      }).flatMap(desc => {
        return this.filter(desc);
      });
  }

  filter(artFind: string): Promise<ArticuloSelected[]> | Observable<ArticuloSelected[]> {
    if (artFind && artFind.length) {
      if (artFind.length < this.minInput) {
        return Observable.of(<ArticuloSelected[]>[]);
      } else if (this.articulo && artFind === this.articulo.Descripcion1) {
        return Observable.of(<ArticuloSelected[]>[]);
      } else {
        this.showSearchSpinner = 'visible';
        return this._pedidoSrv.buscaArticulos(artFind, this.almacen).finally(() => {
          this.showSearchSpinner = 'hidden';
        });
      }
    } else {
      this.showSearchSpinner = 'visible';
      return this._pedidoSrv.buscaArticulos('', this.almacen).finally(() => {
        this.showSearchSpinner = 'hidden';
      });
    }
  }

  displayFn(articulo: any): string {
    // console.log(articulo);
    if(articulo && articulo!==null && !isArray(articulo) && articulo.Articulo ) {
      return '(' + articulo.Articulo + ') ' + articulo.Descripcion1;
    } else {
      return '';
    }
  }

  setArticulo(articulo: ArticuloSelected): void {
    this.articulo = articulo;
  }

  @Input() set disabled(disabled: boolean) {
    if (this.autocompleteCtrl) {
      if (disabled) {
        this.autocompleteCtrl.disable({ emitEvent: false });
      } else {
        this.autocompleteCtrl.enable();
      }
    }
  }

  get disabled() {
    return this.autocompleteCtrl.disabled;
  }

  cancel() {
    if (!this.disabled) {
      this.autocompleteCtrl.setValue({});
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('Cambios... ',JSON.stringify(changes));
    const resetCtrl: SimpleChange = changes.resetControl;
    const copyArt: SimpleChange = changes.cloneArt;
    if (this.autocompleteCtrl) {
      if (resetCtrl) {
        if (resetCtrl.currentValue !== resetCtrl.previousValue) {
          this.autocompleteCtrl.setValue({});
        }
      }
      if (copyArt) {
        if(copyArt.currentValue !== copyArt.previousValue) {
          if (copyArt.currentValue !== 'XXX') {
            // console.log('Entra a clonar');
            this._pedidoSrv.buscaArticulos(copyArt.currentValue, this.almacen).subscribe(
              res => {
                this.autocompleteCtrl.setValue(<ArticuloSelected>res[0]);
                this.seleccionado.emit(<ArticuloSelected>res[0]);
                this.setArticulo(<ArticuloSelected>res[0]);
                // this.cloneArt = 'XXX';
              }, err => {
                console.log('ERROR::ArticulosSelectorComponent::ngOnChanges::', err);
              });
          }
        }
      }
    }
  }
}