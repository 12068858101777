import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { ClientesService } from './clientes.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'krb-new-cliente',
    templateUrl: './new-cte.component.html',
    styleUrls: []
})
export class NewClienteComponent implements OnInit {

    cteForm: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public action: any,
                public dialogRef: MatDialogRef<NewClienteComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private _dlgSrv: ConfirmacionService,
                private _builder: FormBuilder,
                private ssnSrv: SessionService,
                private _cteSrv: ClientesService) {
    }

    ngOnInit(): void {
        // console.log('Inicia Dialogo como: ', this.action);
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
    }

    private InitComponente(): void {
        this.creaForm();
    }

    private creaForm(): void {
        this.cteForm = this._builder.group({
            Nombre: [null, Validators.required],
            RFC: [null],
            Telefonos: [null, Validators.required],
            eMail: [null, [Validators.required, Validators.email]],
            Agente: [null]
        });


        if(Number(this.ssnSrv.principal.nivel) === 3 || Number(this.ssnSrv.principal.nivel) === 6) {
            this.cteForm.controls.Agente.patchValue(this.ssnSrv.principal.agente);
        } else {
            this.cteForm.controls.Agente.patchValue(null);
        }

    }

    guardarCliente(): void {
        this._dlgSrv.confirmarSimple('El cliente se afectara en Intelisis, ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this._loadSrv.toggle(true);
                    this._cteSrv.AlmacenaCliente(this.cteForm.value).subscribe(
                        resWS => {
                            if (resWS.code === 0) {
                                this._msjSrv.agregaOK('Se ha insertado el cliente correctamente con la clave [' + resWS.message + '].');
                                this.cerrarDialogo();
                            } else {
                                this._msjSrv.agregaError(resWS.message);    
                            }
                            this._loadSrv.toggle(false);
                        },
                        err => {
                            this._msjSrv.agregaError('Error al insertar el cliente, intente más tarde.');
                            this._loadSrv.toggle(false);
                        }
                    );
                }
            }
        );
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }
}
