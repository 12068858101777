/**
 * Clase de utilerias para la aplicacione
 */
export class UtilsApps {
    
    /**
     * Metodo encargado de codificar un texto plano a SHA1
     * @param value Valor que se desea encriptar en SHA1
     */
    public static sha1(value : string): any {
        function rotl(n, s) { return n << s | n >>> 32 - s; };
        function tohex(i) { for (var h = "", s = 28; ; s -= 4) { h += (i >>> s & 0xf).toString(16); if (!s) return h; } };
        var H0 = 0x67452301, H1 = 0xEFCDAB89, H2 = 0x98BADCFE, H3 = 0x10325476, H4 = 0xC3D2E1F0, M = 0x0ffffffff;
        var i, t, W = new Array(80), ml = value.length, wa = new Array();
        value += String.fromCharCode(0x80);
        while (value.length % 4) value += String.fromCharCode(0);
        for (i = 0; i < value.length; i += 4) wa.push(value.charCodeAt(i) << 24 | value.charCodeAt(i + 1) << 16 | value.charCodeAt(i + 2) << 8 | value.charCodeAt(i + 3));
        while (wa.length % 16 != 14) wa.push(0);
        wa.push(ml >>> 29), wa.push((ml << 3) & M);
        for (var bo = 0; bo < wa.length; bo += 16) {
            for (i = 0; i < 16; i++) W[i] = wa[bo + i];
            for (i = 16; i <= 79; i++) W[i] = rotl(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
            var A = H0, B = H1, C = H2, D = H3, E = H4;
            for (i = 0; i <= 19; i++) t = (rotl(A, 5) + (B & C | ~B & D) + E + W[i] + 0x5A827999) & M, E = D, D = C, C = rotl(B, 30), B = A, A = t;
            for (i = 20; i <= 39; i++) t = (rotl(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & M, E = D, D = C, C = rotl(B, 30), B = A, A = t;
            for (i = 40; i <= 59; i++) t = (rotl(A, 5) + (B & C | B & D | C & D) + E + W[i] + 0x8F1BBCDC) & M, E = D, D = C, C = rotl(B, 30), B = A, A = t;
            for (i = 60; i <= 79; i++) t = (rotl(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & M, E = D, D = C, C = rotl(B, 30), B = A, A = t;
            H0 = H0 + A & M; H1 = H1 + B & M; H2 = H2 + C & M; H3 = H3 + D & M; H4 = H4 + E & M;
        }
        return tohex(H0) + tohex(H1) + tohex(H2) + tohex(H3) + tohex(H4);
    }

    /**
     * Convierte una fecha de String a Date
     * @param _date Fecha en string
     * @param _format formato de la fecha en la que se encuetra Ej. dd/mm/yyyy
     * @param _delimiter Separador o limitador de la fecha Ej. /
     */
    public static stringToDate(_date : string, _format : string, _delimiter : string) : Date {
        const formatLowerCase = _format.toLowerCase();
        const formatItems = formatLowerCase.split(_delimiter);
        const dateItems = _date.split(_delimiter);

        const mesIndex = formatItems.indexOf('mm');
        const diaIndex = formatItems.indexOf('dd');
        const anioIndex = formatItems.indexOf('yyyy');

        let mes = parseInt(dateItems[mesIndex]);
        mes = mes - 1;

        const fechaDate = new Date(parseInt(dateItems[anioIndex]), mes, parseInt(dateItems[diaIndex]));

        return fechaDate;
    }

    /**
     * Regresa una fecha convertida a string en formato yyyy/m/dd el delimitador es configurable
     * @param _date Fecha a convertir tipo Date
     * @param _delimiter Dilimitar para usar al separar la fecha Ej. /
     */
    public static dateToString(_date: Date, _delimiter: string): string {
        const fechaStr: string =
            _date.getFullYear() + _delimiter +
            (_date.getMonth() + 1) +  _delimiter +
            _date.getDate();

        return fechaStr;
    }

    /**
     * Obtiene token aleatoriamente
     */
    public static rand(): string {
        return Math.random().toString(36).substr(2);
    }

}