import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService, UtilsApps } from 'app/shared';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientesModel } from '../ClientesModel';
import { ClientesService } from '../clientes.service';
import { DomicilioModel } from '../selector-cp/DomicilioModel';

@Component({
    selector: 'krb-new-sucursal-cte',
    templateUrl: './new-scursal.component.html'
})
export class SucursalCteNuevoComponent implements OnInit {

    sucursalForm: FormGroup;
    clienteSelected: ClientesModel;
    muestraAll: boolean;
    resetCtrlDom: any = 'A';

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<SucursalCteNuevoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _dlgSrv: ConfirmacionService,
                private _builder: FormBuilder,
                private dialog: MatDialog,
                private _cteSrv: ClientesService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.clienteSelected = null;
        this.creaForm();
        this.sucursalForm.reset();
        this.muestraAll = false;
    }

    private InitComponente(): void {
        this.clienteSelected = <ClientesModel> this.params.clienteSelected;
        
        // Setea los valores que ya traemos
        this.sucursalForm.controls.Cliente.patchValue(this.clienteSelected.Cliente);
    }

    private creaForm(): void {
        this.sucursalForm = this._builder.group({
            Cliente: [null, Validators.required],
            Nombre: [null, Validators.required],
            Direccion: [null, Validators.required],
            DireccionNumero: [null, Validators.required],
            EntreCalles: [null, null],
            Colonia: [null, Validators.required],
            Delegacion: [null, Validators.required],
            Poblacion: [null, null],
            Estado: [null, Validators.required],
            Pais: [null, Validators.required],
            CodigoPostal: [null, Validators.required],
            Telefonos: [null, null],
            Contacto1: [null, Validators.required],
            Contacto2: [null, null],
            eMail1: [null, (Validators.required, Validators.email)],
            observacion: [null, null],
        });
    }

    setDomicilio(domicilio: DomicilioModel): void {
        if (domicilio.codigo_postal !== undefined) {
            this.muestraAll = true;
            this.sucursalForm.controls.Colonia.patchValue(domicilio.nombre_asentamiento);
            this.sucursalForm.controls.Delegacion.patchValue(domicilio.nombre_municipio);
            this.sucursalForm.controls.Poblacion.patchValue(domicilio.nombre_municipio);
            this.sucursalForm.controls.Estado.patchValue(domicilio.nombre_estado);
            this.sucursalForm.controls.Pais.patchValue(domicilio.nombre_pais);
            this.sucursalForm.controls.CodigoPostal.patchValue(domicilio.codigo_postal);
        } else {
            this.limpiarInfo();
        }
    }

    private resertDomicilioFind(): void {
        this.resetCtrlDom = Math.random();
    }

    limpiarInfo(): void {
        this.sucursalForm.reset();
        this.resertDomicilioFind();
        this.muestraAll = false;
    }

    guardarSucursal(): void {
        // console.log(JSON.stringify(this.sucursalForm.getRawValue()));
        this._dlgSrv.confirmarSimple('La sucursal del cliente se afectara en Intelisis, ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this._loadSrv.toggle(true);
                    this._cteSrv.AlmacenaSucursal(this.sucursalForm.getRawValue()).subscribe(
                        resWS => {
                            if (resWS.code === 0) {
                                this._msjSrv.agregaOK('Se ha insertado la sucursal del cliente correctamente.');
                                this.cerrarDialogo();
                            } else {
                                this._msjSrv.agregaError(resWS.message);    
                            }
                            this._loadSrv.toggle(false);
                        },
                        err => {
                            this._msjSrv.agregaError('Error al insertar la sucursal del cliente, intente más tarde.');
                            this._loadSrv.toggle(false);
                        }
                    );
                }
            }
        );
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }
}
