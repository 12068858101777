import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Login2Guard } from '../shared';
import { LoginComponent } from './login.component';
import { RegistroComponent } from './registro/registro.component';
import { DesbloqueoComponent } from './desbloqueo/desbloqueo.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [Login2Guard] },
  { path: 'recuperarpwd', component: DesbloqueoComponent, canActivate: [Login2Guard] },
  { path: 'registro', component: RegistroComponent, canActivate: [Login2Guard] }
  /*
  {
    path: 'login',
    children: [
      { path: '', component: LoginComponent, canActivate: [Login2Guard] }
    ]
  }*/
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
