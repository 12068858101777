import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CargandoService } from '../dialogo-carga/cargando.service';
import { Auth2Service, IdlebootService } from '../../submodules';


@Component({
  selector: 'krb-logout-button',
  template: `
    <button mat-button (click)="cerrarSesion()" matTooltip="Cerrar sesión" matTooltipPosition="before">
      <mat-icon class="md-36">exit_to_app</mat-icon>
    </button>
  `,
  styles: []
})
export class LogoutButtonComponent {

  constructor(private _router: Router,
              private _authSrv: Auth2Service,
              private _loadSrv: CargandoService,
              private _idle: IdlebootService) {
  }

  cerrarSesion() {
    this._loadSrv.toggle(true);
    this._idle.stop();
    this._authSrv.logout();
    this._loadSrv.toggle(false);
    // window.location.reload();
    this.navegar('/login');
  }

  navegar(ruta: string) {
    this._router.navigate([ruta]);
  }

}
