import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
  import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
  import { ClientesService } from './../clientes.service';
  import { Observable } from 'rxjs/Observable';
  import 'rxjs/add/operator/startWith';
  import 'rxjs/add/operator/debounceTime';
  import 'rxjs/add/operator/distinctUntilChanged';
  import 'rxjs/add/operator/map';
  import 'rxjs/add/operator/mergeMap';
  import 'rxjs/add/observable/of';
  import 'rxjs/add/operator/finally';
  import { isArray } from 'util';
import { DomicilioModel } from './DomicilioModel';
  
  @Component({
    selector: 'karabe-domicilio-selector',
    templateUrl: './selector-cp.component.html'
  })
  export class SelectorDomicilioComponent implements OnChanges, OnInit {
  
    autocompleteCtrl: FormControl;
    filteredOptions: Observable<DomicilioModel[]>;
    domicilio: DomicilioModel;
    @Output() seleccionado = new EventEmitter<DomicilioModel>();
    showSearchSpinner = 'hidden';
    hasError = false;
    @Input()
    minInput = 2;
    @Input() resetControl: any;
  
    constructor(private _cteSrv: ClientesService) { }
  
    ngOnInit() {
      this.autocompleteCtrl = new FormControl([''], Validators.minLength(this.minInput));
      this.filteredOptions = this.autocompleteCtrl.valueChanges
        .startWith(null)
        .debounceTime(500)
        .distinctUntilChanged()
        .map(cpstr => {
          if (cpstr && typeof cpstr === 'object') {
            this.seleccionado.emit(<DomicilioModel>cpstr);
            this.setArticulo(<DomicilioModel>cpstr);
            return cpstr.etiqueta;
          } else {
            return cpstr;
          }
        }).flatMap(desc => {
          return this.filter(desc);
        });
    }
  
    filter(domFind: string): Promise<DomicilioModel[]> | Observable<DomicilioModel[]> {
      if (domFind && domFind.length) {
        if (domFind.length < this.minInput) {
          return Observable.of(<DomicilioModel[]>[]);
        } else if (this.domicilio && domFind === this.domicilio.etiqueta) {
          return Observable.of(<DomicilioModel[]>[]);
        } else {
          this.showSearchSpinner = 'visible';
          return this._cteSrv.buscaDomicilio(domFind).finally(() => {
            this.showSearchSpinner = 'hidden';
          });
        }
      } else {
        this.showSearchSpinner = 'visible';
        return this._cteSrv.buscaDomicilio('').finally(() => {
          this.showSearchSpinner = 'hidden';
        });
      }
    }
  
    displayFn(dom: any): string {
      // console.log(articulo);
      if(dom && dom!==null && !isArray(dom) && dom.codigo_postal) {
        return dom.etiqueta;
      } else {
        return '';
      }
    }
  
    setArticulo(domicilio: DomicilioModel): void {
      this.domicilio = domicilio;
    }
  
    @Input() set disabled(disabled: boolean) {
      if (this.autocompleteCtrl) {
        if (disabled) {
          this.autocompleteCtrl.disable({ emitEvent: false });
        } else {
          this.autocompleteCtrl.enable();
        }
      }
    }
  
    get disabled() {
      return this.autocompleteCtrl.disabled;
    }
  
    cancel() {
      if (!this.disabled) {
        this.autocompleteCtrl.setValue({});
      }
    }
  
    ngOnChanges(changes: SimpleChanges) {
      // console.log('Cambios... ',JSON.stringify(changes));
      const resetCtrl: SimpleChange = changes.resetControl;
      if (this.autocompleteCtrl) {
        if (resetCtrl) {
          if (resetCtrl.currentValue !== resetCtrl.previousValue) {
            this.autocompleteCtrl.setValue({});
          }
        }
      }
    }
  }