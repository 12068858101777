export class Identity {
    token_id: string;
    id_usuario: number;
    nombre: string;
    email: string;
    usuario: string;
    agente: string;
    nivel: number;
    intentos: number;
    estatus: string;
    code_recuperacion: string;
    almacen: string;
    fh_registro: Date;
    fh_ult_acceso: Date;
    device_acceso: string;
    code: number;
    message: string;
}
