import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KrbSharedModule, MaterialKrbModule, PrimeNgModule, MatPaginatorIntlEs } from 'app/shared';
import { PortalRoutingModule } from './portal.routing';
import { PortalComponent } from './portal.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material';

import { PortalSharedModule } from './shared';

import { VacioComponent } from './shared/components/vacio.component';
import { PortalConfigService } from './shared/services/portal-config.service';

import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';

import { NewArticuloComponent } from './shared/components/articulos/new-articulo.component';
import { ArticulosDetalleComponent } from './shared/components/articulos/detalle_art.component';
import { ArticulosComponent } from './shared/components/articulos/articulos.component';
import { ArticulosService } from './shared/components/articulos/articulos.service';

import { UpdateOpcionesPedidoComponent } from './shared/components/pedidos/update-opciones/update-opciones.component';
import { UpdateCantidadPedidoComponent } from './shared/components/pedidos/update-cantidad/update-cantidad.component';
import { UpdateUPCPedidoComponent } from './shared/components/pedidos/update-upc/update-upc.component';
import { PedidosComponent } from './shared/components/pedidos/pedidos.component';
import { NuevoPedidoComponent } from './shared/components/pedidos/new-pedido.component';
import { ArticulosSelectorComponent } from './shared/components/pedidos/selector-art/selector-art.component';
import { ClienteSelectorComponent } from './shared/components/pedidos/selector-cliente/selector-cliente.component';
import { HistorialPedidoComponent } from './shared/components/pedidos/historial-pedido/historial-pedido.component';
import { ComentarioPedidoComponent } from './shared/components/pedidos/comentarios-pedidos/comentarios-pedidos.component';
import { AutorizacionCtePedidoComponent } from './shared/components/pedidos/autorizacion-cte/autorizacion-cte.component';
import { UploadAutorizacionCteComponent } from './shared/components/pedidos/upload-autorizacion-cte/upload-autoricacion.component';
import { UpdateSitPedidoComponent } from './shared/components/pedidos/update-sit-pedido/update-sit-pedido.component';
import { VerPedidoComponent } from './shared/components/pedidos/view-pedido.component';
import { SelectorTipoPedidoComponent } from './shared/components/pedidos/selector-tipo-pedido/selector-tipo-pedido.component';
import { DlgAddArticuloComponent } from './shared/components/pedidos/dlg-add-art/dlg-add-art.component';
import { PedidoService } from './shared/components/pedidos/services/pedidos.service';

import { ClientesComponent } from './shared/components/clientes/clientes.component';
import { NewClienteComponent } from './shared/components/clientes/new-cte.component';
import { ClienteDetalleComponent } from './shared/components/clientes/det-cliente.component';
import { SucursalDetalleComponent } from './shared/components/clientes/det-sucursal.component';
import { SucursalCteNuevoComponent } from './shared/components/clientes/new-scursal/new-scursal.component';
import { SelectorDomicilioComponent } from './shared/components/clientes/selector-cp/selector-cp.component';
import { ClientesService } from './shared/components/clientes/clientes.service';

import { PreferenciasComponent } from './shared/components/preferencias/preferencias.component';
import { PreferenciasService } from './shared/components/preferencias/preferencias.service';

import { OnlyNumber } from './shared/directivas/OnlyNumber';

import { SelectorOptArtComponent } from './shared/components/pedidos/selector-opt-art/selector-opt-art.component';
import { SelectorOptArtService } from './shared/components/pedidos/selector-opt-art/selector-opt-art.service';

import { NotificacionesMenuComponent } from './shared/components/notificaciones/noti-modal.component';
import { NotificacionDetalleComponent } from './shared/components/notificaciones/det-noti/det-noti.component';
import { NotificacionesService } from './shared/components/notificaciones/notificaciones.service';

import { UsuariosComponent } from './shared/components/usuarios/usuarios.component';
import { UsuariosDetalleComponent } from './shared/components/usuarios/detalle-user/det-user.component';
import { UsuariosNuevoComponent } from './shared/components/usuarios/new-user/new-user.component';
import { UsuariosBusquedaComponent } from './shared/components/usuarios/find-user/find-user.component';
import { UsuariosService } from './shared/components/usuarios/usuarios.service';

import { AgrupadoresComponent } from './shared/components/agrupadores/agrupadores.component';
import { AgrupadoresDetalleComponent } from './shared/components/agrupadores/det-agrupador/det-agrupador.component';
import { MergeAgpEncComponent } from './shared/components/agrupadores/new-enc-agrupador/new-enc-agrupador.component';
import { MergeAgpDetComponent } from './shared/components/agrupadores/new-det-agrupador/new-det-agrupador.component';
import { AgrupadoresService } from './shared/components/agrupadores/agrupadores.service';

import { UpcComponent } from './shared/components/upc/upc.component';
import { UpcService } from './shared/components/upc/upc.service';

@NgModule({
  
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FlexLayoutModule,
    KrbSharedModule, MaterialKrbModule, PrimeNgModule,
    PortalRoutingModule, PortalSharedModule
  ],
  
  declarations: [
    OnlyNumber,
    PortalComponent, VacioComponent, NewArticuloComponent, ArticulosDetalleComponent, ArticulosComponent,
    PedidosComponent, NuevoPedidoComponent, ArticulosSelectorComponent, ClienteSelectorComponent,
    HistorialPedidoComponent, ComentarioPedidoComponent, 
    UpdateCantidadPedidoComponent, UpdateOpcionesPedidoComponent, 
    UpdateUPCPedidoComponent, AutorizacionCtePedidoComponent, SelectorOptArtComponent,
    UploadAutorizacionCteComponent, VerPedidoComponent, UpdateSitPedidoComponent,
    ClientesComponent, PreferenciasComponent, ClienteDetalleComponent, SucursalDetalleComponent, NewClienteComponent,
    FileUploadComponent, NotificacionesMenuComponent, NotificacionDetalleComponent,
    UsuariosComponent, UsuariosDetalleComponent, UsuariosNuevoComponent, UsuariosBusquedaComponent,
    SucursalCteNuevoComponent, SelectorDomicilioComponent,
    AgrupadoresComponent, AgrupadoresDetalleComponent, MergeAgpEncComponent, MergeAgpDetComponent,
    SelectorTipoPedidoComponent, DlgAddArticuloComponent, UpcComponent
  ],
  
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    DatePipe, PortalConfigService, ArticulosService, PreferenciasService,
    ClientesService, PedidoService, SelectorOptArtService, NotificacionesService,
    UsuariosService, AgrupadoresService, UpcService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEs }],

  entryComponents: [
    NewArticuloComponent,
    ArticulosDetalleComponent,
    ClienteDetalleComponent,
    NewClienteComponent,
    SucursalDetalleComponent,
    HistorialPedidoComponent,
    ComentarioPedidoComponent,
    UpdateCantidadPedidoComponent,
    UpdateOpcionesPedidoComponent,
    UpdateUPCPedidoComponent,
    AutorizacionCtePedidoComponent,
    UploadAutorizacionCteComponent,
    UpdateSitPedidoComponent,
    SelectorOptArtComponent,
    UsuariosDetalleComponent,
    UsuariosNuevoComponent,
    UsuariosBusquedaComponent,
    SucursalCteNuevoComponent,
    AgrupadoresDetalleComponent,
    MergeAgpEncComponent,
    MergeAgpDetComponent,
    NotificacionDetalleComponent,
    SelectorTipoPedidoComponent,
    DlgAddArticuloComponent
  ]
})
export class PortalModule { }
