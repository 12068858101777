import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialKrbModule } from '../shared';
import { LoginRoutingModule } from './login.routing';
import { LoginComponent } from './login.component';

import { RegistroComponent } from './registro/registro.component';
import { RegistroUserService } from './registro/registro.service';

import { DesbloqueoComponent } from './desbloqueo/desbloqueo.component';
import { DesbloqueoService } from './desbloqueo/desbloqueo.service';

import { KrbSharedModule } from 'app/shared';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialKrbModule,
    KrbSharedModule,
    LoginRoutingModule
  ],
  providers: [DesbloqueoService, RegistroUserService],
  declarations: [LoginComponent, DesbloqueoComponent, RegistroComponent]
})
export class LoginModule { }
