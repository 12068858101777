import { Idle } from '@ng-idle/core';
import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MensajesService, TIPOS_MENSAJE, Identity, CargandoService, LoginService, Auth2Service, HttpErrorsService } from 'app/shared';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UtilsApps } from './../shared/utils/utils.app';

@Component({
  selector: 'krb-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent {

  loginForm: FormGroup;
  oculta= true;
  
  constructor(private _router: Router,
              private _msjSrv: MensajesService,
              private _loadSrv: CargandoService,
              private _accessSrv: LoginService,
              private _builder: FormBuilder, 
              private _title: Title,
              private _authSrv: Auth2Service,
              private _httpErroSrv: HttpErrorsService,
              @Inject('IDLE_TIMEOUT') private timeout: number,
              private _idle: Idle,
              private deviceService: DeviceDetectorService) {
    this._title.setTitle('Acceso Pedidos en línea');
    this.loginForm = this._builder.group({
      usuario: ['', Validators.required],
      accesskey: ['', Validators.required]
    });
  }

  onSubmit() {
    this._loadSrv.toggle(true);
    this._accessSrv.login(this.loginForm.value.usuario, UtilsApps.sha1(this.loginForm.value.accesskey)).toPromise().then(
      usuario => {
        if(usuario.code === 0) {
          this._authSrv.login(usuario);
          this._loadSrv.toggle(false);
          if (this._idle.isRunning) {
            this._idle.interrupt();
          }
          this._idle.watch();
          if (this._authSrv.redirectUrl && this._authSrv.redirectUrl.length > 0) {
            this._router.navigateByUrl(this._authSrv.redirectUrl);
            this._authSrv.redirectUrl = null;
          } else {
            this._router.navigate(['/']);
          }
        } else {
          this._loadSrv.toggle(false);
          this._msjSrv.agregaError(usuario.message);
        }
      }).catch(error => {
        this._loadSrv.toggle(false);
        this._msjSrv.agregaError(this._httpErroSrv.getError('login', +error.status));
      });
  }

  navegar(ruta: string) {
    this._router.navigate([ruta]);
  }

  irRegistrar(): void {
    this.navegar('/registro');
  }

}
