import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Identity } from '../../../models';

@Injectable()
export class SessionService {

  private _isLoggedOn = false;
  private _hasToken = false;
  private _hasUser = false;
  private _hasSubUser = false;
  private _isAdmin = false;

  private isLoggedOnS = new Subject<boolean>();
  private hasTokenS = new Subject<boolean>();
  private hasUserS = new Subject<boolean>();
  private hasSubUserS = new Subject<boolean>();
  private isAdminS = new Subject<boolean>();

  isLoggedOn$: Observable<boolean>;
  hasToken$: Observable<boolean>;
  hasUser$: Observable<boolean>;
  hasSubUser$: Observable<boolean>;
  isAdmin$: Observable<boolean>;

  // auth props

  principal: Identity;
  token: string;

  // ui control

  private showPasswordChange = new Subject<boolean>();
  showPasswordChange$: Observable<boolean>;


  constructor() {
    this.isLoggedOn$ = this.isLoggedOnS.asObservable();
    this.hasToken$ = this.hasTokenS.asObservable();
    this.hasUser$ = this.hasUserS.asObservable();
    this.hasSubUser$ = this.hasSubUserS.asObservable();
    this.isAdmin$ = this.isAdminS.asObservable();

    this.showPasswordChange$ = this.showPasswordChange.asObservable();
  }

  set isLoggedOn(isLoggedOn: boolean) {
    this.isLoggedOnS.next(isLoggedOn);
    this._isLoggedOn = isLoggedOn;
  }

  set hasToken(hasToken: boolean) {
    this.hasTokenS.next(hasToken);
    this._hasToken = hasToken;
  }

  set hasUser(hasUser: boolean) {
    this.hasUserS.next(hasUser);
    this._hasUser = hasUser;
  }

  set isAdmin(isAdmin: boolean) {
    this.isAdminS.next(isAdmin);
    this._isAdmin = isAdmin;
  }

  get isLoggedOn(): boolean {
    return this._isLoggedOn;
  }
  get hasToken(): boolean {
    return this._hasToken;
  }
  get hasUser(): boolean {
    return this._hasUser;
  }
  get isAdmin(): boolean {
    return this._isAdmin;
  }

  isPasswordChange(is: boolean) {
    this.showPasswordChange.next(is);
  }
  /*
  getNivelForApp(cveAplicacion: string): number {
    const rolApp = this.principal.rolesApp.find(ra => ra.toUpperCase().indexOf(cveAplicacion.toUpperCase()) >= 0);
    if (rolApp) {
      const rol = rolApp.toUpperCase().replace(cveAplicacion.toUpperCase() + '_', '');
      if (rol === 'ADMINISTRADOR') {
        return 200;
      } else if (rol === 'SUPERVISOR') {
        return 150;
      } else if (rol === 'USUARIO') {
        return 100;
      } else {
        return 99;
      }
    } else {
      return 0;
    }
  }*/

}
