import { Component, ElementRef, ViewChild, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { PedidoService } from './../services/pedidos.service';
import { ArticuloSelected } from '../modelos/ArticuloSelected';
import { SelectorOptArtComponent } from '../selector-opt-art';
import { PedidoArticulos } from '../modelos/PedidoArticulos';
import { OpcionesArticulo } from '../modelos/OpcionesArticulo';

@Component({
    selector: 'krb-dlg-add-articulo',
    templateUrl: './dlg-add-art.component.html',
    styleUrls: []
})
export class DlgAddArticuloComponent implements OnInit {

    articulo: string;
    tipo_pedido: string;
    requiere_importacion: boolean;

    articulosForm: FormGroup;
    resetCtrlArt: any = 'A';
    cloneArt: any = null;

    artPedidos: PedidoArticulos[] = [];
    almacen: string = 'P';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DlgAddArticuloComponent>,
        private _loadSrv: CargandoService,
        private _msjSrv: MensajesService,
        private ssnSrv: SessionService,
        private _builder: FormBuilder,
        private _router: Router,
        private dialog: MatDialog,
        private _dlgSrv: ConfirmacionService,
        private _pedSrv: PedidoService) { }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.createForm();
    }

    private InitComponente(): void {
        this.tipo_pedido = <string> this.data.tipo_pedido;
        this.requiere_importacion = <boolean> this.data.requiere_importacion;
        this.almacen = (this.tipo_pedido == 'TE' ? 'T' : this.tipo_pedido == 'PE' ? 'P' : this.tipo_pedido == 'PT' ? 'T' : 'P');
        // console.log('Inicia Articulos Selector:: Tipo pedido {' + this.tipo_pedido + '}, Requiere Importacion {' + (this.requiere_importacion === true ? 'Si' : 'No') + '}');
    }

    private createForm(): void {
        this.articulosForm = this._builder.group({
            articulo: [null, Validators.required],
            precio: [null, Validators.required],
            Existencia: [null, null]
        });
        this.articulosForm.reset();
    }

    setArticulo(art: ArticuloSelected) {
        if (art !== null) {
            this.articulosForm.controls.articulo.patchValue(art);
            this.articulosForm.controls.precio.patchValue(art.PrecioLista);
            this.articulosForm.controls.Existencia.patchValue(Number(art.Existencia));
        } else {
            this.articulosForm.controls.articulo.patchValue(null);
        }
    }

    agregaArt(): void {
        if (this.validaAddArt()) {
            const dialogRef = this.dialog.open(SelectorOptArtComponent, {
                width: '80%',
                closeOnNavigation: true,
                disableClose: true,
                hasBackdrop: true,
                data: {
                    articuloSelected: this.articulosForm.value.articulo.Articulo,
                    tipo_almacen: this.almacen,
                    valida_existencia: (this.tipo_pedido === 'TE' && this.requiere_importacion === false ? true : false)
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result !== null) {
                    result.forEach(optTmp => {
                        let pedidoArtOne: PedidoArticulos = {};
                        pedidoArtOne.id_articulo = this.articulosForm.value.articulo.id_articulo;
                        pedidoArtOne.Articulo = this.articulosForm.value.articulo.Articulo;
                        pedidoArtOne.Descripcion1 = this.articulosForm.value.articulo.Descripcion1;
                        pedidoArtOne.Unidad = this.articulosForm.value.articulo.Unidad;
                        pedidoArtOne.cantidad = Number(optTmp.Cantidad);
                        pedidoArtOne.precio = Number(this.articulosForm.value.precio);
                        // pedidoArtOne.observaciones = this.articulosForm.value.observaciones;

                        let optArray: OpcionesArticulo[] = [];
                        let optOne: OpcionesArticulo;
                        let optStr = '';
                        let optStrText = '';

                        optTmp.Opciones.forEach(elementOpt => {
                            optOne = {};
                            optOne.descripcion = elementOpt.descripcion;
                            optOne.opcion = elementOpt.opcion;
                            optOne.requerido = Number(elementOpt.requerido);
                            optOne.id_opciond = elementOpt.id_opciond;
                            optOne.Numero = elementOpt.Numero;
                            optOne.Nombre = elementOpt.Nombre;
                            optArray.push(optOne);
                            optStr = optStr + elementOpt.opcion + elementOpt.Numero;
                            optStrText = optStrText + elementOpt.descripcion + ' ' + elementOpt.Nombre + '; ';
                        });
                        pedidoArtOne.opcionesArt = optArray;
                        pedidoArtOne.opcioneStr = optStr;
                        pedidoArtOne.opcioneStrText = optStrText;
                        this.artPedidos.push(pedidoArtOne);

                    });
                    this.dialogRef.close(this.artPedidos);
                }
            });
        }
    }

    /*
    private inicializaFormAddArt(): void {
        this.articulosForm.reset();
        this.resertArticuloFind();
    }

    private resertArticuloFind(): void {
        this.resetCtrlArt = Math.random();
    }*/

    private validaAddArt(): boolean {
        if ((this.tipo_pedido === 'TE' && this.requiere_importacion === false ? true : false)) {
            if (Number(this.articulosForm.value.Existencia) <= 0) {
                this._msjSrv.agregaWarn('Cuando no se requiera importación el articulo debe tener existencia');
                return false;
            }
        }

        if(this.articulosForm.value.articulo === null) {
            this._msjSrv.agregaWarn('Debe selecionar un artículo.');
            return false;
        }

        if(this.articulosForm.value.articulo.id_articulo === null) {
            this._msjSrv.agregaWarn('Debe selecionar un artículo.');
            return false;
        }

        return true;
    }

    cancelar(): void {
        this.dialogRef.close();
    }

}
