import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService, UtilsApps } from 'app/shared';
import { UsuariosService } from './../usuarios.service';
import { UsuarioModel } from './../UsuarioModel';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PasswordValidation } from './password-validation';
import { UsuariosBusquedaComponent } from '../find-user/find-user.component';

@Component({
    selector: 'krb-new-user',
    templateUrl: './new-user.component.html'
})
export class UsuariosNuevoComponent implements OnInit {

    usuarioSelected: UsuarioModel;
    accion: string;

    userForm: FormGroup;
    almacenes: any[];
    niveles: any[];

    oculta: boolean = true;
    ocultaConfirm: boolean = true;

    muestraAll: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<UsuariosNuevoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _dlgSrv: ConfirmacionService,
                private _builder: FormBuilder,
                private dialog: MatDialog,
                private _usrSrv: UsuariosService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.usuarioSelected = null;
    }

    private InitComponente(): void {
        this.usuarioSelected = <UsuarioModel> this.params.usuarioSelected;
        this.accion = this.params.accion;
        this.creaForm();
        this.llenaCombos();

        if (this.accion === 'E') {
            // Setea el almacen
            this.userForm.controls.almacen.patchValue(this.usuarioSelected.almacen);
            // Busca los niveles
            this.buscaNiveles(null);
            // Setea los valores
            this.userForm.controls.nivel.patchValue(this.usuarioSelected.nivel);
            this.userForm.controls.nombre.patchValue(this.usuarioSelected.nombre);
            this.userForm.controls.email.patchValue(this.usuarioSelected.email);
            this.userForm.controls.usuario.patchValue(this.usuarioSelected.usuario);
            this.userForm.controls.agente.patchValue(this.usuarioSelected.agente);
            // inactiva campos que no se pueden cambiar
            this.userForm.controls.almacen.disable();
            this.userForm.controls.nivel.disable();
            // Desactiva nombre solo si este viene desde intelisis
            if (this.usuarioSelected.agente !== null) {
                if (this.usuarioSelected.agente.length > 0) {
                    this.userForm.controls.nombre.disable();
                }
            }
            this.userForm.controls.usuario.disable();
            this.userForm.controls.agente.disable();
            this.muestraAll = true;
        }
    }

    private creaForm(): void {
        this.userForm = this._builder.group({
            nombre: [null, Validators.required],
            email: [null, Validators.compose([Validators.required, Validators.email])],
            usuario: [null, Validators.required],
            password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
            confirmPassword: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
            agente: [null],
            nivel: [null, Validators.required],
            almacen: [null, Validators.required]
        },
        {
            validator: PasswordValidation.MatchPassword
        });
    }

    private llenaCombos(): void {
        this.niveles = [];
        this.almacenes = [
            {value: 'T', viewValue: 'Telas'},
            {value: 'P', viewValue: 'Prendas'}
        ];
    }

    buscaNiveles(event: Event): void {
        this._loadSrv.toggle(true);
        this.niveles = [];
        this._usrSrv.getNiveles(this.userForm.value.almacen).subscribe(
            resNivel => {
                resNivel.forEach(n => {
                    this.niveles.push({value: n.nivel, viewValue: n.nombre});
                });
                this._loadSrv.toggle(false);
            },
            errNivel => {
                this._msjSrv.agregaError('Error al buscar los roles, intente más tarde.')
                this._loadSrv.toggle(false);
            }
        );
    }

    validaTipoUsuarios(event: Event): void {
        this._loadSrv.toggle(true);
        this.muestraAll = false;
        if (Number(this.userForm.value.nivel) === 3 || Number(this.userForm.value.nivel) === 6) {
            const dialogRef = this.dialog.open(UsuariosBusquedaComponent, {
                width: '20%',
                closeOnNavigation: true,
                disableClose: true,
                hasBackdrop: true,
                data: {}
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result !== null) { 
                    // Seat en nulo
                    this.userForm.controls.nombre.patchValue(null);
                    this.userForm.controls.email.patchValue(null);
                    this.userForm.controls.usuario.patchValue(null);
                    this.userForm.controls.password.patchValue(null);
                    this.userForm.controls.confirmPassword.patchValue(null);
                    this.userForm.controls.agente.patchValue(null);
                    // Distribuye info
                    this.userForm.controls.nombre.patchValue((<string>result.Nombre).trim());
                    this.userForm.controls.email.patchValue((<string>result.Email).trim());
                    this.userForm.controls.agente.patchValue((<string>result.Agente).trim());
                    this.userForm.controls.nombre.disable();
                    this.userForm.controls.agente.disable();
                    this.muestraAll = true;
                } else {
                    // Seat en nulo
                    this.limpiarInfo();
                }
            });
        } else {
            // limpia info
            this.userForm.controls.nombre.patchValue(null);
            this.userForm.controls.email.patchValue(null);
            this.userForm.controls.usuario.patchValue(null);
            this.userForm.controls.password.patchValue(null);
            this.userForm.controls.confirmPassword.patchValue(null);
            this.userForm.controls.agente.patchValue(null);
            this.userForm.controls.agente.disable();
            this.muestraAll = true;
        }
        this._loadSrv.toggle(false);
    }

    margeUser(): void {
        // console.log(JSON.stringify(this.userForm.value));
        if (this.accion === 'N') {
            this.crearUsuario();
        } else {
            this.editarUsuario();
        }
    }

    private crearUsuario(): void {
        const mensaje = 'Se creará el usuario, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
          res => {
            if (res) {
              this._loadSrv.toggle(true);
              this._usrSrv.RegistroNuevoUsuario(this.userForm.getRawValue().nombre,
                                                this.userForm.getRawValue().email,
                                                this.userForm.getRawValue().usuario,
                                                UtilsApps.sha1(this.userForm.getRawValue().password),
                                                this.userForm.getRawValue().agente,
                                                Number(this.userForm.getRawValue().nivel),
                                                this.userForm.getRawValue().almacen).toPromise().then(
                resService => {
                  if(resService.code === 0) {
                    this._msjSrv.agregaInfo(resService.message);
                    this._loadSrv.toggle(false);
                    this.cerrarDialogo();
                  } else {
                    this._loadSrv.toggle(false);
                    this._msjSrv.agregaError(resService.message);
                  }
                }).catch(error => {
                  this._loadSrv.toggle(false);
                  console.log('Error ==> ', error);
                  this._msjSrv.agregaError('Error al crear el usuario, intente más tarde.');
                });
            }
        });
    }

    private editarUsuario(): void {
        const mensaje = 'Se actualizará el usuario, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
          res => {
            if (res) {
              this._loadSrv.toggle(true);
              this._usrSrv.EditarUsuario(this.userForm.getRawValue().nombre,
                                         this.userForm.getRawValue().email,
                                         UtilsApps.sha1(this.userForm.getRawValue().password),
                                         this.usuarioSelected.id_usuario).toPromise().then(
                resService => {
                  if(resService.code === 0) {
                    this._msjSrv.agregaInfo(resService.message);
                    this._loadSrv.toggle(false);
                    this.cerrarDialogo();
                  } else {
                    this._loadSrv.toggle(false);
                    this._msjSrv.agregaError(resService.message);
                  }
                }).catch(error => {
                  this._loadSrv.toggle(false);
                  console.log('Error ==> ', error);
                  this._msjSrv.agregaError('Error al actualizar el usuario, intente más tarde.');
                });
            }
        });
    }

    limpiarInfo(): void {
        this.userForm.reset();
        this.muestraAll = false;
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }
}
