import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { ClientesService } from './clientes.service';
import { ClientesModel } from './ClientesModel';
import { ClienteDetalleComponent } from './det-cliente.component';
import { StoreManagement } from './../../../../shared/utils/store-management';
import { NewClienteComponent } from './new-cte.component';
import { SucursalCteNuevoComponent } from './new-scursal/new-scursal.component';

@Component({
  selector: 'krb-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  displayedColumns = ['Cliente', 'Nombre', 'RFC', 'eMail1', 'Telefonos', 'Contacto1'];
  clientes: ClientesModel[];

  dataSource = new MatTableDataSource<ClientesModel>();
  clienteSelected: ClientesModel;
  solActiva: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _title: Title,
    private _router: Router,
    private _loadSrv: CargandoService,
    private _msjSrv: MensajesService,
    private ssnSrv: SessionService,
    private config: PortalConfigService,
    private _cliSrv: ClientesService,
    private _srvStorage: StoreManagement,
    private dialog: MatDialog) {
    config.app = 'Clientes';
  }

  ngOnInit(): void {
    this.limpiaVariables();
    this.InitComponente();
  }

  private limpiaVariables(): void {
    this.clientes = [];
    // this.articuloSelected = null;
  }

  private InitComponente(): void {
    this._loadSrv.toggle(true);
    this._cliSrv.getClientes(this.ssnSrv.principal.agente).toPromise().then(
      res => {
        this.clientes = res;
        this.dataSource = new MatTableDataSource<ClientesModel>(this.clientes);
        this.dataSource.paginator = this.paginator;
        this.clienteSelected = null;
        this.solActiva = false;
        this._loadSrv.toggle(false);
      }).catch(error => {
        console.log('Error::ArticulosComponent::InitComponente::', error);
        this._loadSrv.toggle(false);
        this._msjSrv.agregaError('Error al buscar los articulos, intente más tarde.');
      });
  }

  seleccionaElemento(elementSelected: any): void {
    this.clienteSelected = elementSelected;
    this.solActiva = true;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  verDetalle() {
    this.dialog.open(ClienteDetalleComponent, {
      width: '80%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: {
        clienteSelected: this.clienteSelected
      }
    });
  }

  irAddCte(): void {
    const dialogRef = this.dialog.open(NewClienteComponent, {
      width: '80%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: {
        action: 'N'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.limpiaVariables();
      this.InitComponente();
    });
  }

  agregarSucursal() {
    const dialogRef = this.dialog.open(SucursalCteNuevoComponent, {
      width: '80%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: {
        clienteSelected: this.clienteSelected,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
      this.seleccionaElemento(null);
    });
  }

}
