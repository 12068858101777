import { NgModule, ModuleWithProviders } from '@angular/core';
import {
    MatCardModule, MatButtonModule, MatIconModule, MatInputModule, MatSelectModule, MatChipsModule,
    MatDatepickerModule, MatToolbarModule, MatSnackBarModule, MatNativeDateModule, MatProgressSpinnerModule,
    MatTooltipModule, MatTableModule, MatListModule, MatCheckboxModule, MatAutocompleteModule, MatSliderModule,
    MatMenuModule, MatProgressBarModule, MatDialogModule, MatButtonToggleModule, MatExpansionModule,
    MatTabsModule, MatStepperModule, MatSidenavModule, MatBadgeModule, MatGridListModule
} from '@angular/material';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    imports: [],
    exports: [MatCardModule, MatButtonModule, MatIconModule, MatInputModule, MatSelectModule, MatChipsModule,
        MatDatepickerModule, MatToolbarModule, MatSnackBarModule, MatNativeDateModule, MatProgressSpinnerModule,
        MatTooltipModule, MatTableModule, MatListModule, MatCheckboxModule, MatAutocompleteModule, MatSliderModule,
        MatMenuModule, MatProgressBarModule, MatDialogModule, MatPaginatorModule, MatSortModule, MatButtonToggleModule,
        MatExpansionModule, MatTabsModule, MatStepperModule, MatSidenavModule, MatBadgeModule, MatGridListModule,
        MatRadioModule, MatSlideToggleModule ]
})
export class MaterialKrbModule { }
