import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Auth2Service, CargandoService, IdlebootService, SessionService } from 'app/shared';
import { PortalConfigService } from './shared';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ConfirmacionService } from './../shared/components/dialogo-confirmacion/confirmacion.service';
import { PedidoService } from './shared/components/pedidos/services/pedidos.service';
import 'rxjs/add/observable/interval';

@Component({
  selector: 'krb-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements AfterViewInit, OnInit {

  usuario: string;
  nivelUser: number;
  nombreApp: string;
  itemMenu: any[];
  darkTheme: boolean = false;

  constructor(private _title: Title,
    private _router: Router,
    private _element: ElementRef,
    private _overlayContainer: OverlayContainer,
    private _authSrv: Auth2Service,
    private _loadSrv: CargandoService,
    private _idle: IdlebootService,
    private ssnSrv: SessionService,
    private config: PortalConfigService,
    private _dlgSrv: ConfirmacionService,
    private _pedidoSrv: PedidoService) {

    config.isDarkTheme$.subscribe(isDarktheme => this.setTheme(isDarktheme));
    config.appName$.subscribe(appName => this.setNombreapp(appName));
    config.app = 'Espere....';
    config.isDark = false;
    this.usuario = ssnSrv.principal.nombre;
    this.nivelUser = Number(ssnSrv.principal.nivel);
  }

  ngOnInit(): void {
    this.armaMenu();
  }

  ngAfterViewInit(): void { }

  cerrarSesion() {
    this._dlgSrv.confirmarSimple('¿Desea cerrar sesión?').subscribe(
      res => {
        if (res) {
          this._loadSrv.toggle(true);
          this._idle.stop();
          this._authSrv.logout();
          this._loadSrv.toggle(false);
          // window.location.reload();
          this.navegar('/login');
        }
      });
  }

  toggleTheme() {
    this.config.isDark = !this.darkTheme;
  }

  setTheme(isDark: boolean) {
    this.darkTheme = isDark;
    const darkThemeClass = 'krb-dark-theme';
    if (this.darkTheme) {
      this._element.nativeElement.classList.add(darkThemeClass);
      this._overlayContainer.getContainerElement().classList.add(darkThemeClass);
    } else {
      this._element.nativeElement.classList.remove(darkThemeClass);
      this._overlayContainer.getContainerElement().classList.remove(darkThemeClass);
    }
  }

  setNombreapp(nombre: string) {
    this.nombreApp = nombre;
    this._title.setTitle(`Pedidos en línea | ${nombre}`);
  }

  navegar(ruta: string) {
    this._router.navigate([ruta]);
  }

  irPreferenciasUser() {
    this.navegar('/preferencias');
  }

  irInicio() {
    this.navegar('/');
  }

  irPedidos() {
    this.navegar('/pedidos');
  }

  private armaMenu(): void {
    this.itemMenu = [
      { nombre: 'Inicio', descripcion: 'Inicio', ruta: '/', icon: 'home' },
      { nombre: 'Pedidos', descripcion: 'Historial de pedidos', ruta: '/pedidos', icon: 'shopping_cart' },
      { nombre: 'Articulos', descripcion: 'Catálogo de articulos', ruta: '/articulos', icon: 'shopping_basket' },
      { nombre: 'Clientes', descripcion: 'Catálogo de clientes', ruta: '/clientes', icon: 'group' },
      { nombre: 'Preferencias', descripcion: 'Preferencias del usuario', ruta: '/preferencias', icon: 'settings_applications' },
    ];

    if (Number(this.ssnSrv.principal.nivel) === 1 || Number(this.ssnSrv.principal.nivel) === 2) {
      this.itemMenu.push({ nombre: 'Sistema de códigos', descripcion: 'Actualizar la configuración de UPC o EAN', ruta: '/upc', icon: 'list_alt' }),
        this.itemMenu.push({ nombre: 'Agrupadores', descripcion: 'Catálogo de Agrupadores', ruta: '/agrupadores', icon: 'grid_on' })
      this.itemMenu.push({ nombre: 'Usuarios', descripcion: 'Catálogo de Usuarios', ruta: '/usuarios', icon: 'person' })
    }

  }

}
