import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { AgrupadoresService } from './agrupadores.service';
import { AgrupadoresDetalleComponent } from './det-agrupador/det-agrupador.component';
import { MergeAgpEncComponent } from './new-enc-agrupador/new-enc-agrupador.component';

@Component({
    selector: 'krb-agrupadores',
    templateUrl: './agrupadores.component.html',
    styleUrls: ['./agrupadores.component.css']
})
export class AgrupadoresComponent implements OnInit {

    displayedColumns = ['NombreEnc', 'Situacion'];
    agrupadores: any[];
    dataSource = new MatTableDataSource<any>();
    agrupadorSelected: any;

    solActiva: boolean = false;
    esInactivar: boolean = false;
    esActivar: boolean = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private _title: Title,
        private _router: Router,
        private _loadSrv: CargandoService,
        private _msjSrv: MensajesService,
        private ssnSrv: SessionService,
        private agpSrv: AgrupadoresService,
        private _dlgSrv: ConfirmacionService,
        private config: PortalConfigService,
        private dialog: MatDialog) {
        this.validaUsuario();
        config.app = 'Catálogo de Agrupadores';
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.agrupadores = [];
        this.agrupadorSelected = null;
    }

    private InitComponente(): void {
        this._loadSrv.toggle(true);
        this.agpSrv.getAgrupadores().toPromise().then(
            res => {
                this.agrupadores = res;
                this.dataSource = new MatTableDataSource<any>(this.agrupadores);
                this.dataSource.paginator = this.paginator;
                this.agrupadorSelected = null;
                this.solActiva = false;
                this._loadSrv.toggle(false);
            }).catch(error => {
                console.log('Error::AgrupadoresComponent::InitComponente::', error);
                this._loadSrv.toggle(false);
                this._msjSrv.agregaError('Error al buscar los agrupadores, intente más tarde.');
            });
    }

    seleccionaElemento(elementSelected: any): void {
        this.agrupadorSelected = elementSelected;
        this.manegadorBotonera();
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    manegadorBotonera(): void {
        this.solActiva = false;
        this.esInactivar = false;
        this.esActivar = false;
        if (this.agrupadorSelected !== null) {
            this.solActiva = true;
            if (Number(this.agrupadorSelected.Situacion) === 1) {
                this.esInactivar = true;
                this.esActivar = false;
            } else {
                this.esInactivar = false;
                this.esActivar = true;
            }
        }
    }


    verDetalle() {
        const dialogRef = this.dialog.open(AgrupadoresDetalleComponent, {
            width: '50%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                agrupadorSelected: this.agrupadorSelected
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            this.seleccionaElemento(null);
        });
    }

    cambiaEstatus(estatus: number) {
        this._dlgSrv.confirmarSimple('Se guardaran los cambios del agrupador, ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this._loadSrv.toggle(true);
                    this.agpSrv.MergeAgrupadores('E', 'A', this.agrupadorSelected.NombreEnc, null, estatus).subscribe(
                        resWS => {
                            if (resWS.code === 0) {
                                this._msjSrv.agregaOK('Se completo con exitó la accion..');
                                this.ngOnInit();
                                this.seleccionaElemento(null);
                            } else {
                                this._msjSrv.agregaError(resWS.message);    
                            }
                            this._loadSrv.toggle(false);
                        },
                        err => {
                            this._msjSrv.agregaError('Error al completar la acción, intente más tarde.');
                            this._loadSrv.toggle(false);
                        }
                    );
                }
            }
        );
    }

    mergeAgrupador(accion: string) {
        const dialogRef = this.dialog.open(MergeAgpEncComponent, {
            width: '20%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                agrupadorSelected: this.agrupadorSelected,
                accion: accion
            }
        });
  
        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            this.seleccionaElemento(null);
        });
    }

    private validaUsuario(): void {
        if (Number(this.ssnSrv.principal.nivel) !== 1 && Number(this.ssnSrv.principal.nivel) !== 2) {
            this._router.navigate(['/']);
            this._msjSrv.agregaWarn('No tiene permisos para el recurso!!!');
        }
    }
}
