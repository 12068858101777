import { Component, OnInit } from '@angular/core';
import { PortalConfigService } from './../../shared';

@Component({
  selector: 'krb-vacio',
  template: `<!--<button type="button" mat-button (click)="agregaArt()">Ver Opts Art</button>-->`,
  styles: []
})
export class VacioComponent implements OnInit {

  constructor(private config: PortalConfigService) {
    config.app = 'Inicio';
  }
  
  ngOnInit() {}

  /*
  agregaArt(): void {

      const dialogRef = this.dialog.open(SelectorOptArtComponent, {
          width: '80%',
          closeOnNavigation: true,
          disableClose: true,
          hasBackdrop: true,
          data: { articuloSelected: 'DDSIS123'}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(JSON.stringify(result));
      });
  }*/

}
