import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PedidoService } from './../services/pedidos.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '../../../../../../../node_modules/@angular/forms';
import { Router } from '../../../../../../../node_modules/@angular/router';

@Component({
    selector: 'krb-upload-autorizacion-cte',
    templateUrl: './upload-autoricacion.component.html',
    styleUrls: ['./upload-autoricacion.component.css']
})
export class UploadAutorizacionCteComponent implements OnInit {

    id_pedido: number;
    title: string = 'Cargar la autorización firmada por el cliente';
    icon: string = 'add_box';
    maxSize: number = 3;
    autorizacion: File;
  
    @ViewChild('fileUploader') fileUploader;
    fileName: string;
    imageFormGroup: FormGroup;
    datos_agente: any;
    base64: any;

    constructor(@Inject(MAT_DIALOG_DATA) public pedidoSelected: any,
                public dialogRef: MatDialogRef<UploadAutorizacionCteComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _router: Router,
                private _dlgSrv: ConfirmacionService,
                private _pedidoSrv: PedidoService) {
    }

    ngOnInit(): void {
        this.InitComponente();
        this.limpiaVariables();
    }

    private limpiaVariables(): void {
        this.autorizacion = null;
        this.imageFormGroup.controls.file.patchValue(null);
    }

    private InitComponente(): void {
        this.id_pedido = this.pedidoSelected.id_pedido;
        this._pedidoSrv.dameDatosAgente(this.id_pedido).subscribe(
            resSrv => {
                this.datos_agente = resSrv[0];
            }, errSrv => {
                this._msjSrv.agregaError('Error al buscar los datos del pedido, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        );
        this.imageFormGroup = new FormGroup({
            file: new FormControl(null, Validators.required)
        });

    }

    openFileBrowser(event: any) {
        event.preventDefault();
        this.fileUploader.nativeElement.click();
    }
    
    showPreview(event: any) {
        const filePreview = event.target.files[0];
        const size = ((filePreview.size / 1024) / 1024).toFixed(2);
        if (Number(size) <= this.maxSize) {
          if (filePreview) {
            this.autorizacion = filePreview;
            this.fileName = filePreview.name;
            this.readThis(this.autorizacion);
          } else {
            this.autorizacion = null;
            this.imageFormGroup.controls.file.patchValue(null);
            this.fileName = 'No se ha seleccionado ningún archivo';
            this._msjSrv.agregaWarn('El archivo seleccionado es invalido.');
          }
        } else {
            this.autorizacion = null;
            this.imageFormGroup.controls.file.patchValue(null);
            this.fileName = 'No se ha seleccionado ningún archivo';
            this._msjSrv.agregaWarn('El archivo supera el limite de tamaño establecido. (Max. ' + this.maxSize + ' Mb)');
        }
    }

    cancelar(): void {
        this.cerrarDialogo();
    }

    navegar(ruta: string) {
        this._router.navigate([ruta]);
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }

    readThis(inputValue: any): void {
        const file: File = inputValue;
        const myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
            this.base64 = myReader.result;
        };
        myReader.readAsDataURL(file);
    }

    guardarAutorizacion(): void {
        const mensaje = 'Se anexara la autorización del cliente, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
          res => {
            if (res) {
                this._loadSrv.toggle(true);
                const archivo = {nombre: this.fileName, base64: this.base64 };
                this._pedidoSrv.AlmacenaAutorizacionCte(this.id_pedido, 
                                                        this.ssnSrv.principal.id_usuario,
                                                        this.datos_agente.nivel,
                                                        this.ssnSrv.principal.almacen,
                                                        archivo).subscribe(
                    resSrv => {
                        if (resSrv.code === 0) {
                            this._msjSrv.agregaOK(resSrv.message);
                        } else {
                            console.log('Error al subir la autorizacion::', resSrv.message);
                            this._msjSrv.agregaError('Error al anexar la autorización, intente más tarde.');
                        }
                        this._loadSrv.toggle(false);
                        this.cerrarDialogo();
                    },
                    errSrv => {
                        console.log('Error al subir la autorizacion::', errSrv);
                        this._msjSrv.agregaError('Error al anexar la autorización, intente más tarde.');
                        this._loadSrv.toggle(false);
                    }
                );
            }
        });
    }
}
