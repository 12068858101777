import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MensajesService } from '../../../../shared/components/mensajes/mensajes.service';


@Component({
  selector: 'karabe-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() defaultImage: string;
  @Input() maxSizeImage: number;
  @Output() preview: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('fileUploader') fileUploader;
  @ViewChild('imageView') imageView;

  imageFormGroup: FormGroup;
  filePreview: File;

  constructor(
    private _formBuilder: FormBuilder,
    private _msjSrv: MensajesService,
  ) { }

  ngOnInit() {
    this.imageFormGroup = new FormGroup({
      file: new FormControl(null, Validators.required)
    });
    if (this.defaultImage) {
      // console.log(this.defaultImage);
      // this.readImagePreview(this.defaultImage);
      this.imageView.nativeElement.setAttribute('src', 'data:image/generic;base64,'+this.defaultImage);
    }
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    this.fileUploader.nativeElement.click();
  }

  showPreview(event: any) {
    const self = this;
    self.filePreview = event.target.files[0];
    const size = ((self.filePreview.size / 1024) / 1024).toFixed(2);
    if (Number(size) <= self.maxSizeImage) {
      if (self.filePreview) {
        self.preview.emit(self.filePreview);
        self.readImagePreview(self.filePreview);
      } else {
        self.preview.emit();
      }
    } else {
      this.readImagePreview(null);
      self.imageView.nativeElement.setAttribute('src', '');
      self.preview.emit(null);
      this._msjSrv.agregaWarn('La foto del articulo supera el limite de tamaño establecido. (Max. ' + self.maxSizeImage + ' Mb)');
    }
  }

  readImagePreview(image: any): void {
    const self = this;
    if (image instanceof File) {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        self.imageView.nativeElement.setAttribute('src', evt.target.result);
      };
      reader.readAsDataURL(self.filePreview);
    } else if (typeof(image) === 'string') {
      self.imageView.nativeElement.setAttribute('src', image);
    }
  }

}