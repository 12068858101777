import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { PedidosModel } from './../modelos/PedidosModel';
import { StoreManagement } from './../../../../../shared/utils/store-management';
import { ResponseContentType } from '@angular/http';

@Injectable()
export class PedidoService {

  private _relPath = {
    damePedidosURL: '/services/pedidos/damePedidos.php',
    getConceptosURL: '/services/pedidos/getConceptos.php',
    getClientesURL: '/services/pedidos/getClientes.php',
    getSucursalesClienteURL: '/services/pedidos/getSucursalesCliente.php',
    getAgentesURL: '/services/pedidos/getAgentes.php',
    getNextReferenciaURL: '/services/pedidos/getNextReferencia.php',
    buscaArticulosURL: '/services/pedidos/buscaArticulos.php',
    dameOpcionesArtURL: '/services/pedidos/dameOpcionesArt.php',
    InsertPedidoURL: '/services/pedidos/InsertPedido.php',
    dameHistorialURL: '/services/pedidos/dameHistorial.php',
    dameDetallePedidoURL: '/services/pedidos/dameDetallePedido.php',
    obtieneConteosURL: '/services/pedidos/obtieneConteos.php',
    getPedidoReporteURL: '/services/reportes/getPedidoReporte.php',
    ArchivarPedidoURL: '/services/pedidos/ArchivarPedido.php',
    DesarchivarPedidoURL: '/services/pedidos/DesarchivarPedido.php',
    RegistroPedidoIntelisisURL: '/services/pedidos/RegistroPedidoIntelisis.php',
    getCentroCostoURL: '/services/pedidos/getCentroCosto.php',
    getCondicionesURL: '/services/pedidos/getCondiciones.php',
    EnviaAutorizacionClienteURL: '/services/pedidos/EnviaAutorizacionCliente.php',
    dameDatosAgenteURL: '/services/pedidos/dameDatosAgente.php',
    AlmacenaAutorizacionCteURL: '/services/pedidos/AlmacenaAutorizacionCte.php',
    damePosibilidadesPedidoURL: '/services/pedidos/damePosibilidadesPedido.php',
    ContinuaFlujoPedidoURL: '/services/pedidos/ContinuaFlujoPedido.php',
    cambiaSituacionURL: '/services/pedidos/cambiaSituacion.php',
    dameTiposPedidosURL: '/services/pedidos/dameTiposPedidos.php',
    getPedidosTelasRefURL: '/services/pedidos/getPedidosTelasRef.php',
    getCatEstatusURL: '/services/pedidos/getCatEstatus.php',
    CancelaPedidoIntelisisURL: '/services/pedidos/CancelaPedidoIntelisis.php',
    RecuperaInfoPedidoURL: '/services/pedidos/RecuperaInfoPedido.php',
    UpdatePedidoURL: '/services/pedidos/UpdatePedido.php',
    reporteJackURL: '/services/reportes/viewReporte.php',
    UpdateUPCURL: '/services/pedidos/UpdateUPC.php',
    UpdateCantidadURL: '/services/pedidos/UpdateCantidad.php',
    UpdateVariantesURL: '/services/pedidos/UpdateVariantes.php'
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient,
    private _storeSrv: StoreManagement
  ) { }

  damePedidos(Agente: string, almacen: string, nivel: number, Archivados: string, id_usuario: number ): Observable<PedidosModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.damePedidosURL}`;
    const param = {'Agente': Agente, 'almacen': almacen, 'nivel': nivel, 'Archivados': Archivados, 'id_usuario': id_usuario };
    return this.http
      .post<PedidosModel[]>(url, param, httpOptions)
      .pipe(
        map(response => <PedidosModel[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <PedidosModel[]>null)),
      );
  }

  getConceptos(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getConceptosURL}`;
    const param = null;
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  buscaCliente(agente: string, nombre: string): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getClientesURL}`;
    const param = { 'agente': agente, 'nombre': nombre };
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  getSucursalesCliente(cte: string): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getSucursalesClienteURL}`;
    const param = { 'cte': cte };
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  getAgentes(almacen: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getAgentesURL}`;
    const param = { 'almacen': almacen };
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  getNextReferencia(tipo_pedido: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getNextReferenciaURL}`;
    const param = { 'tipo_pedido': tipo_pedido };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  buscaArticulos(articulo: any, almacen: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.buscaArticulosURL}`;
    const param = { 'articulo': articulo, 'almacen': almacen };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  dameOpcionesArt(Articulo: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.dameOpcionesArtURL}`;
    const param = { 'Articulo': Articulo };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  InsertPedido(pedido: any, id_usuario: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.InsertPedidoURL}`;
    const param = { 'pedido': pedido, 'id_usuario': id_usuario };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  dameHistorial(id_pedido: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.dameHistorialURL}`;
    const param = { 'id_pedido': id_pedido };
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  dameDetallePedido(id_pedido: any, nivel: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.dameDetallePedidoURL}`;
    const param = { 'id_pedido': id_pedido, 'nivel': nivel };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  obtieneConteos(nivel: any, agente: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.obtieneConteosURL}`;
    const param = { 'nivel': nivel, 'Agente': agente };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  getPedidoReporte(id_pedido: any, nivel: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getPedidoReporteURL}`;
    const param = { 'id_pedido': id_pedido, 'nivel': nivel };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  ArchivarPedido(id_pedido: any, id_usuario: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.ArchivarPedidoURL}`;
    const param = { 'id_pedido': id_pedido, 'id_usuario': id_usuario };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  DesarchivarPedido(id_pedido: any, id_usuario: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.DesarchivarPedidoURL}`;
    const param = { 'id_pedido': id_pedido, 'id_usuario': id_usuario };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  RegistroPedidoIntelisis(id_pedido: any, id_usuario: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.RegistroPedidoIntelisisURL}`;
    const param = { 'id_pedido': id_pedido, 'id_usuario': id_usuario };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  getCentroCosto(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getCentroCostoURL}`;
    const param = null;
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  getCondiciones(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getCondicionesURL}`;
    const param = null;
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  EnviaAutorizacionCliente(id_pedido: any, id_usuario: any, nivel: any, almacen: any,
                           email_cliente: any, nombre_cliente: any, email_agente: any,
                           nombre_agente: any, body_email: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.EnviaAutorizacionClienteURL}`;
    const param = { id_pedido: id_pedido,
                    id_usuario: id_usuario,
                    nivel: nivel,
                    almacen: almacen,
                    datos_cliente: {
                      email_cliente: email_cliente,
                      nombre_cliente: nombre_cliente,
                      email_agente: email_agente,
                      nombre_agente: nombre_agente,
                      body_email: body_email
                    }
                  };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  AlmacenaAutorizacionCte(id_pedido: any, id_usuario: any, nivel: any, almacen: any, archivo: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.AlmacenaAutorizacionCteURL}`;
    const param = { id_pedido: id_pedido,
                    id_usuario: id_usuario,
                    nivel: nivel,
                    almacen: almacen,
                    archivo: archivo
                  };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  dameDatosAgente(id_pedido: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.dameDatosAgenteURL}`;
    const param = { id_pedido: id_pedido };
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  damePosibilidadesPedido(tipo_pedido: any, nivel_actual: any, cod_estatus_actual: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.damePosibilidadesPedidoURL}`;
    const param = {
      tipo_pedido: tipo_pedido,
      nivel_actual: nivel_actual,
      cod_estatus_actual: cod_estatus_actual
    };
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  ContinuaFlujoPedido(id_pedido: any, id_usuario: any, id_estatus: any, tipo_pedido: any, tipo_flujo: any, comentario: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.ContinuaFlujoPedidoURL}`;
    const param = {
      id_usuario: id_usuario,
      id_pedido: id_pedido,
      id_estatus: id_estatus,
      tipo_pedido: tipo_pedido,
      tipo_flujo: tipo_flujo,
      comentario: comentario
    };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  cambiaSituacion(id_pedido: any, situacion: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.cambiaSituacionURL}`;
    const param = { id_pedido: id_pedido, situacion: situacion };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  dameTiposPedidos(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.dameTiposPedidosURL}`;
    const param = null;
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  getPedidosTelasRef(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getPedidosTelasRefURL}`;
    const param = null;
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  getCatEstatus(almacen: string): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getCatEstatusURL}`;
    const param = {almacen: almacen};
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any[]>null)),
      );
  }

  CancelaPedidoIntelisis(ID: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.CancelaPedidoIntelisisURL}`;
    const param = { 'ID': ID };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  RecuperaInfoPedido(id_pedido: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.RecuperaInfoPedidoURL}`;
    const param = { 'id_pedido': id_pedido };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  UpdatePedido(pedido: any, id_usuario: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.UpdatePedidoURL}`;
    const param = { 'pedido': pedido, 'id_usuario': id_usuario };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  descargaReporteJack(id_pedido: any, nivel: any): Observable<any> {
    const url = `${this.api}${this._relPath.reporteJackURL}?id_pedido=${id_pedido}&nivel=${nivel}`;
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  UpdateUPC(id_pedido: number, id_pedidoD: number, id_usuario: number, upc_old: string, upc_new: string, estatus: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.UpdateUPCURL}`;
    const param = { id_pedido: id_pedido, id_pedidoD: id_pedidoD, id_usuario: id_usuario, upc_old: upc_old, upc_new: upc_new, estatus: estatus };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }
  
  UpdateCantidad(id_pedido: number, id_pedidoD: number, id_usuario: number, cantidad_old: string, cantidad_new: string, estatus: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.UpdateCantidadURL}`;
    const param = { id_pedido: id_pedido,
                    id_pedidoD: id_pedidoD,
                    id_usuario: id_usuario,
                    cantidad_old: cantidad_old,
                    cantidad_new: cantidad_new,
                    estatus: estatus };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }

  UpdateVariantes(id_pedido: number, id_pedidoD: number, id_usuario: number, opciones_old: any[], opciones_new: any[], estatus: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.UpdateVariantesURL}`;
    const param = { id_pedido: id_pedido,
                    id_pedidoD: id_pedidoD,
                    id_usuario: id_usuario,
                    opciones_old: opciones_old,
                    opciones_new: opciones_new,
                    estatus: estatus };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
      );
  }


  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed:`, error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
