import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PortalComponent } from './portal.component';

import { AuthGuard } from '../shared';
import { VacioComponent } from './shared/components/vacio.component';
import { ArticulosComponent } from './shared/components/articulos/articulos.component';
import { PedidosComponent } from './shared/components/pedidos/pedidos.component';
import { NuevoPedidoComponent } from './shared/components/pedidos/new-pedido.component';
import { VerPedidoComponent } from './shared/components/pedidos/view-pedido.component';
import { ClientesComponent } from './shared/components/clientes/clientes.component';
import { PreferenciasComponent } from './shared/components/preferencias/preferencias.component';
import { UsuariosComponent } from './shared/components/usuarios/usuarios.component';
import { AgrupadoresComponent } from './shared/components/agrupadores/agrupadores.component';
import { UpcComponent } from './shared/components/upc/upc.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: PortalComponent,
    children: [
      { path: '', component: VacioComponent },
      { path: 'articulos', component: ArticulosComponent },
      { path: 'pedidos', component: PedidosComponent },
      { path: 'pedidos/create', component: NuevoPedidoComponent },
      { path: 'pedidos/view', component: VerPedidoComponent },
      { path: 'clientes', component: ClientesComponent },
      { path: 'preferencias', component: PreferenciasComponent },
      { path: 'usuarios', component: UsuariosComponent },
      { path: 'agrupadores', component: AgrupadoresComponent },
      { path: 'upc', component: UpcComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalRoutingModule { }
