import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { ArticulosService } from './articulos.service';
import { ArticuloModel } from './ArticuloModel';
import { ArticulosDetalleComponent } from './detalle_art.component';
import { NewArticuloComponent } from './new-articulo.component';

@Component({
  selector: 'krb-articulos',
  templateUrl: './articulos.component.html',
  styleUrls: ['./articulos.component.css']
})
export class ArticulosComponent implements OnInit {

  displayedColumns = ['Articulo', 'Descripcion1', 'Categoria', 'Fabricante', 'Unidad', 'PrecioLista', 'Precio10', 'Existencia'];
  articulos: ArticuloModel[];

  dataSource = new MatTableDataSource<ArticuloModel>();
  articuloSelected: ArticuloModel;
  solActiva: boolean = false;

  esAgregarArt: boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _title: Title,
    private _router: Router,
    private _loadSrv: CargandoService,
    private _msjSrv: MensajesService,
    private ssnSrv: SessionService,
    private config: PortalConfigService,
    private _artSrv: ArticulosService,
    private dialog: MatDialog) {
    config.app = 'Articulos';
  }

  ngOnInit(): void {
    this.limpiaVariables();
    this.InitComponente();
  }

  private limpiaVariables(): void {
    this.articulos = [];
    // this.articuloSelected = null;

    if (Number(this.ssnSrv.principal.nivel) !== 1 && Number(this.ssnSrv.principal.nivel) !== 2) {
      if (this.ssnSrv.principal.almacen === 'T') {
        this.esAgregarArt = false;
      }
    }
  }

  private InitComponente(): void {
    this._loadSrv.toggle(true);
    this._artSrv.getArticulos().toPromise().then(
      res => {
        this.articulos = res;
        this.dataSource = new MatTableDataSource<ArticuloModel>(this.articulos);
        this.dataSource.paginator = this.paginator;
        this.articuloSelected = null;
        this.solActiva = false;
        this._loadSrv.toggle(false);
      }).catch(error => {
        console.log('Error::ArticulosComponent::InitComponente::', error);
        this._loadSrv.toggle(false);
        this._msjSrv.agregaError('Error al buscar los articulos, intente más tarde.');
      });
  }

  seleccionaElemento(elementSelected: any): void {
    this.articuloSelected = elementSelected;
    this.solActiva = true;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  verDetalle() {
    this.dialog.open(ArticulosDetalleComponent, {
      width: '80%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: {
        articuloSelected: this.articuloSelected
      }
    });
  }

  irAddArt() {
    const dialogRef = this.dialog.open(NewArticuloComponent, {
      width: '80%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: {
        action: 'N',
        articuloSelected: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.limpiaVariables();
      this.InitComponente();
    });
  }

  irEditarArt() {

    this._loadSrv.toggle(true);
    this._artSrv.getArticuloEditar(this.articuloSelected.Articulo).subscribe(
      resArtFind => {
        this._loadSrv.toggle(false);
        const dialogRef = this.dialog.open(NewArticuloComponent, {
          width: '80%',
          closeOnNavigation: true,
          disableClose: true,
          hasBackdrop: true,
          data: {
            action: 'E',
            articuloSelected: resArtFind[0]
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          this.limpiaVariables();
          this.InitComponente();
        });
      },
      errArtFind => {
        console.error('Error al recuperar la info del articulo...', errArtFind);
        this._msjSrv.agregaError('Error al recuperar la información del articulo.');
        this._loadSrv.toggle(false);
      }
    );
  }

}
