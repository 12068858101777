import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { UsuariosService } from './../usuarios.service';
import { UsuarioModel } from './../UsuarioModel';

@Component({
    selector: 'krb-det-user',
    templateUrl: './det-user.component.html'
})
export class UsuariosDetalleComponent implements OnInit {

    usuarioSelected: UsuarioModel;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<UsuariosDetalleComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _usrSrv: UsuariosService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.usuarioSelected = null;
    }

    private InitComponente(): void {
        this.usuarioSelected = <UsuarioModel> this.params.usuarioSelected;
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }
}
