import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { FormBuilder, FormControl, Validators, FormGroup } from '../../../../../../node_modules/@angular/forms';
import { UpcService } from './upc.service';

@Component({
    selector: 'krb-upc',
    templateUrl: './upc.component.html',
    styles: []
})
export class UpcComponent implements OnInit {

    formUpc: FormGroup;

    constructor(private builder: FormBuilder,
                private _title: Title,
                private _router: Router,
                private _loadSrv: CargandoService,
                private _dlgSrv: ConfirmacionService,
                private msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private config: PortalConfigService,
                private upcSrv: UpcService) {
        config.app = 'Sistema de código';
    }

    ngOnInit() {
        this.armaFormulario();
        this.initComponent();
    }

    private armaFormulario() {
        this.formUpc = this.builder.group({
            cod_pais: [null, Validators.required],
            cod_empresa: [null, Validators.required],
            calculo: [null, Validators.required],
            consecutivo: [null, Validators.required],
        });
    }

    private initComponent() {
        this._loadSrv.toggle(true);
        this.upcSrv.dameValoresUPC().subscribe(
            res => {
                // console.log(JSON.stringify(res));
                this.formUpc.controls.cod_pais.patchValue(res.cod_pais);
                this.formUpc.controls.cod_empresa.patchValue(res.cod_empresa);
                this.formUpc.controls.calculo.patchValue(res.calculo);
                this.formUpc.controls.consecutivo.patchValue(res.consecutivo);
                this._loadSrv.toggle(false);
            },
            err => {
                console.error('Error al consultar los UPC:::', err);
                this.msjSrv.agregaError('Error al encontrar la configuración de los sistemas de códigos.')
                this._loadSrv.toggle(false);
            }
        );
    }

    descartar(): void {
        this.initComponent();
    }

    selectionChange(event): void {
        let valor: string = this.formUpc.value.consecutivo;
        if (this.formUpc.value.calculo === 'UPC') {
            valor = valor.padStart(3, '0');
            this.formUpc.controls.consecutivo.patchValue(valor.substring(0, 3));
        } else {
            valor = valor.padStart(4, '0');
            this.formUpc.controls.consecutivo.patchValue(valor.substring(0, 4));
        }
    }

    rellenaCeros(): void {
        let valor: string = this.formUpc.value.consecutivo;
        if (this.formUpc.value.calculo === 'UPC') {
            valor = valor.padStart(3, '0');
        } else {
            valor = valor.padStart(4, '0');
        }
        this.formUpc.controls.consecutivo.patchValue(valor);
    }

    actualizaUpc(): void {
        const mensaje = 'Se actualizará los valores del sistema de códigos, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
            res => {
                if (res) {
                    this._loadSrv.toggle(true);
                    this.upcSrv.updateConfUpc(this.formUpc.value.cod_pais,
                                              this.formUpc.value.cod_empresa,
                                              this.formUpc.value.calculo,
                                              this.formUpc.value.consecutivo).subscribe(
                        res => {
                            if (res.code === 0) {
                                this.msjSrv.agregaInfo(res.message);
                                this.initComponent();
                            } else {
                                this.msjSrv.agregaError(res.message);
                            }
                            this._loadSrv.toggle(false);
                        },
                        err => {
                            console.error('Error al actualizar los UPC:::', err);
                            this.msjSrv.agregaError('Error al actualizar la configuración de los sistemas de códigos.')
                            this._loadSrv.toggle(false);
                        }
                    );
                }
            }
        );
    }
}
