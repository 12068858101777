import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { CargandoService, SessionService, MensajesService } from 'app/shared';
import { ClientesService } from './clientes.service';
import { SucursalModel } from './SucursalesModel';

@Component({
    selector: 'krb-det-sucursal',
    templateUrl: './det-sucursal.component.html',
    styleUrls: ['./det-sucursal.component.css']
})
export class SucursalDetalleComponent implements OnInit {

    sucSelected: SucursalModel;

    constructor(@Inject(MAT_DIALOG_DATA) public sucursalSelected: any,
                public dialogRef: MatDialogRef<SucursalDetalleComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _cliSrv: ClientesService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.sucSelected = null;
    }

    private InitComponente(): void {
        this.sucSelected = <SucursalModel> this.sucursalSelected.sucursalSelected;
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }

}
