import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { ArticuloModel } from './ArticuloModel';
import { StoreManagement } from './../../../../shared/utils/store-management';

@Injectable()
export class ArticulosService {

  private _relPath = {
    getArticulosURL: '/services/articulos/getArticulos.php',
    getOpcionesArtURL: '/services/articulos/getOpcionesArt.php',
    getAgrupadoresArticuloURL: '/services/articulos/getAgrupadoresArticulo.php',
    getCombosArtURL: '/services/articulos/getCombosArt.php',
    getAgrupadorValoresURL: '/services/articulos/getAgrupadorValores.php',
    AlmacenaArticuloURL: '/services/articulos/AlmacenaArticulo.php',
    getArticuloEditarURL: '/services/articulos/getArticuloEditar.php',
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient,
    private _storeSrv: StoreManagement
  ) { }

  getArticulos(): Observable<ArticuloModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getArticulosURL}`;
    const param = null;
    return this.http
      .post<ArticuloModel[]>(url, param, httpOptions)
      .pipe(
        map(response => <ArticuloModel[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <ArticuloModel[]>null)),
    );
  }

  getOpcionesArt(articulo: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getOpcionesArtURL}`;
    const param = {'articulo': articulo};
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <any[]>null)),
    );
  }

  getAgrupadoresArticulo(articulo: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getAgrupadoresArticuloURL}`;
    const param = {'articulo': articulo};
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <any[]>null)),
    );
  }

  getCombosArt(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getCombosArtURL}`;
    const param = null;
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <any>null)),
    );
  }

  getAgrupadorValores(idAgrupador: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getAgrupadorValoresURL}`;
    const param = {'idAgrupador': idAgrupador};
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('getAgrupadorValores', <any>null)),
    );
  }

  AlmacenaArticulo(accion: string, articulo: any, opciones: any, agrupadores: any, imagen: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.AlmacenaArticuloURL}`;
    const param = {'Accion': accion, 'articulo': articulo, 'opciones' : opciones, 'agrupadores' : agrupadores, 'imagen' : imagen };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <any>null)),
    );
  }

  getArticuloEditar(articulo: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getArticuloEditarURL}`;
    const param = {'articulo': articulo};
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <any[]>response),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Articulos', <any[]>null)),
    );
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed:`, error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
