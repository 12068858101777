import { NgModule, ModuleWithProviders } from '@angular/core';

import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
    exports: [ TableModule, TooltipModule, DropdownModule, ProgressBarModule, ButtonModule, CalendarModule ]
})
export class PrimeNgModule { }