import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from './../../../../shared/utils/store-management';

@Injectable()
export class PreferenciasService {

  private _relPath = {
    getUsuarioInfoURL: '/services/preferencias/getUsuarioInfo.php',
    cambiaPasswordURL: '/services/preferencias/cambiaPassword.php',
    cambiaEmailURL: '/services/preferencias/cambiaEmail.php',
    cambiaPrefNotificacionesURL: '/services/preferencias/cambiaPrefNotificaciones.php',
    sincronizarIntelisisURL: '/services/preferencias/sincronizarIntelisis.php',
    getPrefNotificacionesURL: '/services/preferencias/getPrefNotificaciones.php'
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient,
    private _storeSrv: StoreManagement
  ) { }

  getUsuarioInfo(id_usuario: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getUsuarioInfoURL}`;
    const param = { 'id_usuario': id_usuario };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
    );
  }

  getPrefNotificaciones(id_usuario: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.getPrefNotificacionesURL}`;
    const param = { 'id_usuario': id_usuario };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
    );
  }

  changePwd(id_usuario: number, pwActual: string, pwNueva: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.cambiaPasswordURL}`;
    const param = { 'id_usuario': id_usuario, 'pwActual': pwActual, 'pwNueva': pwNueva };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
    );
  }

  actualizaEmail(id_usuario: number, email: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.cambiaEmailURL}`;
    const param = { 'id_usuario': id_usuario, 'email': email };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
    );
  }

  cambiaPrefNotificaciones(noti: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.cambiaPrefNotificacionesURL}`;
    const param = { 'noti': noti };
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
    );
  }

  sincronizarIntelisis(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this._storeSrv.getToken()}`
      })
    };
    const url = `${this.api}${this._relPath.sincronizarIntelisisURL}`;
    const param = null;
    return this.http
      .post<any>(url, param, httpOptions)
      .pipe(
        map(response => <any>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('Pedidos', <any>null)),
    );
  }


  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed:`, error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
