import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PedidoService } from './../services/pedidos.service';
import { FormGroup, FormBuilder, Validators } from '../../../../../../../node_modules/@angular/forms';
import { Router } from '../../../../../../../node_modules/@angular/router';

@Component({
    selector: 'krb-autoriza-cte-pedido',
    templateUrl: './autorizacion-cte.component.html',
    styleUrls: ['./autorizacion-cte.component.css']
})
export class AutorizacionCtePedidoComponent implements OnInit {

    id_pedido: any;
    datos_agente: any;
    formAutorizacion: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public id_pedido_selected: any,
                public dialogRef: MatDialogRef<AutorizacionCtePedidoComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _router: Router,
                private _dlgSrv: ConfirmacionService,
                private _pedidoSrv: PedidoService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.inicializaForm();
        this.formAutorizacion.reset();
        this.id_pedido = null;
        this.datos_agente = {};
    }

    private InitComponente(): void {
        this._loadSrv.toggle(true);
        this.id_pedido = this.id_pedido_selected.id_pedido;
        this._pedidoSrv.dameDatosAgente(this.id_pedido).subscribe(
            resSrv => {
                this.datos_agente = resSrv[0];
                this.formAutorizacion.controls.nombre_cliente.patchValue(this.datos_agente.nombre_cte);
                this.formAutorizacion.controls.email_cliente.patchValue(this.datos_agente.email_cte);
                this.formAutorizacion.controls.nombre_agente.patchValue(this.datos_agente.nombre_agente);
                this.formAutorizacion.controls.email_agente.patchValue(this.datos_agente.email_agente);
                
                this.formAutorizacion.controls.nombre_cliente.disable();
                this.formAutorizacion.controls.nombre_agente.disable();
                this.formAutorizacion.controls.email_agente.disable();
                this._loadSrv.toggle(false);
            }, errSrv => {
                this._msjSrv.agregaError('Error al buscar los datos del pedido, intente más tarde.');
                this._loadSrv.toggle(false);
            }
        )
    }

    private inicializaForm(): void {
        this.formAutorizacion = this._builder.group({
            nombre_cliente: [null, Validators.required],
            email_cliente: [null, [Validators.required, Validators.email]],
            text: [null, Validators.required],
            nombre_agente: [null, Validators.required],
            email_agente: [null, [Validators.required, Validators.email]]
        });
    }

    cancelar(): void {
        this.cerrarDialogo(1);
    }

    navegar(ruta: string) {
        this._router.navigate([ruta]);
    }

    cerrarDialogo(code: number): void {
        this.dialogRef.close({'Code':code});
    }

    mandaAutorizacion(): void {
        const mensaje = 'El pedido se enviará para su autorización con el cliente, ¿Desea continuar?';
        this._dlgSrv.confirmarSimple(mensaje).subscribe(
          res => {
            if (res) {
                this._loadSrv.toggle(true);
                this._pedidoSrv.EnviaAutorizacionCliente(this.id_pedido, 
                                                         this.ssnSrv.principal.id_usuario,
                                                         this.datos_agente.nivel,
                                                         this.ssnSrv.principal.almacen,
                                                         this.formAutorizacion.value.email_cliente,
                                                         this.datos_agente.nombre_cte,
                                                         this.datos_agente.email_agente,
                                                         this.datos_agente.nombre_agente,
                                                         this.formAutorizacion.value.text).subscribe(
                    resAut => {
                        if (resAut.code === 0) {
                            this._msjSrv.agregaOK(resAut.message);
                            this.cerrarDialogo(0);
                        } else {
                            console.log('Error al enviar la autorizacion::', resAut.message);
                            this._msjSrv.agregaError('Error al enviar la autorización, intente más tarde.');
                            this.cerrarDialogo(1);
                        }
                        this._loadSrv.toggle(false);
                    },
                    errAut => {
                        console.log('Error al enviar la autorizacion::', errAut);
                        this._msjSrv.agregaError('Error al enviar la autorización, intente más tarde.');
                        this.cerrarDialogo(1);
                        this._loadSrv.toggle(false);
                    }
                );
            }
        });
    }
}
