import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Identity } from '../models';
import { Usuario } from '../submodules/session';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';

@Injectable()
export class LoginService {

  private _relPath = {
    loginServiceURL: '/services/login/SessionLogin.php',
    pingURL: '/services/login/SessionPing.php',
    perfilURL: '/services/login/SessionPerfil.php'
  };

  constructor(
    @Inject('AUTH_API_ENDPOINT') private api: string,
    private http: HttpClient
  ) {}

  login(user: string, pass: string): Observable<Identity> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };
    const url = `${this.api}${this._relPath.loginServiceURL}`;
    const param = {'login' : {'usuario': user, 'password' : pass}};
    return this.http
      .post<any[]>(url, param, httpOptions)
      .pipe(
        map(response => <Identity>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('login', <Identity>null)),
      );
  }

  perfil(token: string): Observable<Identity> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':  `Bearer ${token}`,
      })
    };
    const url = `${this.api}${this._relPath.perfilURL}`;
    return this.http
      .post<any>(url, null, httpOptions)
      .pipe(
        map(response => <Identity>response[0]),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(this.handleError('perfil', <Identity>null)),
      );
  }

  ping(token: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':  `Bearer ${token}`
      })
    };
    const url = `${this.api}${this._relPath.pingURL}`;
    return this.http
      .post<any>(url, null, httpOptions)
      .pipe(
        map(response => (response[0]['code'] === 0 ? true : false)),
        timeoutWith(1999999, throwError(new Error('Request timed out'))),
        catchError(() => of(false)),
    );
  }
  /*
  autorizar(token: string, loginData: Identity, nivel = 150, app: string): Observable<boolean> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    let url = `${this.api}${this._relPath.autorizarURL}/${nivel}`;
    if (app && app !== null && app.length > 0) {
      url = `${this.api}${this._relPath.autorizarURL}/${nivel}/${app.toUpperCase()}`;
    }
    return this.http
      .post<boolean>(url, JSON.stringify(loginData), { headers: headers })
      .pipe(
        timeoutWith(3000, throwError(new Error('Request timed out'))),
        catchError(this.handleError('autorizar', false)),
    );
  }*/

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed:`, error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
