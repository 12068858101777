import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatPaginator, MatTableDataSource, MatDialog, MatTable } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { PortalConfigService } from './../../../shared';
import { PedidoService } from './services/pedidos.service';
import { PedidosModel } from './modelos/PedidosModel';
import { HistorialPedidoComponent } from './historial-pedido/historial-pedido.component';
import { DataService } from '../../../../shared/services/DataService';
import { UpdateSitPedidoComponent } from './update-sit-pedido/update-sit-pedido.component';
import { SelectItem } from 'primeng/components/common/selectitem';
import { Table } from 'primeng/table';

@Component({
  selector: 'krb-pedidos',
  templateUrl: './html/pedidos.component.html',
  styleUrls: ['./css/pedidos.component.css']
})
export class PedidosComponent implements OnInit {

  pedidos: PedidosModel[];

  pedidoSelected: PedidosModel;
  solActiva: boolean = false;
  esArchivar: boolean = false;
  esDesarchivar: boolean = false;
  estatus: SelectItem[];
  situaciones: SelectItem[];
  isSituacion: boolean = false;
  verArchivados: boolean = false;
  es: any;

  constructor(private _title: Title, 
              private _router: Router,
              private _loadSrv: CargandoService,
              private _msjSrv: MensajesService,
              private ssnSrv: SessionService,
              private _dlgSrv: ConfirmacionService,
              private _paramSrv: DataService,
              private config: PortalConfigService,
              private _pedidoSrv: PedidoService,
              private dialog: MatDialog) {
    config.app = 'Pedidos';
  }

  ngOnInit(): void {
    this.limpiaVariables();
    this.llenaCombo();
    this.InitComponente();
    // this.seleccionaElemento(null);

    this.es = {
      firstDayOfWeek: 1,
      dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
      dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
      dayNamesMin: [ "D","L","M","X","J","V","S" ],
      monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],
      monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
      today: 'Hoy',
      clear: 'Borrar'
    }

  }

  private limpiaVariables(): void {
    this.pedidos = [];
    this.pedidoSelected = null;
    if (this.ssnSrv.principal.almacen === 'T' && Number(this.ssnSrv.principal.nivel) === 5) {
      this.isSituacion = true;
    } else {
      this.isSituacion = false;
    }
  }

  private InitComponente(): void {
    this._loadSrv.toggle(true);
    this._pedidoSrv.damePedidos((this.ssnSrv.principal.agente === null ? null : this.ssnSrv.principal.agente.length === 0 ? null : this.ssnSrv.principal.agente),
      this.ssnSrv.principal.almacen, this.ssnSrv.principal.nivel,
      (this.verArchivados ? 'V': 'F'), this.ssnSrv.principal.id_usuario).toPromise().then(
      res => {
        this.pedidos = res;
        this.pedidoSelected = null;
        this.solActiva = false;
        this._loadSrv.toggle(false);
      }).catch(error => {
        console.log('Error::ArticulosComponent::InitComponente::', error);
        this._loadSrv.toggle(false);
        this._msjSrv.agregaError('Error al buscar los articulos, intente más tarde.');
      });
  }

  private llenaCombo(): void {
    this._loadSrv.toggle(true);
    this.estatus = [];
    this.estatus.push({value: null, label: 'Todos'});
    this._pedidoSrv.getCatEstatus(this.ssnSrv.principal.almacen).subscribe(
      res => {
        res.forEach(est => {
          this.estatus.push({value: est.cod_estatus, label: est.nombre});
        });
        this._loadSrv.toggle(false);
      },
      err => {
        console.error('Error al obtener los estatus:::', err);
        this._loadSrv.toggle(false);
      });

    this.situaciones = [
      {value: null, label: 'Todos'},
      {value: 'Entregado', label: 'Entregado'},
      {value: 'Entregado Parcialidades', label: 'Entregado Parcialidades'},
      {value: 'Pendiente de Pago', label: 'Pendiente de Pago'},
      {value: 'Cancelado', label: 'Cancelado'}
    ]
  }

  seleccionaElemento(event: any): void {
    this.solActiva = true;
    this.esArchivar = false;
    this.esDesarchivar = false;
    if (this.pedidoSelected !== null) {
      if ( this.pedidoSelected.estatus === 'CONCLUIDO' || 
           this.pedidoSelected.estatus === 'CANCELADO' ||
           this.pedidoSelected.estatus === 'RECHAZADO' ) {
        this.esArchivar = true;
      }
      if (this.verArchivados) {
        this.esDesarchivar = true;
        this.esArchivar = false;
      }
    }

  }

  appliFilterArchivados(cheked: boolean) {
    this.verArchivados = cheked;
    if (this.verArchivados) {
      console.log('Es ver archivados');
      this.InitComponente();
    } else {
      console.log('Es ver principales');
      this.InitComponente();
    }
    this.seleccionaElemento(null);
  }

  navegar(ruta: string) {
    this._router.navigate([ruta]);
  }

  irNuevoPedido(): void {
    this.navegar('/pedidos/create');
  }

  irViewPedido(): void {
    this._paramSrv.sessionMap['PedidoSelect'] = this.pedidoSelected;
    this.navegar('/pedidos/view');
  }

  verHistorial() {
    this.dialog.open(HistorialPedidoComponent, {
      width: '95%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: {
        pedidoSelected: this.pedidoSelected
      }
    });
  }

  cambiaSituacion(): void {
    const dialogRef = this.dialog.open(UpdateSitPedidoComponent, {
        width: '50%',
        closeOnNavigation: true,
        disableClose: true,
        hasBackdrop: true,
        data: {
          pedidoSelected: this.pedidoSelected
        }
    });

    
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });

  }

  mandaArchivar(): void {
    const mensaje = 'El pedido se archivará, ¿Desea continuar?';
    this._dlgSrv.confirmarSimple(mensaje).subscribe(
      res => {
        if (res) {
            this._loadSrv.toggle(true);
            this._pedidoSrv.ArchivarPedido(this.pedidoSelected.id_pedido, 
                                          this.ssnSrv.principal.id_usuario).toPromise().then(
                res => {
                    if(res.code === 0) {
                        this._msjSrv.agregaInfo(res.message);
                        this.InitComponente();
                    } else {
                        this._msjSrv.agregaError(res.message);
                    }
                    this._loadSrv.toggle(false);
                }).catch(error => {
                    console.log('Error::PedidosComponent::mandaArchivar::', error);
                    this._msjSrv.agregaError('Error al archivar el pedido, intente más tarde.');
                    this._loadSrv.toggle(false);
                }
            );   
        }
      });
  }

  mandaDesarchivar(): void {
    const mensaje = 'El pedido se desarchivará, ¿Desea continuar?';
    this._dlgSrv.confirmarSimple(mensaje).subscribe(
      res => {
        if (res) {
            this._loadSrv.toggle(true);
            this._pedidoSrv.DesarchivarPedido(this.pedidoSelected.id_pedido, 
                                          this.ssnSrv.principal.id_usuario).toPromise().then(
                res => {
                    if(res.code === 0) {
                        this._msjSrv.agregaInfo(res.message);
                        this.InitComponente();
                    } else {
                        this._msjSrv.agregaError(res.message);
                    }
                    this._loadSrv.toggle(false);
                }).catch(error => {
                    console.log('Error::PedidosComponent::mandaDesarchivar::', error);
                    this._msjSrv.agregaError('Error al archivar el pedido, intente más tarde.');
                    this._loadSrv.toggle(false);
                }
            );   
        }
      });
  }

  public muestraSituacion(): boolean {
    if (this.ssnSrv.principal.almacen === 'T') {
      return true;
    } else {
      return false;
    }
  }
  
}