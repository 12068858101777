import { Component, ElementRef, ViewChild, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargandoService, SessionService, MensajesService, ConfirmacionService } from 'app/shared';
import { SelectorOptArtService } from './selector-opt-art.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { OpcionesArticulo } from './../modelos/OpcionesArticulo';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatChipInputEvent} from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'krb-selector-opciones-articulo',
    templateUrl: './selector-opt-art.component.html',
    styleUrls: ['./selector-opt-art.component.css']
})
export class SelectorOptArtComponent implements OnInit {

    articulo: string;
    tipo_almacen: string;
    valida_existencia: boolean;
    descArt: string;
    descReg: string;
    descCol: string;
    conlumnas: any[];
    conlumnasFiltered: Observable<any[]>;
    renglones: any[];
    renglonesFiltered: Observable<any[]>;
    ctrlColumnas: FormControl
    ctrlRenglones: FormControl;
    ctrlNumModelos: FormControl;
    tieneColumna: boolean;
    tieneRenglon: boolean;
    tieneListas: boolean;
    esDibujo: boolean;
    dondeDibujo: string;

    tableCol: any[];
    tableReg: any[][];

    listaForm: FormGroup;
    numRenglones: number;
    numColumnas: number;

    isTrabajando: boolean;
    renglonesSeleccionados: any[];
    columnasSeleccionados: any[];

    separatorKeysCodes: number[] = [ENTER, COMMA];

    disponiblilidad : any[];

    @ViewChild('renglonesInput') renglonesInput: ElementRef<HTMLInputElement>;
    @ViewChild('columnasInput') columnasInput: ElementRef<HTMLInputElement>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<SelectorOptArtComponent>,
                private _loadSrv: CargandoService,
                private _msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private _builder: FormBuilder,
                private _router: Router,
                private _dlgSrv: ConfirmacionService,
                private _selSrv: SelectorOptArtService) {

        this.ctrlRenglones = new FormControl();
        this.ctrlColumnas = new FormControl();
        this.ctrlNumModelos = new FormControl(null, Validators.required);
        this.renglonesFiltered = this.ctrlRenglones.valueChanges
            .startWith(null)
            .debounceTime(500)
            .distinctUntilChanged()
            .map(buscar => {
                if (buscar && typeof buscar === 'object') {
                    return buscar.Nombre;
                } else {
                    return buscar;
                }
            }).flatMap(buscar => {
                return this._filterRenglones(buscar);
            });

        this.conlumnasFiltered = this.ctrlColumnas.valueChanges
            .startWith(null)
            .debounceTime(500)
            .distinctUntilChanged()
            .map(buscar => {
                if (buscar && typeof buscar === 'object') {
                    return buscar.Nombre;
                } else {
                    return buscar;
                }
            }).flatMap(buscar => {
                return this._filterColumnas(buscar);
            });

    }

    ngOnInit(): void {
        this.isTrabajando = true;
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        
        this.listaForm = this._builder.group({ opciones: this._builder.array([]) });

        this.articulo = null;
        this.descArt = null;
        this.descCol = null;
        this.descReg = null;
        
        this.conlumnas = [];
        this.renglones = [];
        
        this.tieneColumna = false;
        this.tieneRenglon = false;
        this.tieneListas = false;
        this.esDibujo = false;
        this.dondeDibujo = '';

        this.tableCol = [];
        this.tableReg = [];

        this.numRenglones = 0;
        this.numColumnas= 0;

        this.renglonesSeleccionados = [];
        this.columnasSeleccionados = [];

        this.disponiblilidad = [];
    }

    private InitComponente(): void {
        this.articulo = <string> this.data.articuloSelected;
        this.tipo_almacen = <string> this.data.tipo_almacen;
        this.valida_existencia = <boolean> this.data.valida_existencia;
        console.log('Inicia Opciones:: Articulo {' + this.articulo + '}, Almacen {' + this.tipo_almacen +'}, Valida Existencias {' + (this.valida_existencia === true ? 'Si' : 'No') + '}');
        this.obtieneOpcionesDisplonibles();
    }

    private validaExistanOpciones(): boolean {
        if (this.tieneColumna === false) {
            if (this.tieneRenglon === false) {
                if(this.tieneListas === false ){
                    this._msjSrv.agregaError('No hay opciones definidas para el Articulo.');
                    return false;
                }
            }
        }

        if (this.numRenglones > 1 || this.numColumnas > 1) {
            this._msjSrv.agregaError('En la lista de datos, hay más de un asistente tipo Columna o tipo Renglo.');
            return false;
        }

        return true;
    }

    private obtieneOpcionesDisplonibles(): void {
        this._loadSrv.toggle(true);
        this._selSrv.getOpcionesRegCol(this.articulo).subscribe(
            resSrv => {
                this.descArt = resSrv.DescArticulo;

                if (resSrv.Renglon !== null) {
                    if (resSrv.Renglon.length > 0) {
                        resSrv.Renglon.forEach(ren => {
                            if (ren.Opcion === 'C') {
                                this.esDibujo = true;
                                this.dondeDibujo = 'Renglon';
                            }
                            this.tieneRenglon = true;
                            this.numRenglones = this.numRenglones + 1;
                            this.renglones = ren.Renglon;
                            this.descReg = ren.descCol;
                        });
                    }
                }
                
                if (resSrv.Columna !== null) {
                    if (resSrv.Columna.length > 0) {
                        resSrv.Columna.forEach(col => {
                            if (col.Opcion === 'C') {
                                this.esDibujo = true;
                                this.dondeDibujo = 'Columna';
                            }
                            this.tieneColumna = true;
                            this.numColumnas = this.numColumnas + 1;
                            this.conlumnas = col.Columna;
                            this.descCol = col.descCol;
                        });
                    }
                }
                
                if (!this.validaExistanOpciones()) {
                    this._loadSrv.toggle(false);
                    this.cancelar();
                } else {
                    this._selSrv.getDisponibleSubcuenta(this.articulo, this.tipo_almacen).subscribe(
                        resDisp => {
                            this.disponiblilidad = resDisp;
                            if (resSrv.Lista !== null) {
                                if (resSrv.Lista.length > 0) {
                                    this.tieneListas = true;
                                    this.armaFormLista(resSrv.Lista);
                                }
                            }
                            this._loadSrv.toggle(false);
                            this.isTrabajando = false;
                        }, errDisp => {
                            this.disponiblilidad = [];
                            if (resSrv.Lista !== null) {
                                if (resSrv.Lista.length > 0) {
                                    this.tieneListas = true;
                                    this.armaFormLista(resSrv.Lista);
                                }
                            }
                            console.error('Error al buscar la disponibilidad::: ', errDisp);
                            this._loadSrv.toggle(false);
                            this.isTrabajando = false;
                        }
                    )
                }
            },
            errSrv => {
                console.error('Error::SelectorOptArtComponent::obtieneOpcionesDisplonibles', errSrv);
                this._loadSrv.toggle(false);
                this.isTrabajando = false;
            });
    }

    private armaFormLista(opciones: any): void {
        const control = <FormArray>this.listaForm.controls.opciones;
        // Elimina las opciones existentes
        while (control.length !== 0) {
            control.removeAt(0)
        }
        // LLena las nuevas opciones
        opciones.forEach(e => {
            control.push(
                this._builder.group({
                    opcion: [e.Opcion, Validators.required],
                    descripcion: [e.Descripcion, Validators.required],
                    requerido: [e.Requerido, Validators.required],
                    disponibles: [this.dameDisponibleLista(e.Lista), Validators.required],
                    valor: [null, (Number(e.Requerido) === 1 ? Validators.required: null)],
                }));
        });
    }

    armaTabla(): void {
        // Obtiene los dibujos siguientes
        if (this.esDibujo) {

            //this._selSrv.getOpcionesDibujoSiguientes(this.ctrlNumModelos.value).subscribe(
            //    resOK => { 
                    // Si el dibujo esta en el renglon
                    if(this.dondeDibujo === 'Renglon') {
                        this.renglonesSeleccionados = this.armaDibujoDinamico(this.ctrlNumModelos.value);
                    }
                    // Si el dibujo esta en la columna
                    if(this.dondeDibujo === 'Columna') {
                        this.columnasSeleccionados = this.armaDibujoDinamico(this.ctrlNumModelos.value);
                    }
                    // Es matriz
                    if (this.tieneColumna && this.tieneRenglon) {
                        this.generaMatriz();
                    }
                    // Solo es renglon
                    if (!this.tieneColumna && this.tieneRenglon) {
                        this.generaRenglon();
                    }
                    // Solo es columna
                    if (this.tieneColumna && !this.tieneRenglon) {
                        this.generaColumna();
                    }
            //    },
            //    error => {
            //        console.error('ERROR AL RECUPERAR LOS DIBUJOS::: ', error);
            //        this._msjSrv.agregaError('Imposible obtener los dibujos, intente más tarde.');
            //    }
            //);
        } else {
            // Es matriz
            if (this.tieneColumna && this.tieneRenglon) {
                this.generaMatriz();
            }
            // Solo es renglon
            if (!this.tieneColumna && this.tieneRenglon) {
                this.generaRenglon();
            }
            // Solo es columna
            if (this.tieneColumna && !this.tieneRenglon) {
                this.generaColumna();
            }
        }
    }

    private generaColumna(): void {
        this.tableCol = [];
        this.tableCol.push({'ViewValue': '', 'ColValue': null, 'RowValue': null});
        this.columnasSeleccionados.forEach(col => {
            this.tableCol.push({'ViewValue': col.Nombre, 'ColValue': {'Requerido': col.Requerido,
                                                                      'id_opciond': col.id_opciond,
                                                                      'Descripcion': col.Descripcion,
                                                                      'Opcion': col.Opcion,
                                                                      'Nombre': col.Nombre,
                                                                      'Numero': col.Numero}, 'RowValue': null});
        });

        this.tableReg = [];
        let i = 0;
        let j = 0;
        this.tableReg[i] = [];
        this.tableCol.forEach(col => {
            if (j == 0) {
                this.tableReg[i][j] = {'ViewValue': 'Cantidad', 'Cantidad': -1, 'Existencia': null, 'RowValue': null, 'ColValue': null};
            } else {
                this.tableReg[i][j] = {'ViewValue': null, 
                                       'Cantidad': null,
                                       'Existencia': this.dameDisponible('C', col.ColValue.Opcion, col.ColValue.Numero, null, null),
                                       'ColValue': col.ColValue, 'RowValue': null};
            }
            j++;
        });
    }

    private generaRenglon(): void {
        this.tableCol = [];
        this.tableCol.push({'ViewValue': '',         'ColValue': null, 'RowValue': null});
        this.tableCol.push({'ViewValue': 'Cantidad', 'ColValue': null, 'RowValue': null});

        this.tableReg = [];
        let i = 0;
        let j = 0;
        this.renglonesSeleccionados.forEach(reg => {
            this.tableReg[i] = [];
            j = 0;
            this.tableCol.forEach(col => {
                if (j == 0) {
                    this.tableReg[i][j] = {'ViewValue': reg.Nombre, 'Cantidad': -1, 'Existencia': null, 'RowValue': null, 'ColValue': null};
                } else {
                    this.tableReg[i][j] = {'ViewValue': null,
                                           'Cantidad': null,
                                           'Existencia': this.dameDisponible('R', null, null, reg.Opcion, reg.Numero),
                                           'ColValue': null,
                                           'RowValue': {'Requerido': reg.Requerido,
                                                        'id_opciond': reg.id_opciond,
                                                        'Descripcion': reg.Descripcion,
                                                        'Opcion': reg.Opcion,
                                                        'Nombre': reg.Nombre,
                                                        'Numero': reg.Numero} };
                }
                j++;
            });
            i++;
        });
    }

    private generaMatriz(): void {
        this.tableCol = [];
        this.tableCol.push({'ViewValue': '', 'ColValue': null, 'RowValue': null});
        this.columnasSeleccionados.forEach(col => {
            this.tableCol.push({'ViewValue': col.Nombre, 'ColValue': {'Requerido': col.Requerido,
                                                                      'id_opciond': col.id_opciond,
                                                                      'Descripcion': col.Descripcion,
                                                                      'Opcion': col.Opcion,
                                                                      'Nombre': col.Nombre,
                                                                      'Numero': col.Numero}, 'RowValue': null});
        });

        this.tableReg = [];
        let i = 0;
        let j = 0;
        this.renglonesSeleccionados.forEach(reg => {
            this.tableReg[i] = [];
            j = 0;
            this.tableCol.forEach(col => {
                if (j == 0) {
                    this.tableReg[i][j] = {'ViewValue': reg.Nombre,
                                           'Cantidad': -1,
                                           'Existencia': null,
                                           'RowValue': null,
                                           'ColValue': null};
                } else {
                    this.tableReg[i][j] = {'ViewValue': null,
                                           'Cantidad': null,
                                           'Existencia': this.dameDisponible('M', col.ColValue.Opcion, col.ColValue.Numero, reg.Opcion, reg.Numero),
                                           'ColValue': col.ColValue,
                                           'RowValue': {'Requerido': reg.Requerido,
                                                        'id_opciond': reg.id_opciond,
                                                        'Descripcion': reg.Descripcion,
                                                        'Opcion': reg.Opcion,
                                                        'Nombre': reg.Nombre,
                                                        'Numero': reg.Numero} };
                }
                j++;
            });
            i++;
        });
    }

    dameDisponibleLista(lista: any[]): any[] {
        let respuesta: any[] = [];
        let det: any;
        let disponible = 0;
        lista.forEach(lst => {
            disponible = 0;
            this.disponiblilidad.forEach(disp => {
                if (this.tipo_almacen === 'T') {
                    if (disp.Almacen === 'A46') {
                        if (disp.SubCuenta === lst.Opcion + '' + lst.Numero) {
                            disponible = disponible + Number(disp.Disponible);
                        }
                    }
                } else {
                    if (disp.Almacen === '100' || disp.Almacen === '101' || disp.Almacen === '102') {
                        if (disp.SubCuenta === lst.Opcion + '' + lst.Numero) {
                            disponible = disponible + Number(disp.Disponible);
                        }
                    }
                }
            });
            det = { Descripcion: lst.Descripcion,
                    Opcion: lst.Opcion,
                    Numero: lst.Numero,
                    Nombre: lst.Nombre,
                    id_opciond: lst.id_opciond,
                    Existencia: disponible
                };
            respuesta.push(det);
        });

        return respuesta;
    }

    dameDisponible(tipo: any, optCol: any, colNum: any, optReg: any, regNum: any): number {
        let subCta1: string;
        let subCta2: string;
        let disponible: number = 0;
        // M - Matriz R: Renglon, C Columna, L Lista
        if (tipo === 'M') {
            this.disponiblilidad.forEach(disp => {
                if (this.tipo_almacen === 'T') {
                    if (disp.Almacen === 'A46') {
                        subCta1 = optCol + '' + colNum + '' + optReg + '' + regNum;
                        subCta2 = optReg + '' + regNum + '' + optCol + '' + colNum;
                        if (disp.SubCuenta === subCta1 || disp.SubCuenta === subCta2) {
                            disponible = disponible + Number(disp.Disponible);
                        }
                    }
                } else {
                    if (disp.Almacen === '100' || disp.Almacen === '101' || disp.Almacen === '102') {
                        subCta1 = optCol + '' + colNum + '' + optReg + '' + regNum;
                        subCta2 = optReg + '' + regNum + '' + optCol + '' + colNum;
                        if (disp.SubCuenta === subCta1 || disp.SubCuenta === subCta2) {
                            disponible = disponible + Number(disp.Disponible);
                        }
                    }
                }
            });
        }

        if (tipo === 'R') {
            this.disponiblilidad.forEach(disp => {
                if (this.tipo_almacen === 'T') {
                    if (disp.Almacen === 'A46') {
                        subCta1 = optReg + '' + regNum;
                        if (disp.SubCuenta === subCta1) {
                            disponible = disponible + Number(disp.Disponible);
                        }
                    }
                } else {
                    if (disp.Almacen === '100' || disp.Almacen === '101' || disp.Almacen === '102') {
                        subCta1 = optReg + '' + regNum;
                        if (disp.SubCuenta === subCta1) {
                            disponible = disponible + Number(disp.Disponible);
                        }
                    }
                }
            });
        }

        if (tipo === 'C') {
            this.disponiblilidad.forEach(disp => {
                if (this.tipo_almacen === 'T') {
                    if (disp.Almacen === 'A46') {
                        subCta1 = optCol + '' + colNum;
                        if (disp.SubCuenta === subCta1) {
                            disponible = disponible + Number(disp.Disponible);
                        }
                    }
                } else {
                    if (disp.Almacen === '100' || disp.Almacen === '101' || disp.Almacen === '102') {
                        subCta1 = optCol + '' + colNum;
                        if (disp.SubCuenta === subCta1) {
                            disponible = disponible + Number(disp.Disponible);
                        }
                    }
                }
            });
        }

        return disponible;
    }

    removeRenglon(renglon: any): void {
        const index = this.renglonesSeleccionados.indexOf(renglon);
        if (index >= 0) {
          this.renglonesSeleccionados.splice(index, 1);
        }
    }

    removeColumna(columna: any): void {
        const index = this.columnasSeleccionados.indexOf(columna);
        if (index >= 0) {
          this.columnasSeleccionados.splice(index, 1);
        }
    }

    addRenglon(event: MatChipInputEvent): void {
        const input = event.input;
        const value: any = event.value;
        // Reset the input value
        if (input) {
          input.value = '';
        }
        this.ctrlRenglones.setValue(null);
    }


    addColumna(event: MatChipInputEvent): void {
        const input = event.input;
        const value: any = event.value;
        // Reset the input value
        if (input) {
          input.value = '';
        }
        this.ctrlColumnas.setValue(null);
    }

    private _filterRenglones(valueFind: string): Promise<any[]> | Observable<any[]> {
        if (valueFind && valueFind.length) {
            if (valueFind.length < 1) {
                return Observable.of(<any[]>[]);
            } else {
                return Observable.of(this.renglones.filter(find => find.Nombre.toLowerCase().indexOf(valueFind.toLowerCase()) === 0).slice(0, 20));
            }
        } else {
            return Observable.of(this.renglones.slice(0, 20));
        }
    }

    private _filterColumnas(valueFind: string): Promise<any[]> | Observable<any[]> {
        if (valueFind && valueFind.length) {
            if (valueFind.length < 1) {
                return Observable.of(<any[]>[]);
            } else {
                return Observable.of(this.conlumnas.filter(find => find.Nombre.toLowerCase().indexOf(valueFind.toLowerCase()) === 0).slice(0, 20));
            }
        } else {
            return Observable.of(this.conlumnas.slice(0, 20));
        }
    }

    selectedRenglon(event: MatAutocompleteSelectedEvent): void {
        let existe: boolean = false;
        this.renglonesSeleccionados.forEach(e => {
            if (e.Numero === event.option.value.Numero) {
                existe = true;
            }
        });

        if (!existe) {
            this.renglonesSeleccionados.push(event.option.value);
        }

        this.renglonesInput.nativeElement.value = '';
        this.ctrlRenglones.setValue(null);
    }

    selectColumna(event: MatAutocompleteSelectedEvent): void {
        let existe: boolean = false;
        this.columnasSeleccionados.forEach(e => {
            if (e.Numero === event.option.value.Numero) {
                existe = true;
            }
        });

        if (!existe) {
            this.columnasSeleccionados.push(event.option.value);
        }

        this.columnasInput.nativeElement.value = '';
        this.ctrlColumnas.setValue(null);
    }

    okOpciones(): void {
        if (this.validaOpcionesElegidas()) {
            this.procesaMatriz();
        }
     }

    private procesaMatriz(): void {

        let opcionesReturn = [];
        let optTemp: OpcionesArticulo;
        let optArray: OpcionesArticulo[] = [];

        let optListas = this.agregaOpcionesListas();

        if (this.validaExistencias()) {
            // Es la matriz
            if (this.tieneColumna && this.tieneRenglon) {
                // console.log('Es Matriz...');
                this.tableReg.forEach(row => {
                    row.forEach(col => {
                        optArray = [];
                        if (Number(col.Cantidad) > 0) {
                            optTemp = {};
                            optTemp.descripcion = col.ColValue.Descripcion;
                            optTemp.id_opciond = col.ColValue.id_opciond;
                            optTemp.Nombre = col.ColValue.Nombre;
                            optTemp.Numero = col.ColValue.Numero;
                            optTemp.opcion = col.ColValue.Opcion;
                            optTemp.requerido = col.ColValue.Requerido;
                            optArray.push(optTemp);

                            optTemp = {};
                            optTemp.descripcion = col.RowValue.Descripcion;
                            optTemp.id_opciond = col.RowValue.id_opciond;
                            optTemp.Nombre = col.RowValue.Nombre;
                            optTemp.Numero = col.RowValue.Numero;
                            optTemp.opcion = col.RowValue.Opcion;
                            optTemp.requerido = col.RowValue.Requerido;
                            optArray.push(optTemp);

                            optListas.forEach(lst => { optArray.push(lst); });
                            opcionesReturn.push({'Cantidad': col.Cantidad, 'Opciones': optArray });
                            
                        }
                    });
                });
            }
            // Es solo renglon
            if (!this.tieneColumna && this.tieneRenglon) {
                // console.log('Es renglon...');
                this.tableReg.forEach(row => {
                    row.forEach(col => {
                        optArray = [];
                        if (Number(col.Cantidad) > 0) {
                            optTemp = {};
                            optTemp.descripcion = col.RowValue.Descripcion;
                            optTemp.id_opciond = col.RowValue.id_opciond;
                            optTemp.Nombre = col.RowValue.Nombre;
                            optTemp.Numero = col.RowValue.Numero;
                            optTemp.opcion = col.RowValue.Opcion;
                            optTemp.requerido = col.RowValue.Requerido;
                            optArray.push(optTemp);

                            optListas.forEach(lst => { optArray.push(lst); });
                            opcionesReturn.push({'Cantidad': col.Cantidad, 'Opciones': optArray });
                            
                        }
                    });
                });
            }

            // Es solo columna
            if (this.tieneColumna && !this.tieneRenglon) {
                // console.log('Es columna...');
                this.tableReg.forEach(row => {
                    row.forEach(col => {
                        optArray = [];
                        if (Number(col.Cantidad) > 0) {
                            optTemp = {};
                            optTemp.descripcion = col.ColValue.Descripcion;
                            optTemp.id_opciond = col.ColValue.id_opciond;
                            optTemp.Nombre = col.ColValue.Nombre;
                            optTemp.Numero = col.ColValue.Numero;
                            optTemp.opcion = col.ColValue.Opcion;
                            optTemp.requerido = col.ColValue.Requerido;
                            optArray.push(optTemp);

                            optListas.forEach(lst => { optArray.push(lst); });
                            opcionesReturn.push({'Cantidad': col.Cantidad, 'Opciones': optArray });
                            
                        }
                    });
                });
            }

            this.dialogRef.close(opcionesReturn);
        }
    }

    private agregaOpcionesListas(): any[] {

        let opcionesLista: any[] = [];
        let optTemp: OpcionesArticulo;

        if (this.tieneListas) {
            this.listaForm.value.opciones.forEach(opt => {
                if (opt.valor !== null) {
                    optTemp = {};
                    optTemp.descripcion = opt.valor.Descripcion;
                    optTemp.id_opciond = opt.valor.id_opciond;
                    optTemp.Nombre = opt.valor.Nombre;
                    optTemp.Numero = opt.valor.Numero;
                    optTemp.opcion = opt.valor.Opcion;
                    optTemp.requerido = opt.requerido;
                    opcionesLista.push(optTemp);
                }
            });
        }

        return opcionesLista;
    }

    cancelar(): void {
        this.dialogRef.close(null);
    }

    private validaOpcionesElegidas(): boolean {

        if (this.tieneListas) {
            for(const opt of this.listaForm.value.opciones) {
                if (Number(opt.requerido) === 1) {
                    if (opt.valor === null){
                        this._msjSrv.agregaError('La opción [' + opt.descripcion + '] es requerida.');
                        return false;
                    }
                }
            }
        }

        if (this.tieneColumna || this.tieneRenglon) {
            let num = 0;
            this.tableReg.forEach(row => {
                row.forEach(col => {
                    if (col.Cantidad > 0) {
                        num = num + 1;
                    }
                });
            });

            if (num == 0) {
                this._msjSrv.agregaError('Debe seleccionar al menos un valor de la Matriz');
                return false;
            }
        }

        return true;
    }

    private validaExistencias(): boolean {

        let respuesta: boolean = true;

        if (this.valida_existencia) {
            // Valida si es matriz
            if (this.tieneColumna && this.tieneRenglon) {
                this.tableReg.forEach(row => {
                    row.forEach(col => {
                        if (Number(col.Cantidad) > 0) {
                            if (Number(col.Cantidad) > Number(col.Existencia)) {
                                this._msjSrv.agregaWarn('En la Fila ['+ col.RowValue.Nombre +'] y Columna [' + col.ColValue.Nombre + '] la cantidad requerida excede lo disponible.');
                                respuesta = false;
                            }
                        }
                    });
                });
            }
            // Es solo renglon
            if (!this.tieneColumna && this.tieneRenglon) {
                this.tableReg.forEach(row => {
                    row.forEach(col => {
                        if (Number(col.Cantidad) > 0) {
                            if (Number(col.Cantidad) > Number(col.Existencia)) {
                                this._msjSrv.agregaWarn('En la Fila ['+ col.RowValue.Nombre +'] la cantidad requerida excede lo disponible.');
                                respuesta = false;
                            }
                        }
                    });
                });
            }
            // Es solo columna
            if (this.tieneColumna && !this.tieneRenglon) {
                this.tableReg.forEach(row => {
                    row.forEach(col => {
                        if (Number(col.Cantidad) > 0) {
                            if (Number(col.Cantidad) > Number(col.Existencia)) {
                                this._msjSrv.agregaWarn('En la columna ['+ col.ColValue.Nombre +'] la cantidad requerida excede lo disponible.');
                                respuesta = false;
                            }                            
                        }
                    });
                });
            }
        }

        return respuesta;
    }

    private armaDibujoDinamico(numero: number): any[] {
        let respuesta: any[] = [];
        for (let index = 0; index < numero; index++) {
            respuesta.push({
                Descripcion: 'Dibujo',
                Opcion: 'C',
                Numero: index + 1,
                Nombre: index + 1,
                id_opciond: 0
            })
        }
        return respuesta;
    }
}
